import { AddIcon, Button, Dialog, Flex, DownloadIcon } from '@fluentui/react-northstar';
import * as React from "react";
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from "react-router";
import { NavLink } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Room, RoomAttribute, RoomsFilters, RoomsFilterType } from '../../../model';
import { getActiveOrganisation, getPackageFeeds, getRooms } from '../../../store/selectors';
import { actions } from '../../../store/types';
import { useSelector } from '../../../store/utils';
import AddRoomForm, { CreateRoom } from './AddRoomForm';
import RoomDetail from './RoomDetail';
import RoomList from './RoomList';
import "./RoomPage.css";
import { CSVLink, CSVDownload } from 'react-csv';
import { RootState } from "../../../store/types";
import { connect } from "react-redux";
import { HalResource, HalResult } from "../../../services/Hal";

// const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
//     setRoomsFilter,
//     createRoom: createRoom.request,
//     fetchFail: fetchFail.request,
//     fetchPackageFeeds: fetchPackageFeeds.request,
//     fetchRooms: fetchRooms.request
// }, dispatch);

// function mapStateToProps(state: RootState) {
//     return {
//         rooms: state.rooms,
//         roomsFilter: state.rooms.roomFilter,
//         isCreatingRoom: state.rooms.isCreatingRoom,
//         packageFeeds: getPackageFeeds(state)
//     };
// }


const RoomPage: React.FC<{}> = ({ }) => {

    const dispatch = useDispatch()
    const [addRoomDialogOpen, setAddRoomDialogOpen] = useState(false)
    const packageFeeds = useSelector(getPackageFeeds)
    const activeOrganisation = useSelector(getActiveOrganisation)

    const { isLoaded, isLoading, isCreatingRoom, roomFilter } = useSelector(s => s.rooms)
    
    //const withRedux = connect(mapStateToProps);

    useEffect(() => {

        if (!isLoaded && !isLoading) {
            dispatch(actions.fetchRooms.request(activeOrganisation.organisation_id))
            dispatch(actions.fetchPackageFeeds.request())
        }
    }, [activeOrganisation, dispatch, isLoaded, isLoading])

    const onItemSelected = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        const selectedFilter = event.currentTarget.id as RoomsFilterType
        dispatch(actions.setRoomsFilter(selectedFilter))
    }, [dispatch])

    const rooms = useSelector(getRooms)
    

    const addRoom = useCallback((create: CreateRoom) => {

        const room: Room = {
            name: create.roomName!,
            display_name: create.displayName!,
            email_address: create.emailAddress!,
            provisioning_status: "AwaitingProvisioning",
            room_msi_package_feed_name: create.msiPackageFeed,
            template_room_name: create.templateRoom && create.templateRoom! === "None" ? undefined : create.templateRoom,
            has_license_file: false,
            organisation_id: activeOrganisation?.organisation_id
        };
        const props = createProps(create)
        const roomProps: Array<{ name: string, value: RoomAttribute }> = props.filter(_ => _.value.value !== undefined) as Array<{ name: string, value: RoomAttribute }>;

        console.log("room", room)
        console.log("props", props)
        console.log("roomProps", roomProps)

        dispatch(actions.createRoom.request({ room, attribs: roomProps }))
    }, [activeOrganisation, dispatch])


    const addRoomButton = <Button primary content='Add Room' icon={<AddIcon />} iconPosition='before' loading={isCreatingRoom} styles={{ marginRight: '1em' }} />
    const addRoomContent = isCreatingRoom
        ? <Flex hAlign='end'>{addRoomButton}</Flex>
        : (<Flex hAlign='end'>
            <Dialog
                content={<AddRoomForm packageFeeds={packageFeeds} onSubmit={addRoom} onCancel={() => setAddRoomDialogOpen(false)} />}
                header='Add/Edit Room'
                open={addRoomDialogOpen}
                onOpen={() => setAddRoomDialogOpen(true)}
                trigger={addRoomButton}
            />
        </Flex>)


    const roomListToolbar = <>
        {addRoomContent}
        <UncontrolledDropdown className="filter-dropdown">
            <DropdownToggle className="filter-button" size="sm" color="dark" caret>
                {roomFilter}
            </DropdownToggle>

            <DropdownMenu>
                {RoomsFilters.map((filter, index) => {
                    return <DropdownItem key={index}
                        active={filter === roomFilter}
                        onClick={onItemSelected} id={filter}>
                        {filter as string}
                    </DropdownItem>
                })}
            </DropdownMenu>

        </UncontrolledDropdown>
    </>


    // =======================================================
    // CSV Data download
    // =======================================================

    const [csvData, setCsvData] = useState<string[][]>([]);
    const [csvFilename, setCsvFilename] = useState<string>();
    
    function createCsvData() {
 
        if (!rooms) {
            return;
        }

       console.log('doCsvExport');
        
        const csv: any = [];
        console.log('createCsvData: rooms', rooms);

        csv.push(createHeaderRow());

        rooms.forEach(room => {
            const item = createCsvRow(room);
            csv.push(item);
            console.log('createCsvData', csv);
        });

        setCsvData(csv);
    }

    function createHeaderRow() : string[] {
        return ["Organisation",
                "Context",
                "Action",
                "RoomName",
                "RoomEmail",
                "DisplayName",
                "RoomPackageFeed",
                "AdminPackageFeed",
                "TemplateRoom",
                "ExchangeEmail",
                "ExchangeUserName",
                "ExchangePassword",
                "WindowsUserName",
                "WindowsPassword"];
    }

    function createCsvRow(room: HalResult<Room>) : string[] {

        console.log('createCsvRow room', room);
        console.log('createCsvRow pf', packageFeeds);
        
        const csvRow:string[] = [];        

        csvRow.push(activeOrganisation.name);
        csvRow.push('VfxRoom');
        csvRow.push('Update');
        csvRow.push(room.resource.name);
        csvRow.push(''); //room.email_address);
        csvRow.push(room.resource.display_name);
        csvRow.push(room.resource.room_component?.package_feed_name ? room.resource.room_component.package_feed_name : '');
        csvRow.push(room.resource.admin_component?.package_feed_name ? room.resource.admin_component?.package_feed_name : '');
        csvRow.push(room.resource.template_room_name ? room.resource.template_room_name : '');
        csvRow.push(''); // Exhange email
        csvRow.push(''); // Exchange User
        csvRow.push(''); // exchange pw
        csvRow.push(''); // Windows username
        csvRow.push(''); // Windows pw
        
        return csvRow;
    }

    function createCsvFileName() {
        setCsvFilename(`${activeOrganisation.name}_VideoFxRooms.csv`);
    }

    const downloadCsvButton = <CSVLink 
                className='csvButton'
                data={csvData} 
                filename = {csvFilename}
                target="_blank"
                ><Button primary content='Download Csv' 
                        icon={<DownloadIcon />} 
                        iconPosition='before' 
                        styles={{ marginBottom: '0.5rem', marginRight: '0.5rem' }} />
        </CSVLink>
    
    useEffect(() => {
        if (rooms){
            createCsvData();
            createCsvFileName();
        }
    }, [rooms]);

    // ========================================================

    return (<>
        <h5>Rooms</h5>
        
        <div className="navbar room-page-header">
            <div className="mr-auto">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <NavLink to="/rooms">All</NavLink>
                    </li>
                    <Route path="/rooms/:name" children={({ match }) => (match && <li className="breadcrumb-item active" >
                        {match.params.name}
                    </li>)} />
                </ol>
            </div>
            {downloadCsvButton}
            <Route exact path="/rooms" children={roomListToolbar} />
            
        </div>
        
        <Switch>
            <Route exact path="/rooms" children={<RoomList/>} />
            <Route path="/rooms/:name" children={({ match }) => (match && <RoomDetail roomName={match.params.name} />)} />
        </Switch>
    </>)
}

function createProps(create: CreateRoom) {
    return [{
        name: 'exchange_email',
        value: {
            value: create.emailAddress,
            type: 'Provisioning'
        }
    }, {
        name: 'exchange_password',
        value: {
            value: create.exchangePassword,
            data_type: 'Password',
            type: 'Provisioning'
        }
    }, {
        name: 'exchange_username',
        value: {
            value: create.exchangeUsername,
            type: 'Provisioning'
        }
    }
        , {
        name: 'windows_password',
        value: {
            value: create.windowsPassword,
            data_type: 'Password',
            type: 'Provisioning'
        }
    }, {
        name: 'windows_username',
        value: {
            value: create.windowsUsername,
            type: 'Provisioning'
        }
    }]
}

export default RoomPage;


