import React, { useCallback, useEffect, useState } from "react";
import { Button, Dropdown, Flex, Menu, MenuItemProps, Popup } from "@fluentui/react-northstar";

import BulkLoadDevices from "./BulkLoadDevices";
import BulkLoadBookitResources from "./BulkLoadBookitResources";
import BulkLoadVideoFxRooms from "./BulkLoadVideoFxRooms"


function BulkUpdates() {
    const [activeIndex, setActiveIndex] = useState(0)

    const tabItems = [
        {
            "key": "bookit_resources",
            "content": "Bookit resources"
        },
        {
            "key": "bookit_devices",
            "content": "Bookit devices"
        },
        {
            "key": "videofx_rooms",
            "content": "VideoFx rooms"
        }
    ]

    const handleMenuItemClick = useCallback((event: React.SyntheticEvent<HTMLElement>, data?: MenuItemProps) => {
       
        if (data) {
            setActiveIndex(data.index!)
        }
    }
    , [])

    return <Flex className="ReportsPage">
        
        <h5>Bullk updates</h5>

        <Menu defaultActiveIndex={0} 
                activeIndex={activeIndex} 
                onItemClick={handleMenuItemClick}
                items={tabItems} 
                underlined primary
            />

        {activeIndex === 0 && <BulkLoadBookitResources />}
        {activeIndex === 1 && <BulkLoadDevices />}
        {activeIndex === 2 && <BulkLoadVideoFxRooms />}
       
        
    </Flex>
}

export default BulkUpdates;