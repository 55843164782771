import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../store/utils";
import { getActiveOrganisation } from "../../../store/selectors";


import { Loader } from "@fluentui/react-northstar";
import { MdCall, MdEvent, MdWarning } from "react-icons/md";
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

import ReactTable, { CellInfo, Column } from "react-table";
import { StoppedRunningSelection, VfxRoomStatusReport } from "../../../model";
import StoppedRunningSelector from './StoppedRunningSelector';
import { fetchVfxRoomStatusReport } from "../../../store/actions";
//import services from "../../services";
import { getVfxRoomStatusReport, getVfxRoomStatusReportIsLoading } from "../../../store/selectors";
import css from './SupportDashboard.module.scss';
import { date } from "yup";
import moment from "moment";
import { GiCellarBarrels } from "react-icons/gi";

export function secondsToDhm(seconds: number) {
    const minutes = seconds / 60

    const d = Math.floor(minutes / (60*24));
    const h = Math.floor(minutes % (60*24) / 60);
    const m = Math.floor(minutes % 60);

    const dDisplay = d > 0 ? d + "d " : "";
    const hDisplay = h > 0 ? h + "hr "  : "";
    const mDisplay = m > 0 ? m + "m " : "";

    return dDisplay + hDisplay + mDisplay;
}



function VfxRoomStatusReportPage() {

    const dispatch = useDispatch()
    const activeOrganisation = useSelector(getActiveOrganisation)

    useEffect(() => {
        if (activeOrganisation) {
            dispatch(fetchVfxRoomStatusReport.request({ organisation_id: activeOrganisation.organisation_id }))        
        }
    }, [dispatch, activeOrganisation])

    const data = useSelector(getVfxRoomStatusReport);
    
    const isLoading = useSelector(getVfxRoomStatusReportIsLoading);

    const [currentSelection, setCurrentSelection] = useState<StoppedRunningSelection>('all')
    const [filteredData, setFilteredData] = useState<VfxRoomStatusReport[]>(data)

    function onSelectionChanged(selection: StoppedRunningSelection) {

        setCurrentSelection(selection);

        if (selection === 'running') {
            setFilteredData(data.filter(d => d.status == 'Running'));
        } else if (selection === 'stopped') {
            setFilteredData(data.filter(d => d.status == 'Stopped' || d.status == 'NeverRun'));
        } else {
            setFilteredData(data);
        }

    }

    function renderStatus(status: string) {
    
        console.log('[Support Dashboard] status', status);
    
        if (status == 'NeverRun') {
            return <div><MdWarning size="2em" color='orange' /></div>
        } else if (status == 'Stopped') {
            return <div><FaTimesCircle size="2em" color='red' /></div>
        }else {
            return <div><FaCheckCircle size="2em" color='green' /></div>
        }
        //return active ? <FaCheckCircle color="green" size="2em" /> : <FaTimesCircle color="red" size="2em" />
    }
    
    function renderMeetingStatus(isInMeeting: boolean) {
        if (isInMeeting) {
            return <MdCall size="2em" color="green" />
        } else {
            return <div></div>
        }
    }

    function renderUtcDate(date:Date | undefined) {
        if (date) {
            return moment(date).local().format('Do MMM YYYY, h:mm:ss a');
        } else {
            return '';
        }
    }
    
    // {moment.utc(site.last_activity_utc).local().format('MMM Do YYYY, h:mm:ss a')}
    function renderStatusTime(roomid: string) {

        console.log('renderStatusTime data:',data);

        const roomData = data.filter(d => d.room_email == roomid)[0];
        const now = moment();

        if (roomData) {
            if (roomData.status == 'NeverRun') {
                return <div></div>
            } else if (roomData.last_heartbeat_utc) {
                const elapsedString = moment.duration(now.diff(moment(roomData.last_heartbeat_utc).local())).humanize()
                return <div>{elapsedString}</div>
            }
            return <div></div>
        } else {
            return <div></div>
        }


        
    }
    
    const columns: Column[] = [{
        headerStyle: {
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Room',
            headerStyle: {
                textAlign: 'left',
                borderRight: '1px solid #fff',
            },
            minWidth: 50,
            accessor: 'room_name',
            Cell: (cellInfo: CellInfo) => {
                const index = cellInfo.original.room_name
                const id = cellInfo.original.room_name
                return (
                    <div>
                        <span id={id}>{cellInfo.original.room_name}</span>
                        {/* <UncontrolledTooltip placement='auto' target={id}>
                            <div key={index}>{cellInfo.original.display_name}</div>
                        </UncontrolledTooltip> */}
                    </div>
                )
            },
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Location',
            className: 'leftAlign',
            minWidth: 20,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '0px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'location',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }
    , {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Status',
            className: 'centreAlign',
            minWidth: 10,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '0px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'status',
            Cell: (cell) => renderStatus(cell.value),
        }]
    },  
    {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'In a call',
            className: 'centreAlign',
            minWidth: 10,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '0px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'is_in_meeting',
            Cell: (cell) => renderMeetingStatus(cell.value),
        }]
    },
    {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Run state',
            className: 'leftAlign',
            minWidth: 10,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '0px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'status',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    },{
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Stop reason',
            className: 'leftAlign',
            minWidth: 20,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '0px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'status_reason',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    },
     {
        headerStyle: {
            borderLeft: '0px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Time in this state',
            className: 'leftAlign',
            minWidth: 30,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '0px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'room_email',
            Cell: (cell) => renderStatusTime(cell.value),
        }]
    },
    {
       headerStyle: {
           borderLeft: '1px solid #fff',
           borderRight: '1px solid #fff',
       },
       columns: [{
           Header: 'Last heartbeat',
           className: 'leftAlign',
           minWidth: 30,
           defaultSortDesc: true,
           headerStyle: {
               borderLeft: '0px solid #fff',
               borderRight: '1px solid #fff',
           },
           accessor: 'last_heartbeat_utc',
           Cell: (cell) => renderUtcDate(cell.value),
       }]
    },
    {
       headerStyle: {
           borderLeft: '1px solid #fff',
           borderRight: '1px solid #fff',
       },
       columns: [{
           Header: 'Last restart',
           className: 'leftAlign',
           minWidth: 30,
           defaultSortDesc: true,
           headerStyle: {
               borderLeft: '0px solid #fff',
               borderRight: '1px solid #fff',
           },
           accessor: 'last_restart_utc',
           Cell: (cell) => renderUtcDate(cell.value),
       }]
    }
    ]
    
    if (isLoading) { 
        return <Loader label="Loading report..." /> 
    } 

    
    return <div className={css.reportSection}>
    <h2>Vfx rooms status</h2>

    <StoppedRunningSelector onSelectionChange={onSelectionChanged} />


    <ReactTable
        className="-striped -highlight"
        showPagination={false}
        key={filteredData.length === 0 ? "nodata" : "havedata"}
        defaultPageSize={filteredData.length === 0 ? 5 : filteredData.length}
        columns={columns}
        defaultSorted={([{ id: "room_name", desc: false }])}
        data={filteredData}
        loadingText={<Loader label='Loading...' />}
        loading={isLoading}
        noDataText={<span>Nothing to show</span>}
    />
</div>

}

export default VfxRoomStatusReportPage;
