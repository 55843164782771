import * as _ from 'lodash';
import { getType } from "typesafe-actions";
import { ApiError } from '../../utils/error';
import { Actions, actions } from "../types";
import { ToastHistory } from "../../model"

export type ToastType = 'Error' | 'Information' | 'Warning'

interface ToastsHistory {
    toasts: ToastHistory[]
}

export default function toastsHistory(state: ToastsHistory = {toasts: []}, action: Actions) : ToastsHistory {
    switch (action.type) {

        case getType(actions.addToastHistory):
            console.log('Toast: Adding action', {state,action});
            return { 
                ...state,
                toasts: [...state.toasts, action.payload]
            };        

        // case getType(actions.removeToastHistory):
        //     return { ...state, toasts: _.omit(state, action.payload) };
       
        default:
            return state;
    }
}

// export function toastForApiError(apiError: ApiError, newId: number, customErrorMessage?: (err: ApiError) => string|null): Toast {
    
//     const customMessage = customErrorMessage && customErrorMessage(apiError)

//     console.log('customMessage', customMessage) 

//     if (customMessage) {
//         return {
//             id: newId,
//             title: customMessage,
//             header: apiError.report!.title,
//             data: apiError,
//             type: 'Error',
//             customApiError: true
//         }
//     }

//     let headerStr: string|undefined = undefined;
//     if (apiError.statusCode !== undefined
//         && apiError.statusCode === 400
//         &&  apiError.report !== undefined
//         ) {
//             headerStr = "There was a problem with that request"
//         }
    
//     return {
//         id: newId,
//         header: headerStr,
//         title: defaultErrorMessage(apiError),
//         data: apiError,
//         type: 'Error',
//         customApiError: false
//     }
//}