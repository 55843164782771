import { ProviderConsumer } from '@fluentui/react-northstar';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChartBar, faChartPie, faCircleNotch, faCloudDownloadAlt, faDownload, faExclamationTriangle, 
        faImage, faInfo, faPlay, faPlusCircle, faPowerOff, faRedo, faStop, faSyncAlt, faTimes, 
        faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './App.css';

import {Toast, ToastHistory} from './model';

import NfcTagsPage from './components/admin/NfcTagsPage';
import OfficeLocationsPage from './components/admin/OfficeLocationsPage';
import OrganisationsPage from './components/admin/OrganisationsPage';
import OrganisationTenantsPage from './components/admin/OrganisationTenantsPage';
import UserAccountsPage from './components/admin/UserAccountsPage';
import SecurityReportPage from './components/admin/SecurityReportPage';
import GenericReportScheduler from './components/admin/GenericReportScheduler';
import { NotAdminPage } from './components/auth/NotAdminPage';
import AttributesPage from './components/bookIt/AttributesPage';
import MeetingRoomsPage from './components/bookIt/BookItRoomPage';
import CalendarDelegationsPage from './components/bookIt/CalendarDelegationsPage';
import DevicesPage from './components/bookIt/DevicesPage';
import GraphApiPage from './components/bookIt/GraphApiPage';
import LocationsPage from './components/bookIt/LocationsPage';
import BookItMetricsPage from './components/bookIt/MetricsPage';
import OnlineMeetingProvidersPage from './components/bookIt/OnlineMeetingProvidersPage';
import ProvidersPage from './components/bookIt/ProvidersPage';
import OrganisationSettingsPage from './components/bookIt/settings/OrganisationSettingsPage';
import OutlookRoomLocatorPage from './components/bookIt/OutlookRoomLocatorPage';
import SharingCodesPage from './components/bookIt/SharingCodesPage';
import SharingGroupsPage from './components/bookIt/SharingGroupsPage';
import { FullscreenLoader } from './components/controls/FullscreenLoader';
import NavBar from './components/nav/NavBar';
import SideNav from './components/nav/SideNav';
import { ServerUnvailable } from './components/ServerUnavailable';
import CorsSettingsPage from './components/settings/CorsSettingsPage';
import ToastComponent from './components/Toast';
import { SitesPage } from './components/uc/SitesPage';
import SupportSettingsPage from './components/uc/Support/SupportSettingsPage';
import ConferenceClientsPage from './components/videofx/ConferenceClientsPage';
import EscalationReportPage from './components/videofx/EscalationReportPage';
import EscalationRulesPage from './components/videofx/EscalationRulesPage';
import EscalationsPage from './components/videofx/EscalationsPage';
import MetricsPage from './components/videofx/MetricsPage';
import PackageFeedsPage from './components/videofx/PackageFeedsPage';
import RoomPage from './components/videofx/room/RoomPage';
import RoomoteDevices from './components/videofx/RoomoteDevices';
import SupportDashboard from './components/admin/Support/SupportDashboard';
import BulkUpdates from './components/admin/Support/BulkUpdates/BulkUpdates';
import { RoomsHealthPage } from './components/videofx/RoomsHealthPage';
import WindowsUpdatesPage from './components/videofx/WindowsUpdatesPage';
import { addToastHistory, loadTimezoneData } from './store/actions';
import { ApiResult } from './store/reducers/ApiResult';
import { actions } from './store/types';
import { useSelector } from './store/utils';
import ResourcesPage from './components/admin/ResourcesPage';


library.add(faCircleNotch, faCloudDownloadAlt, faInfo, faPlay, faStop, faRedo, faSyncAlt
  , faDownload, faUpload, faChartPie, faChartBar, faImage, faExclamationTriangle
  , faPlusCircle, faTrash, faPowerOff, faTimes);

const routeData = [
  { rolesRequired: ["IpfxAdmin"], path: '/organisations', component: <OrganisationsPage /> },
  { rolesRequired: ["IpfxAdmin"], path: '/organisation_tenants', component: <OrganisationTenantsPage /> },
  { rolesRequired: ["UserAdmin"], path: '/user_accounts', component: <UserAccountsPage /> },
  { rolesRequired: ["BookItAdmin", "VideoFxAdmin"], path: '/resources', component: <ResourcesPage /> },
  { rolesRequired: ["IpfxAdmin"], path: '/conference_clients', component: <ConferenceClientsPage /> },
  { rolesRequired: ["IpfxAdmin"], path: '/nfctags', component: <NfcTagsPage /> },
  { rolesRequired: ["BookItAdmin"], path: '/office_locations', component: <OfficeLocationsPage /> },
  { rolesRequired: ['VideoFxAdmin', 'VideoFxRooms'], path: '/rooms', component: <RoomPage /> },
  { rolesRequired: ['VideoFxAdmin'], path: "/packagefeeds", component: <PackageFeedsPage /> },
  { rolesRequired: ['VideoFxAdmin'], path: "/roomote_devices", component: <RoomoteDevices /> },
  { rolesRequired: ['VideoFxAdmin'], path: "/escalations", component: <EscalationsPage /> },
  { rolesRequired: ['VideoFxAdmin'], path: "/escalation_rules", component: <EscalationRulesPage /> },
  { rolesRequired: ['VideoFxAdmin'], path: "/support_dashboard", component: <SupportDashboard /> },
  { rolesRequired: ['VideoFxAdmin'], path: "/bulk_updates", component: <BulkUpdates /> },
  { rolesRequired: ['VideoFxReporting'], path: "/escalation_report", component: <EscalationReportPage /> },
  { rolesRequired: ['VideoFxReporting'], path: "/admin/security_report", component: <SecurityReportPage /> },
  { rolesRequired: ['VideoFxReporting'], path: "/admin/generic_report_scheduler", component: <GenericReportScheduler /> },
  { rolesRequired: ['VideoFxWindowsUpdateAdmin'], path: "/windows_updates", component: <WindowsUpdatesPage /> },
  { rolesRequired: ['VideoFxReporting'], path: "/metrics", component: <MetricsPage /> },
  { rolesRequired: ['Development'], path: "/health/rooms", component: <RoomsHealthPage /> },
  { rolesRequired: ['IpfxAdmin'], path: "/settings/cors", component: <CorsSettingsPage /> },
  { rolesRequired: ['BookItAdmin'], path: "/bookit/devices", component: <DevicesPage /> },
  { rolesRequired: ['BookItAdmin'], path: "/bookit/bookable_resources", component: <MeetingRoomsPage /> },
  { rolesRequired: ['BookItAdmin'], path: "/bookit/providers", component: <ProvidersPage /> },
  { rolesRequired: ['IpfxAdmin'], path: "/bookit/sharing_groups", component: <SharingGroupsPage /> },
  { rolesRequired: ['BookItAdmin'], path: "/bookit/sharing_codes", component: <SharingCodesPage /> },
  { rolesRequired: ['BookItAdmin'], path: "/bookit/online_meeting_providers", component: <OnlineMeetingProvidersPage /> },
  { rolesRequired: ['BookItAdmin'], path: "/bookit/locations", component: <LocationsPage /> },
  { rolesRequired: ['BookItAdmin'], path: "/bookit/attributes", component: <AttributesPage /> },
  { rolesRequired: ['BookItAdmin'], path: "/bookit/calendar_delegation", component: <CalendarDelegationsPage /> },
  { rolesRequired: ['BookItReporting'], path: "/bookit/insights", component: <BookItMetricsPage /> },
  { rolesRequired: ['BookItAdmin'], path: "/bookit/organisation_settings", component: <OrganisationSettingsPage /> },
  { rolesRequired: ['BookitAdmin'], path: "/bookit/outlook_room_locator", component: <OutlookRoomLocatorPage /> },
  { rolesRequired: ['Development'], path: "/bookit/graph_api", component: <GraphApiPage /> },
  { rolesRequired: ['UcAdmin'], path: "/uc/sites", component: <SitesPage /> },
  { rolesRequired: ['UcAdmin'], path: "/uc/support_settings", component: <SupportSettingsPage /> },
]


const App: React.FC<{}> = (props) => {
  const toasts = useSelector(s => s.toasts)
  const { userInfo, serverInfo } = useSelector(s => s.app)
  const hubsStarted = useSelector(s => s.app.hubs.started)

  const dispatch = useDispatch()

  useEffect(() => { dispatch(loadTimezoneData()) }, [dispatch])

  useEffect(() => {
    if (!userInfo.isRequested) {
      dispatch(actions.fetchUserInfo.request())
    }
  }, [dispatch, userInfo])

  useEffect(() => {
    if (!serverInfo.isRequested) {
      dispatch(actions.fetchServerInfo.request())
    }
  }, [dispatch, serverInfo])

  useEffect(() => {
    if (!hubsStarted) {
      dispatch(actions.startServerNotificationHub.request())
      dispatch(actions.startPingHub.request())
      dispatch(actions.hubsStarted())
    }
  }, [dispatch, hubsStarted])

  useEffect(() => {

    if (!_.isEmpty(toasts.toasts)) {
      const ids: number[] = [];
      _.mapValues(toasts.toasts, t => {
        const toastComp = <ToastComponent toast={t} />
        if (t.type === "Error") {
          toast.error(toastComp, { autoClose: 5000 })
        } else if (t.type === "Warning") {
          toast.warn(toastComp, { autoClose: 5000 })
        }
        else {
          toast.success(toastComp, { autoClose: 5000 })
        }
        ids.push(t.id);
        
        dispatch(actions.addToastHistory(mapToastToToastHistory(t)))
      })
      dispatch(actions.toastShown(ids))
    }
  }, [dispatch, toasts])

  function mapToastToToastHistory(toast: Toast) : ToastHistory {

    console.log('Toast: adding toast history');

    let errorText = '';

    if (toast.data.causedBy) {
      errorText = toast.data.causedBy.message;
    } else if (toast.data.report) {
      if (toast.data.report.status) {
          errorText = 'Status code: ' + toast.data.report.status + '.';
      }
      if (toast.data.report.instance) {
          errorText += ' instance: ' + toast.data.report.instance + '.';
      }
    }
    return {id: toast.id, timestamp: new Date(), type: toast.type, title: toast.title, message: errorText}
  }

  const handleForceUpdate = useCallback(() => dispatch(actions.clearUserServerInfo()), [dispatch])

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     console.log('Toast: This will be called every 10 seconds');
  //   }, 10000);

  //   return () => clearInterval(interval);
  // }, []);

  const requests = [userInfo, serverInfo]
  const loading = requests.some(x => x.isLoading || !x.isRequested)
  const failure = requests.map(x => ApiResult.isFailure(x) && x.error).find(x => x)
  const notAdmin = (ApiResult.isSuccess(userInfo) && !userInfo.value.portal_login_allowed) || (failure && failure.stsAuthError !== undefined && failure.stsAuthError.error === "invalid_grant")


  if (loading) {
    return <FullscreenLoader message='Loading user information...' />;
  }
  else if (notAdmin) {
    return <NotAdminPage />
  } else if (failure) {

    return <ServerUnvailable title='The server is unavailable' error={ApiResult.isFailure(serverInfo) ? serverInfo.error.message : ApiResult.isFailure(userInfo)
      ? userInfo.error.message : "Unknown error"}
      onRetry={handleForceUpdate} />
  }

  console.log("severInfo", userInfo)
  console.log("severInfo", serverInfo)

  return (
    <ProviderConsumer
      render={theme => {
        return (<div className="app">
          <NavBar userInfo={ApiResult.getValueOrThrow(userInfo)} serverInfo={ApiResult.getValueOrThrow(serverInfo)} />
          <div className="content">
            <SideNav roles={ApiResult.getValueOrThrow(userInfo).user.roles} />
            <Content backgroundColor={theme.siteVariables.colorScheme.default.background2} roles={ApiResult.getValueOrThrow(userInfo).user.roles} />
          </div>
          <ToastContainer />
        </div>)
      }}
    />
  );
}


function Content({ roles, backgroundColor }: { roles: string[], backgroundColor: string }) {
  const defaultLink = React.useMemo(() => getDefaultLink(roles), [roles])

  if (defaultLink === undefined) {
    return <NotAdminPage />
  }

  return (<main role="main" style={{ backgroundColor }}>
    <div className="scrollContainer">
      <Switch>
        {routeData.filter(d => roles.some(r => d.rolesRequired.includes(r))).map(d => <Route path={d.path} children={d.component} key={d.path} />)}
        <Redirect to={defaultLink ?? "/rooms"} />
      </Switch>
    </div>
  </main>
  );
}

function getDefaultLink(roles: string[]) {
  if (roles.includes("IpfxAdmin")) return "/organisations"
  if (roles.includes("VideoFxAdmin") || roles.includes("VideoFxRooms")) return "/rooms"
  if (roles.includes("BookItAdmin")) return "/bookit/devices"
  if (roles.includes("BookItReporting")) return "/bookit/insights"
  if (roles.includes("VideoFxReporting")) return "/metrics/meeting_type"
  if (roles.includes("UcAdmin")) return "/uc/sites"
  return undefined;
}

interface ProtectedRouteProps {
  children: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode
  rolesRequired: string[]
  roles: string[]
  path: string
}

function ProtectedRoute({ children, rolesRequired: requiredRoles, roles, path }: ProtectedRouteProps) {
  return roles.some(r => requiredRoles.includes(r)) ? <Route path={path} children={children} /> : null
}

export default App
/* <ProtectedRoute rolesRequired={["IpfxAdmin"]} roles={roles} path='/organisations' children={<OrganisationsPage />} />
<ProtectedRoute rolesRequired={["IpfxAdmin"]} roles={roles} path='/organisation_tenants' children={<OrganisationTenantsPage />} />
<ProtectedRoute rolesRequired={["IpfxAdmin"]} roles={roles} path='/nfctags' children={<NfcTagsPage />} />
<ProtectedRoute rolesRequired={["UserAdmin"]} roles={roles} path='/user_accounts' children={<UserAccountsPage />} />
<ProtectedRoute rolesRequired={['VideoFxAdmin', 'VideoFxRooms']} roles={roles} path='/rooms' children={<RoomPage />} />
<ProtectedRoute rolesRequired={['VideoFxAdmin']} roles={roles} path="/packagefeeds" children={<PackageFeedsPage />} />
<ProtectedRoute rolesRequired={['VideoFxAdmin']} roles={roles} path="/escalations" children={<EscalationsPage />} />
<ProtectedRoute rolesRequired={['VideoFxAdmin']} roles={roles} path="/escalation_rules" children={<EscalationRulesPage />} />
<ProtectedRoute rolesRequired={['VideoFxReporting']} roles={roles} path="/escalation_report" children={<EscalationReportPage />} />
<ProtectedRoute rolesRequired={['VideoFxWindowsUpdateAdmin']} roles={roles} path="/windows_updates" children={<WindowsUpdatesPage />} />
<ProtectedRoute rolesRequired={['VideoFxReporting']} roles={roles} path="/metrics" children={<MetricsPage />} />
<ProtectedRoute rolesRequired={['Development']} roles={roles} path="/health/rooms" children={<RoomsHealthPage />} />
<ProtectedRoute rolesRequired={['IpfxAdmin']} roles={roles} path="/settings/cors" children={<CorsSettingsPage />} />
<ProtectedRoute rolesRequired={['BookItAdmin']} roles={roles} path="/bookit/devices" children={<DevicesPage />} />
<ProtectedRoute rolesRequired={['BookItAdmin']} roles={roles} path="/bookit/bookable_resources" children={<MeetingRoomsPage />} />
<ProtectedRoute rolesRequired={['BookItAdmin']} roles={roles} path="/bookit/providers" children={<ProvidersPage />} />
<ProtectedRoute rolesRequired={['BookItAdmin']} roles={roles} path="/bookit/sharing_groups" children={<SharingGroupsPage />} />
<ProtectedRoute rolesRequired={['BookItAdmin']} roles={roles} path="/bookit/online_meeting_providers" children={<OnlineMeetingProvidersPage />} />
<ProtectedRoute rolesRequired={['BookItAdmin']} roles={roles} path="/bookit/locations" children={<LocationsPage />} />
<ProtectedRoute rolesRequired={['BookItAdmin']} roles={roles} path="/bookit/attributes" children={<AttributesPage />} />
<ProtectedRoute rolesRequired={['BookItReporting']} roles={roles} path="/bookit/insights" children={<BookItMetricsPage />} />
<ProtectedRoute rolesRequired={['BookItAdmin']} roles={roles} path="/bookit/organisation_settings" children={<OrganisationSettingsPage />} />
<ProtectedRoute rolesRequired={['Development']} roles={roles} path="/bookit/graph_api" children={<GraphApiPage />} /> */
