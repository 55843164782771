export function toLowercaseRecord<T, V>(obj: Record<string, T>) {
    return toRecord(Object.keys(obj), key => key.toLowerCase(), key => obj[key])
}

export function toRecord<T, V>(arr: T[], keyFn: (t: T) => string, valueFn: (t: T) => V): Record<string, V> {
    const res = {}
    for (const item of arr) {
        res[keyFn(item)] = valueFn(item)
    }
    return res
}

export function flatten<T>(arr: T[][]): T[] {
    return new Array<T>().concat(...arr)
}

export type PropsOf<T, X> = NonNullable<{
    [P in keyof T]: NonNullable<T[P]> extends X ? P : never
}[keyof T]>

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends ((k: infer I) => void) ? I : never;

export type Flatten<T> = {
    [K in Exclude<keyof T, PropsOf<T, object>>]: T[K]
} & UnionToIntersection<T[PropsOf<T, object>]>



export type FlattenUnion<T> = {
    [K in keyof UnionToIntersection<T>]: K extends keyof T ?
    T[K] extends any[] ? T[K]
    : T[K] extends object ? FlattenUnion<T[K]>
    : T[K]
    : UnionToIntersection<T>[K] | undefined
}

type Except<T, U extends keyof any> = Pick<T, Exclude<keyof T, U>>
export type Extend<T, U> = Except<T, keyof U> & U



// convert a comma separated list is of numbers tp array of numbers
export function toArrayNumber(val: string): number[] | undefined {
    let arr: number[]
    try {
        arr = JSON.parse(`[${val}]`)
    } catch (err) {
        return undefined
    }

    if (!Array.isArray(arr) || arr.some(isNaN)) {
        return undefined
    }
    return arr
}

export function isValidEmail(email: string | undefined) {
    if (email === undefined) return false
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }