import React from "react";
import { FaExclamationCircle, FaInfoCircle, FaTimesCircle } from "react-icons/fa";
import { IconBaseProps, IconType } from "react-icons/lib/cjs";
import { UncontrolledCollapse } from "reactstrap";
import { ProblemReport } from "../model";
import { Toast, ToastType } from "../model"
import { instanceOfApiError } from "../utils/error";
import './Toast.css';

interface Props {
    toast: Toast
}

const ToastComponent: React.FC<Props> = ({ toast }) => {


    return (<div className='toast'>
        <ToastIcon type={toast.type} className='toast-icon' />
        <div className='toast-contents'>
            {getContent(toast)}
        </div>
    </div>)
}

function getTitle(toast: Toast) {
    if (toast.header) {
        return toast.header!
    }
    return `${toast.type} Notification`;
}

function getContent(toast: Toast) {
    const data = toast.data
    console.log('display toast', toast)
    let defaultMessage = toast.title
    if (instanceOfApiError(data)) {
        if (data.report) {
            return <ApiContentMessage 
                        report={data.report!}
                        header={toast.header}
                        title={toast.customApiError ? toast.title : data.report!.title}
                        detail={toast.customApiError ? undefined : data.report!.detail}
                        includeDetail={toast.customApiError}
                         />
        }
        defaultMessage = data.message
    }
    return <>
        <header>{getTitle(toast)}</header>
        <p className='toast-message'>{defaultMessage}</p>
    </>
}

const ApiContentMessage: React.FC<{ report: ProblemReport, title: string, detail?: string, header?: string, includeDetail: boolean }> 
        = ({ report, title, detail, header, includeDetail }) => {

    const onClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
        evt.stopPropagation()
    }

    return <div className='toast-problem-content'>
        <header>{header || "Error communicating with VideoFX"}</header>
        <p className='toast-problem-title'>{title}</p>
        {detail && <p className='toast-problem-detail'>{detail}</p>}
        <button className='toast-problem-show-detail' onClick={onClick} id="details">Details...</button>
        <UncontrolledCollapse toggler='#details'>
            <div className='toast-problem-details' >
                <div className='toast-problem-details-headings'>
                    {includeDetail && <p>Title:</p>}
                    {includeDetail && report.detail && <p>Detail:</p>}
                    <p>Error Code:</p>
                    <p>HTTP Status Code:</p>
                    <p>Instance:</p>
                    <p>Type:</p>
                </div>
                <div className='toast-problem-details-data'>
                    {includeDetail && <p>{report.title}</p>}
                    {includeDetail && report.detail && <p>{report.detail}</p>}
                    <p>{report.api_error_code}</p>
                    <p>{report.status}</p>
                    <p>{report.instance}</p>
                    <p>{report.type}</p>
                </div>
            </div>
        </UncontrolledCollapse>
    </div>
}

const ToastIcon: React.FC<{ type: ToastType } & IconBaseProps> = ({ type, ...rest }) => {
    const props = { size: '2.2em', ...rest }
    let Icon: IconType;
    if (type === 'Information') {
        Icon = FaInfoCircle
    } else if (type === 'Warning') {
        Icon = FaExclamationCircle
    } else {
        Icon = FaTimesCircle
    }

    return <Icon {...props} />
}


export default ToastComponent