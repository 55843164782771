import React, { useCallback, useEffect, useState } from "react";
import { Button, Dropdown, Flex, Menu, MenuItemProps, Popup } from "@fluentui/react-northstar";
import css from './SupportDashboard.module.scss';
import BookitTabletStatusReportPage from "./BookitTabletStatusReportPage";
import VfxRoomStatusReportPage from "./VfxRoomStatusReportPage";
import NotificationsReport from "./NotificationsReport";
import DeviceTimeseriesReportPage from "./DeviceTimeseriesReportPage";
import DashboardSummary from "./DashboardSummary"


function SupportDashboard() {

    const [activeIndex, setActiveIndex] = useState(0)

    const tabItems = [
        {
            "key": "summary",
            "content": "Summary"
        },
        {
            "key": "vfx_rooms",
            "content": "VideoFx rooms"
        },
        {
            "key": "bookit_tablets",
            "content": "Bookit devices"
        },
        {
            "key": "status_timeseries",
            "content": "Device status time series"
        },
        {
            "key": "notifications",
            "content": "Notifications"
        }
    ]

    const handleMenuItemClick = useCallback((event: React.SyntheticEvent<HTMLElement>, data?: MenuItemProps) => {
       
        if (data) {
            setActiveIndex(data.index!)
        }
    }
    , [])

    return <Flex className="ReportsPage">
        
        <h5>Support dashboard</h5>

        <Menu defaultActiveIndex={0} 
                activeIndex={activeIndex} 
                onItemClick={handleMenuItemClick}
                items={tabItems} 
                underlined primary
            />

        {activeIndex === 0 && <DashboardSummary />}
        {activeIndex === 1 && <VfxRoomStatusReportPage />}
        {activeIndex === 2 && <BookitTabletStatusReportPage />}
        {activeIndex === 3 && <DeviceTimeseriesReportPage />}
        {activeIndex === 4 && <NotificationsReport />}
        
    </Flex>
}

export default SupportDashboard;
