import React, { useCallback, useEffect, useState, useMemo } from "react";
import ReactTable, { CellInfo, Column } from "react-table";
import { NotificationSeveritySelection, ToastHistory } from "../../../model";
import SeveritySelector from './SeveritySelector';
import css from './SupportDashboard.module.scss';
import { useSelector } from '../../../store/utils'
import * as _ from 'lodash';
import { actions } from '../../../store/types';
import { useDispatch } from 'react-redux';
import moment from "moment";



export function secondsToDhm(seconds: number) {
    const minutes = seconds / 60

    const d = Math.floor(minutes / (60*24));
    const h = Math.floor(minutes % (60*24) / 60);
    const m = Math.floor(minutes % 60);

    const dDisplay = d > 0 ? d + "d " : "";
    const hDisplay = h > 0 ? h + "hr "  : "";
    const mDisplay = m > 0 ? m + "m " : "";

    return dDisplay + hDisplay + mDisplay;
}

function localeTime(dateToDisplay: Date) {
    return moment(dateToDisplay).format('h:mm a')
}

function localeDay(dateToDisplay: Date) {
    return moment(dateToDisplay).format('ddd Do MMM')
}

const columns: Column[] = [{
    headerStyle: {
        borderRight: '1px solid #fff',
    },
    columns: [{
        Header: 'Time',
        headerStyle: {
            textAlign: 'left',
            borderRight: '1px solid #fff',
        },
        minWidth: 10,
        accessor: 'timestamp',
        Cell: (cell) => {
            return (
                <div>
                <div>{localeDay(cell.value)}</div>
                <div>{localeTime(cell.value)}</div>
            </div>
            )
        },
    }]
}
, {
    headerStyle: {
        borderLeft: '1px solid #fff',
        borderRight: '1px solid #fff',
    },
    columns: [{
        Header: 'Severity',
        className: 'leftAlign',
        minWidth: 10,
        defaultSortDesc: true,
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        accessor: 'type',
        Cell: (cell) => cell.value ? cell.value : '',
    }]
}, {
    headerStyle: {
        borderLeft: '1px solid #fff',
        borderRight: '1px solid #fff',
    },
    columns: [{
        Header: 'Title',
        className: 'leftAlign',
        minWidth: 30,
        defaultSortDesc: true,
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        accessor: 'title',
        Cell: (cell) => cell.value ? cell.value : '',
    }]
}, 
{
    headerStyle: {
        borderLeft: '1px solid #fff',
        borderRight: '1px solid #fff',
    },
    columns: [{
        Header: 'Message',
        className: 'leftAlign',
        minWidth: 100,
        defaultSortDesc: true,
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        accessor: 'message',
        Cell: (cell) => cell.value ? cell.value : '',
    }]
}]

interface notificationModel {
    type: string,
    title: string,
    message: string,
    delete: boolean
}

function NotificationsReport() {
    
    const dispatch = useDispatch()
    const dataSource: notificationModel[] = [];
    const isLoading = true;
    const toastsHistory = useSelector(s => s.toastsHistory)
    const [severitySelection, setSeveritySelection] = useState<NotificationSeveritySelection>('all')

    console.log('Toast history', toastsHistory);

    function onSelectionChanged(selection: NotificationSeveritySelection) {
        console.log('onSelectionChanged', selection)
        setSeveritySelection(selection);
    }

    const data = useMemo(() => applyFilterToNotificationModel(), [toastsHistory, severitySelection]);
    
    function applyFilterToNotificationModel() : ToastHistory[] {

        console.log('applyFilterToNotificationModel', toastsHistory.toasts);
        switch(severitySelection) {
            case 'all':
                return toastsHistory.toasts;
            case 'errors':
                return toastsHistory.toasts.filter(t => t.type === 'Error');
            case 'warnings':
                return toastsHistory.toasts.filter(t => t.type === 'Warning');
            case 'information':
                return toastsHistory.toasts.filter(t => t.type === 'Information');
        }

        return [];
    }

    return <div className={css.reportSection}>
    
    <h2>Notifications Report</h2>
    
    <SeveritySelector onSelectionChange={onSelectionChanged} />

    <ReactTable
        className="-striped -highlight"
        showPagination={false}
        key={data.length === 0 ? "nodata" : "havedata"}
        defaultPageSize={data.length === 0 ? 5 : data.length}
        columns={columns}
        defaultSorted={([{ id: "timestamp", desc: true }])}
        data={data}
        //loadingText={<Loader label='Loading...' />}
        //loading={isLoading}
        noDataText={<span>No notifications for this event type</span>}
    />
</div>
}

export default NotificationsReport;
