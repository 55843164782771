import { useState, useEffect } from "react";
import { Button, FilesUploadIcon, Dialog, CloseIcon, PlayIcon } from "@fluentui/react-northstar";
import ReactTable, { Column } from "react-table";
import { useDispatch } from "react-redux";
import { actions } from "../../../../store/types";
import * as _ from 'lodash';
import CSVReader from 'react-csv-reader'
import {  Room, RoomAttribute, Component, VfxRoomBulkLoadItem } from "../../../../model";
import './BulkUpdate.scss';
import { getActiveOrganisation, getRoomNames, getPackageFeeds, getRoom, getRooms } from "../../../../store/selectors";
import { useSelector } from '../../../../store/utils';
import { MdAlarmOn, MdWarning, MdCheckCircle } from "react-icons/md";
import {CreateRoom} from '../../../videofx/room/AddRoomForm'
import { fetchPackageFeeds, fetchRoomNames, fetchRoom, doUpdateVfxRoomsFromCsv } from "../../../../store/actions";
import { HalResult, HalResource } from "../../../../services/Hal";


function BulkLoadVideoFxRooms() {


    const dispatch = useDispatch();

    // Validate upload against this organisation
    const activeOrganisation = useSelector(getActiveOrganisation);
    
    const { isLoaded: isRoomsLoaded, isLoading: isRoomsLoading } = useSelector(s => s.rooms)
    useEffect(() => {
        if (!isRoomsLoaded && !isRoomsLoading && activeOrganisation) {
            dispatch(actions.fetchRooms.request(activeOrganisation.organisation_id));
            dispatch(actions.fetchPackageFeeds.request());
        }
    }, [activeOrganisation, dispatch, isRoomsLoaded, isRoomsLoading])
    
    const rooms = useSelector(getRooms)

    console.log('getRooms', rooms);

    const packageFeeds = useSelector(getPackageFeeds)

    const [isLoading, setIsLoading] = useState(false)
    const [isSelectingFile, setIsSelectingFile] = useState(false);
    const [tableData, setTableData] = useState<VfxRoomBulkLoadItem[]>([]);
    const [canProcess, setCanProcess] = useState(false);
    const [isValidationError, setIsValidationError] = useState(false);
    const [validationErrorMessage, setValidationErrorMessage] = useState<string>('');
    const [fileLoadErrorMessage, setFileLoadErrorMessage] = useState<string>();

    useEffect(() => {
        if (isRoomsLoaded && activeOrganisation && tableData.length > 0) {
            doValidation(tableData);
        }
    }, [isRoomsLoaded, activeOrganisation]);

    // ----------------------------------------------------------
    // Causes the container dialogue to close (and this with it)
    // ----------------------------------------------------------
    const onClose = () => {
        setTableData([]);
        setCanProcess(false);
    }

    function doValidation(data: VfxRoomBulkLoadItem[]) {
        if (validateData(data)) {
            setCanProcess(true);
        } else {
            setCanProcess(false);
        }
    }

    // ----------------------------------------------------------
    // Called by the csv loader if it was successful
    // ----------------------------------------------------------
    function onLoadSuccess(data: VfxRoomBulkLoadItem[]) {
        
        setIsSelectingFile(false);
        
        // We still see empty objects even though the parser has been instructed to ignore
        _.remove(data, (item) => _.isEmpty(item.action) && _.isEmpty(item.context) && _.isEmpty(item.organisation));            
        
        doValidation(data);
        setTableData(data);
    }

    // ----------------------------------------------------------
    // Called by the csv loader if there was an error
    // ----------------------------------------------------------
    function onLoadError(error: Error) {
        setFileLoadErrorMessage(error.message);
        console.log('onLoadError', error);
    }

    // ----------------------------------------------------------
    // Make sure that the file data can be run and provide
    // feedback of errors in the corresponding table row
    // ----------------------------------------------------------
    function validateData(data: VfxRoomBulkLoadItem[]) : Boolean {

        console.log('validateData start data:', data);
        console.log('validateData start rooms:', rooms);

        setIsValidationError(false);
        setValidationErrorMessage('');

        if (data.length === 0) {
            setIsValidationError(true);
            setFileLoadErrorMessage('File contains no data.');
            return false;
        }

        const validatedRows: VfxRoomBulkLoadItem[] = [];
        let isValid = true;
        
        for (let row=0; row < data.length; row++) {
            
            const rowData = data[row];
            let error:string = '';
            let isError = false;

            rowData.process_status = 'valid';
            rowData.process_text = 'Ok to process';

            // Context must be correct: No point in going further
            if (rowData.context !== 'vfxroom') {
                rowData.process_status = 'error';
                rowData.process_text = `The context '${rowData.context}' is not recognised. Must be 'VfxRoom'`;   
            } else if (rowData.organisation !== activeOrganisation.name.toLowerCase()) {
                setIsValidationError(true);
                error = `Organisation ${rowData.organisation} does not match active organisation (${activeOrganisation.name})`;
                rowData.process_status = 'error';
                rowData.process_text = `${error}`;                   
            } else {                    

                // if (rowData.action === 'delete' || rowData.action === 'update') {
                //     error += 'The actions DELETE and UPDATE are not available in this version. ';  
                //     isError = true; 
                //     isValid = false;                       
                if (rowData.action !== 'add' && rowData.action !== 'update' && rowData.action !== 'delete') {
                    error += `Unrecognised action (${rowData.action}). Must be ADD, UPDATE or DELETE. `;   
                    isError = true;
                    isValid = false;  
                }
                    
                if (_.isEmpty(rowData.room_name)) {
                    error += 'A room name must be provided. ';   
                    isError = true;
                    isValid = false;
                } else if (rowData.room_name.indexOf(' ') >= 0) {
                    error += 'A room name cannot contain spaces. ';   
                    isError = true;
                    isValid = false;
                } else if (rowData.action === 'add') {
                    const room = rooms!.find(r => r.resource.name.toLowerCase() === rowData.room_name.toLowerCase());  
                    if (room) {
                        error += `A room already exists for id ${rowData.room_name}.`;   
                        isError = true;
                        isValid = false;
                    }
                }

                if (rowData.action === 'add') {

                    if ( _.isEmpty(rowData.room_email)) {
                        error += 'An email address must be provided. ';   
                        isError = true;
                        isValid = false;
                    } else if (rowData.room_email.indexOf(' ') >= 0 || rowData.room_email.indexOf('@') < 2 || rowData.room_email.indexOf('.') < 1) {
                        error += 'Invalid email address. ';   
                        isError = true;
                        isValid = false;
                    }

                    if (_.isEmpty(rowData.display_name)) {
                        error += 'A display name must be provided.';   
                        isError = true;
                        isValid = false;
                    }
                }

                if (rowData.action === 'update') {
                    const room = rooms!.find(r => r.resource.name.toLowerCase() === rowData.room_name.toLowerCase());  
                    if (!room) {
                        error += `No room found for id ${rowData.room_name}.`;   
                        isError = true;
                        isValid = false;
                    }
                }

                if (isError) {
                    rowData.process_status = 'error';
                    rowData.process_text = error;   
                }
            }

            validatedRows.push(rowData);

        }

        console.log('validateData', validatedRows);

        return isValid;

    }

    // ----------------------------------------------------------
    // An awaitable delay
    // ----------------------------------------------------------
    function delay(ms:number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    // ----------------------------------------------------------
    // Perform the crud operations. because there could potentially
    // be 100's of these, we'll place a short interval between each
    // call to avoid a DOS attack
    // ----------------------------------------------------------
    async function processData() {

        console.log('Processing data');
        
        setCanProcess(false);
        const data = [...tableData];
        
        // Just the adds
        for (let i=0; i < data.length; i++) {
            
            if (data[i].action === 'add') {
                
                const create = mapBulkModelItemToVfxRoomModel(data[i])

                const room: Room = {
                    name: create.roomName!,
                    display_name: create.displayName!,
                    email_address: create.emailAddress!,
                    provisioning_status: "AwaitingProvisioning",
                    room_msi_package_feed_name: create.msiPackageFeed,
                    admin_msi_package_feed_name: data[i].admin_package_feed,
                    template_room_name: create.templateRoom && create.templateRoom! === "None" ? undefined : create.templateRoom,
                    has_license_file: false,
                    organisation_id: activeOrganisation?.organisation_id
                };
                const props = createProps(create);
                const roomProps: Array<{ name: string, value: RoomAttribute }> = props.filter(_ => _.value.value !== undefined) as Array<{ name: string, value: RoomAttribute }>;

                console.log('dispatching');
                dispatch(actions.createRoom.request({ room, attribs: roomProps }))

                data[i].process_status = 'success';
                data[i].process_text = 'Added';      
            }               
            
            await delay(500);
        }

        // Updates

        var updateData = data.filter(d => _.toLower(d.action) === 'update');

        const args = {
            organisation_id: activeOrganisation.organisation_id,
            bulkUpdateItems: updateData
        }

        dispatch(actions.doUpdateVfxRoomsFromCsv.request(args))

        for (let i=0; i < updateData.length; i++) {
            data[i].process_status = 'success';
            data[i].process_text = 'Updated';   
            await delay(500);
        }

        // Deletes
      
        setTableData(data);
    }

    

    function updateRoomComponent(room: HalResult<Room>, propertyName: string, value?: string) {
        
        let component: HalResource<Component> = room.resource.room_component!
        
        if (propertyName === "javascript_package_feed_name") {
            component = room.resource.javascript_package_component!
        } else if (propertyName === "admin_msi_package_feed_name") {
            component = room.resource.admin_component!
        }
        
        const args = { room: room, component: component, updatedComponent: { ...component, package_feed_name: value } };
        dispatch(actions.updateComponentProperties.request(args))
        
    }

    function updateRoomProperty(room: HalResult<Room>, propertyName:string, value?:string) {
        
        console.log('updateRoomProperty', {room, propertyName, value});


        if (propertyName === "template_room_name" && value === "None") {
            value = undefined;
        }
        
        const args = { room: room, updatedRoom: { ...room.resource, [propertyName]: value } };
        console.log('updateRoomProperty', args);

        dispatch(actions.updateRoomProperties.request(args))
    }

    function createProps(create: CreateRoom) {
        return [{
            name: 'exchange_email',
            value: {
                value: create.emailAddress,
                type: 'Provisioning'
            }
        }, {
            name: 'exchange_password',
            value: {
                value: create.exchangePassword,
                data_type: 'Password',
                type: 'Provisioning'
            }
        }, {
            name: 'exchange_username',
            value: {
                value: create.exchangeUsername,
                type: 'Provisioning'
            }
        }
            , {
            name: 'windows_password',
            value: {
                value: create.windowsPassword,
                data_type: 'Password',
                type: 'Provisioning'
            }
        }, {
            name: 'windows_username',
            value: {
                value: create.windowsUsername,
                type: 'Provisioning'
            }
        }]
    }

    
    // ----------------------------------------------------------
    // Convert the local bulk load model to a device model that
    // can be passed to the crud actions.
    // ----------------------------------------------------------
    function mapBulkModelItemToVfxRoomModel(bulkItem: VfxRoomBulkLoadItem) : CreateRoom {

        // Note that the validation method has already established that all referenced resources exist
        // and that the referenced organisation is the current active org.

        return {       
            roomName: bulkItem.room_name,
            displayName: bulkItem.display_name,
            emailAddress: bulkItem.room_email,
            msiPackageFeed: bulkItem.room_package_feed,            
            windowsUsername: bulkItem.windows_user_name,
            windowsPassword: bulkItem.windows_password,
            exchangeUsername: bulkItem.exchange_user_name,
            exchangePassword: bulkItem.exchange_password,
            templateRoom: bulkItem.template_room
        }
    }

    // ----------------------------------------------------------
    // Called by the CSV parser to convert file string data to 
    // the correct type (most types are handled automatically)
    // ----------------------------------------------------------
    function mapValueType(value:string, header:string) {        

        switch(header){
            case 'organisation':
            case 'context':
            case 'action':
            case 'room_email':        
                return _.toLower(_.trim(value));  
            case 'room_name':
            case 'display_name':
            case 'room_package_feed':
            case 'admin_package_feed':
            case 'exchange_email_address':
            case 'exchange_user_name':
            case 'exchange_password':
            case 'windows_user_name':
            case 'windows_password':
                return _.trim(value);    
            default:
                return value;
        }

        return value;
    }

    // ----------------------------------------------------------
    // Control that provides file selection and upload as Csv
    // ----------------------------------------------------------
    function pickCsvFile() {

        const parsingOptions = {
            header: true,
            skipEmptyLines: true,
            quoteChar: '"',
            transformHeader: (header:string, index:number) => _.snakeCase(header),
            transform: (value:string, header:string) => mapValueType(value, header)
        };

        return <CSVReader 
            onFileLoaded={(data, fileInfo, originalFile) => onLoadSuccess(data)}
            onError={(error:Error) => onLoadError(error)}
            parserOptions={parsingOptions} /> 
    }
    
    // ----------------------------------------------------------
    // Cell render function
    // ----------------------------------------------------------
    function renderStatus(cellText:string) {
        return <div className="cellTextWrap">{cellText}</div>
    }

    function renderProcessIcon(cellText:string) {
        if (cellText === 'error') {
            return <MdWarning size="2em" color='red' />
        } else if (cellText === 'valid') {
            return <MdAlarmOn size="2em" color='green' />
        } else if (cellText === 'success') {
            return <MdCheckCircle size="2em" color="green" />
        } else {
            return <div>{cellText}</div>
        }
    }

    // ----------------------------------------------------------
    // ----------------------------------------------------------
    const columns: Column[] = [
        {
        headerStyle: {
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: '',
            headerStyle: {
                textAlign: 'left',
                borderRight: '1px solid #fff',
            },
            minWidth: 40,
            accessor: 'process_status',
            Cell: (cell) => renderProcessIcon(cell.value)
        }]
    }
    , {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Status',
            className: 'leftAlign',
            minWidth: 200,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'process_text',
            Cell: (cell) => renderStatus(cell.value),
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Organisation',
            className: 'leftAlign',
            minWidth: 100,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'organisation',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Context',
            className: 'leftAlign',
            minWidth: 80,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'context',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Action',
            className: 'leftAlign',
            minWidth: 60,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'action',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Name',
            className: 'leftAlign',
            minWidth: 150,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'room_name',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Email',
            className: 'leftAlign',
            minWidth: 150,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'room_email',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Display name',
            className: 'leftAlign',
            minWidth: 150,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'display_name',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    },{
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Room Pkge feed',
            className: 'leftAlign',
            minWidth: 100,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'room_package_feed',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    },{
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Admin Pkge feed',
            className: 'leftAlign',
            minWidth: 100,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'admin_package_feed',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Template room',
            className: 'leftAlign',
            minWidth: 120,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'template_room',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Exch. email',
            className: 'leftAlign',
            minWidth: 150,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'exchange_email',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Exch. User',
            className: 'leftAlign',
            minWidth: 100,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'exchange_user_name',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Exch. password',
            className: 'leftAlign',
            minWidth: 100,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'exchange_password',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Windows user',
            className: 'leftAlign',
            minWidth: 100,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'windows_user_name',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Windows password',
            className: 'leftAlign',
            minWidth: 100,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'windows_password',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }

]

    return <div className="panel">
        
        <div className="buttonRow">
            <Button primary content='Load CSV file' icon={<FilesUploadIcon />} 
                iconPosition='before' disabled={isLoading} onClick={() => setIsSelectingFile(true)} 
                styles={{ marginBottom: '0.5rem' }} />     
            
            {!_.isEmpty(fileLoadErrorMessage) && <div className="errorMessage">{fileLoadErrorMessage}</div>} 
        </div>


        {isSelectingFile && <Dialog
                open={isSelectingFile}
                header='Upload VFX room loader file'
                content={pickCsvFile()}    
                cancelButton="Cancel"  
                onCancel={() => setIsSelectingFile(false)}  
            />}

        {isValidationError && <div>{validationErrorMessage}</div>}

        <div className="gridContainer">
            <ReactTable
                className="-striped -highlight"
                showPagination={true}
                key={tableData.length === 0 ? "nodata" : "havedata"}
                defaultPageSize={tableData.length === 0 ? 5 : tableData.length}
                columns={columns}
                defaultSorted={([{ id: "organisation", desc: true }])}
                data={tableData}
                noDataText={<span>Load a CSV file</span>}
            />
        </div>

        <div className="buttonRow" style={{marginTop: '0.5rem'}}>  
            {canProcess && <Button primary content='Process' icon={<PlayIcon />} 
                iconPosition='before' disabled={isLoading} onClick={() => processData()} 
                styles={{ marginBottom: '0.5rem', marginLeft: '0.5rem' }} />}

            <Button primary content='Cancel' icon={<CloseIcon />} 
                iconPosition='before' disabled={isLoading} onClick={() => onClose()} 
                styles={{ marginBottom: '0.5rem', marginLeft: '0.5rem' }} />
        </div>

    </div>
}

export default BulkLoadVideoFxRooms;