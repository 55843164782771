import { Loader, Button, DownloadIcon } from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactTable, { Column } from "react-table";
import moment from "moment";

import { fetchSecurityReport } from "../../store/actions";
import services from "../../services";
import { getSecurityReport, getSecurityReportIsLoading } from "../../store/selectors";
import { useSelector } from "../../store/utils";
import { ReportFilter } from "../admin/SecurityReportPage";
import './Reports.scss';
import SecurityUserReport from "./SecurityUserReport"
import { fileDownloadFromBlob } from "../../utils/fileDownload";

function localeDay(dateToDisplay: Date) {
    return moment(dateToDisplay).add(moment(dateToDisplay).utcOffset(), 'minutes').format('Do MMM')
}

function localeTime(dateToDisplay: Date) {
    return moment(dateToDisplay).add(moment(dateToDisplay).utcOffset(), 'minutes').format('h:mm a')
}

export function secondsToDhm(seconds: number) {
    const minutes = seconds / 60

    const d = Math.floor(minutes / (60*24));
    const h = Math.floor(minutes % (60*24) / 60);
    const m = Math.floor(minutes % 60);

    const dDisplay = d > 0 ? d + "d " : "";
    const hDisplay = h > 0 ? h + "hr "  : "";
    const mDisplay = m > 0 ? m + "m " : "";

    return dDisplay + hDisplay + mDisplay;
}

const columns: Column[] = [{
    Header: '',
    headerStyle: {
        textAlign: 'left',
    },
    columns: [{
        Header: 'User account',
        minWidth: 200,
        accessor: "user_account",
        headerClassName: 'organiserHeader',
    }]
}, {
    Header: "Portal",
    minWidth: 200,    
    headerStyle: {
        borderLeft: '1px solid #aaa'
    },
    columns: [{
        Header: 'Last login',
        minWidth: 100,
        accessor: "last_portal_login",
        Cell: (cell) => {
            if (cell.value) {
                return (<div><div>{localeDay(cell.value)} {localeTime(cell.value)}</div></div>)
            } else {
                return (<div></div>)
            }
        },
        className: 'centerAlign',
        defaultSortDesc: true,
        headerStyle: {
            borderLeft: '1px solid #aaa',
            borderTop: '1px solid #aaa'
        }
    },    
    {
        Header: '# ok',
        minWidth: 100,
        accessor: "portal_login_count",
        className: 'centerAlign',
        defaultSortDesc: true,
        headerStyle: {
            borderLeft: '1px solid #aaa',
            borderTop: '1px solid #aaa'
        }
    },
    {
        Header: '# failed',
        minWidth: 100,
        accessor: "portal_login_fail_count",
        className: 'centerAlign',
        defaultSortDesc: true,
        headerStyle: {
            borderLeft: '1px solid #aaa',
            borderTop: '1px solid #aaa'
        }
    }

]}, 
{
    Header: "Bookit",
    minWidth: 200,    
    headerStyle: {
        borderLeft: '1px solid #aaa'
    },
    columns: [{
        Header: 'Last login',
        minWidth: 100,
        accessor: "last_bookit_login",
        Cell: (cell) => {
            if (cell.value) {
                return (<div><div>{localeDay(cell.value)} {localeTime(cell.value)}</div></div>)
            } else {
                return (<div></div>)
            }
        },
        className: 'centerAlign',
        defaultSortDesc: true,
        headerStyle: {
            borderLeft: '1px solid #aaa',
            borderTop: '1px solid #aaa'            
        }
    },    
    {
        Header: '# ok',
        minWidth: 100,
        accessor: "bookit_login_count",
        className: 'centerAlign',
        defaultSortDesc: true,
        headerStyle: {
            borderLeft: '1px solid #aaa',
            borderTop: '1px solid #aaa'
        }
    },
    {
        Header: '# failed',
        minWidth: 100,
        accessor: "bookit_login_fail_count",
        className: 'centerAlign',
        defaultSortDesc: true,
        headerStyle: {
            borderLeft: '1px solid #aaa',
            borderTop: '1px solid #aaa'
        }
    }

]},
{
    Header: "Other",
    minWidth: 50,    
    headerStyle: {
        borderLeft: '1px solid #aaa'
    },
    columns: [{    
        Header: "Events", 
        minWidth: 50,
        accessor: "other_event_count",
        className: 'centerAlign',
        defaultSortDesc: true,
        headerStyle: {
            borderLeft: '1px solid #aaa'
        }
    }

]},
{
    Header: "",
    minWidth: 50,    
    headerStyle: {
        borderLeft: '1px solid #aaa'
    },
    columns: [{    
        Header: "Locked", 
        minWidth: 50,
        accessor: "is_account_locked",
        className: 'centerAlign',
        defaultSortDesc: true,
        headerStyle: {
            borderLeft: '1px solid #aaa'
        }
    }

]}
]

 // Export the dataset as a CSV file
 

function SecurityReport({ filter }: { filter: ReportFilter }) {
    
    const dispatch = useDispatch()

    async function doExport(startDate: Date, endDate: Date) {
                
        //setIsReportRunning(true)
    
        try {
    
            const {data,fileName} = await services.serverApi.fetchSecurityReportCsv(startDate!, endDate!)
    
            fileDownloadFromBlob(data, fileName ?? 'organisations_security_report.csv')
    
        } catch (e) {
            console.error('doExport', e)
        }
    
        //setIsReportRunning(false)
    }

    useEffect(() => {
        dispatch(fetchSecurityReport.request({ startRange: filter.startDate?.toDate()!, endRange: filter.endDate?.toDate()! }))
    }, [dispatch, filter.endDate, filter.startDate])

    const data = useSelector(getSecurityReport);
    const isLoading = useSelector(getSecurityReportIsLoading);

    const [expandedList, setExpandedList] = useState({})

    if (isLoading) {
        return <Loader label="Loading Security Report" />
    } else if (data === undefined || data.length === 0) {
        return <div>No data to show. Please change your filters</div>
    } else if (data !== undefined && data.length > 0) {

        console.log('Security report: ', data);
        return (
            <div className="reportContainer">
                <div className="reportSection">
                    <h2>Security Report</h2>
                    {<Button primary content='Export as CSV' 
                            onClick={() => { doExport(filter.startDate?.toDate()!, filter.endDate?.toDate()!) }} 
                            icon={<DownloadIcon />} 
                            iconPosition='before' 
                        />
                    }
                    <ReactTable
                        className="-striped -highlight"
                        showPagination={false}
                        key={data.length === 0 ? "nodata" : "havedata"}
                        defaultPageSize={data.length === 0 ? 0 : data.length}
                        columns={columns}
                        //defaultSorted={([{ id: "room_name", desc: false }])}
                        data={data}
                        loadingText={<Loader label='Loading...' />}
                        loading={isLoading}
                        noDataText={<span>...</span>}
                        collapseOnDataChange={false}
                        SubComponent={ row => {
                            return (
                                <SecurityUserReport filter={filter} userAccount={row.row.user_account}/>
                            )
                        }}
                        expanded={expandedList}
                        onExpandedChange={ (expandedList, indexArray, event) => {
                            var newExpandedList = {}
                            Object.keys(expandedList).forEach(function(key) {
                            if (parseInt(key) === parseInt(indexArray[0])) {
                                //toggle current subcomponent
                                newExpandedList[key] = expandedList[key] ? true : false
                            } else {
                                //other subcomponents set to not expanded
                                newExpandedList[key] = false
                            }
                        })
                        setExpandedList(newExpandedList)
                    }}
                    />
                </div>
            </div>
        )
    } else {
        return <div></div>
    }
}

export default SecurityReport