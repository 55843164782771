import * as _ from 'lodash';
import { getType } from "typesafe-actions";
import { ApiError } from '../../utils/error';
import { Actions, actions } from "../types";
import { Toast } from '../../model'



interface Toasts {
    toasts: {
        [id: number]: Toast;
    }
    newId: number;
}

export default function toasts(state: Toasts = { toasts: {}, newId: 1 }, action: Actions): Toasts {
    switch (action.type) {
        case getType(actions.onReportCustomApiError):
            return { toasts: { ...state.toasts, [state.newId]: toastForApiError(action.payload.api, state.newId, action.payload.errorMessage) }, newId: state.newId + 1 };
        case getType(actions.onReportApiError):
            return { toasts: { ...state.toasts, [state.newId]: toastForApiError(action.payload, state.newId) }, newId: state.newId + 1 };
        case getType(actions.onReportError):
            return { toasts: { ...state.toasts, [state.newId]: { id: state.newId, title: `${action.payload}`, data: action.payload, type: 'Error', customApiError: false } }, newId: state.newId + 1 };
        case getType(actions.onEventEscalation):
            return { toasts: { ...state.toasts, [state.newId]: { id: state.newId, title: `${action.payload.roomName}: ${action.payload.title}`, data: action.payload, type: 'Warning', customApiError: false } }, newId: state.newId + 1 };
        case getType(actions.onReportInformation):
            return { toasts: { ...state.toasts, [state.newId]: { id: state.newId, title: action.payload.message, header: action.payload.title, data: action.payload, type: 'Information', customApiError: false } }, newId: state.newId + 1 };
        case getType(actions.onEventEscalationCleared):
            return { toasts: { ...state.toasts, [state.newId]: { id: state.newId, title: `${action.payload.roomName}: ${action.payload.title}`, data: action.payload, type: 'Information', customApiError: false } }, newId: state.newId + 1 };
        case getType(actions.toastShown):
            return { ...state, toasts: _.omit(state.toasts, action.payload) };
        case getType(actions.onNfcTagScanned):
            return { toasts: { ...state.toasts, [state.newId]: { id: state.newId, title: `${action.payload.emailAddress} scanned NFC Tag ${action.payload.tagName} (${action.payload.tagType})`, data: action.payload, type: 'Information', customApiError: false } }, newId: state.newId + 1 };
        default:
            return state;
    }
}

function defaultErrorMessage(err: ApiError) {
    return err.report ? err.report!.detail || err.report!.title : err.message
}

export function toastForApiError(apiError: ApiError, newId: number, customErrorMessage?: (err: ApiError) => string|null): Toast {
    
    const customMessage = customErrorMessage && customErrorMessage(apiError)

    console.log('customMessage', customMessage) 

    if (customMessage) {
        return {
            id: newId,
            title: customMessage,
            header: apiError.report!.title,
            data: apiError,
            type: 'Error',
            customApiError: true
        }
    }

    let headerStr: string|undefined = undefined;
    if (apiError.statusCode !== undefined
        && apiError.statusCode === 400
        &&  apiError.report !== undefined
        ) {
            headerStr = "There was a problem with that request"
        }
    
    return {
        id: newId,
        header: headerStr,
        title: defaultErrorMessage(apiError),
        data: apiError,
        type: 'Error',
        customApiError: false
    }
}