import { Button, Flex, Popup, DownloadIcon } from "@fluentui/react-northstar";

import { ChartData, ChartOptions, ChartTooltipItem, ChartType } from "chart.js";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, RouteComponentProps, Switch } from "react-router";
import useToggle from "../../hooks/useToggle";
import { AggregateColumnType, AggregateData } from "../../model";
import { getActiveOrganisation } from "../../store/selectors";
import { actions } from "../../store/types";
import { useSelector } from "../../store/utils";
import RangeSelector, { getRange } from "../controls/RangeSelector";
import MetricsChart from "./MetricsChart";
import './MetricsPage.scss';
import RoomSelector from "./RoomSelector";

const MetricsPage: React.FC<{}> = () => {

    const [rangeOpen, toggleRangeOpen] = useToggle(false)
    const [roomSelectionOpen, toggleRoomSelectionOpen] = useToggle(false)
    const [selectedRoomName, setSelectedRoomName] = useState<string>()
    const [selectedRange, setSelectedRange] = useState<string>("last7d")
    const [rangeDescription, setRangeDescription] = useState("Last 7 days")
    const [roomDescription, setRoomDescription] = useState("All Rooms")

    const [defaultRange] = useState(getRange("last7d"))
    const [startDate, setStartDate] = useState<moment.Moment | undefined>(moment(defaultRange.startDate))
    const [endDate, setEndDate] = useState<moment.Moment | undefined>(moment(defaultRange.endDate))

    const activeOrganisation = useSelector(getActiveOrganisation)
    const roomNames = useSelector(s => s.rooms.roomNames)
    const dispatch = useDispatch()
    useEffect(() => {
        if (roomNames === undefined && activeOrganisation) {
            // This can happen when user role did not allow for all rooms to be fetched.
            // In this case just get room names
            dispatch(actions.fetchRoomNames.request(activeOrganisation.organisation_id))
        }
    }, [activeOrganisation, dispatch, roomNames])

    const toggleRoomSelection = () => {
        if (rangeOpen) {
            toggleRange();
        }
        toggleRoomSelectionOpen()
    }

    const toggleRange = () => {
        if (roomSelectionOpen) {
            toggleRoomSelection();
        }
        toggleRangeOpen()
    }

    const onApplyRange = (sDate: moment.Moment, eDate: moment.Moment, range: string, description: string, toggle: boolean) => {
        setRangeDescription(description)
        setSelectedRange(range)
        if (description === "All") {
            setStartDate(undefined)
            setEndDate(undefined)
        } else {
            setStartDate(sDate)
            setEndDate(eDate)
        }
        if (toggle) {
            toggleRange();
        }
    }

    const onApplyRoomSelection = (selected?: string) => {
        setSelectedRoomName(selected)
        setRoomDescription(selected === undefined ? "All Rooms" : selected!)
        toggleRoomSelection();
    }

    const renderMetricsChart = useCallback((routeProps: RouteComponentProps<{ name: string }>) =>
        renderMetrics(selectedRoomName, startDate, endDate, routeProps), [selectedRoomName, startDate, endDate])


    //console.log(`start: ${startDate}, end: ${endDate}, selectedRange=${selectedRange}`)

   
    const rangeContent = (
        <Flex column>
            <Flex.Item align="end">
                <Button text iconOnly icon="close" onClick={() => toggleRange()} title="Close" />
            </Flex.Item>
            <RangeSelector startDate={startDate} endDate={endDate} onApply={onApplyRange} onCancel={toggleRange} selectedRange={selectedRange} />
        </Flex>
    )
    const roomSelectionContent = (
        <Flex column>
            <Flex.Item align="end">
                <Button text iconOnly icon="close" onClick={() => toggleRoomSelection()} title="Close" />
            </Flex.Item>
            <RoomSelector selectedRoomName={selectedRoomName} onApply={onApplyRoomSelection} onCancel={toggleRoomSelection} roomNames={roomNames ?? []} />
        </Flex>
    )

    return (
        <>
            <h5>Metrics</h5>
            <div>
                <Popup
                    trapFocus
                    trigger={<Button primary onClick={() => toggleRange()}>{rangeDescription}</Button>}
                    content={rangeContent}
                    open={rangeOpen}
                    onOpenChange={() => toggleRangeOpen()}
                />

                <Popup
                    trapFocus
                    trigger={<Button primary onClick={() => toggleRoomSelection()} style={{ marginLeft: '0.25rem' }}>{roomDescription}</Button>}
                    content={roomSelectionContent}
                    open={roomSelectionOpen}
                    onOpenChange={() => toggleRoomSelection()}
                />

              

            </div>

            <Route path="/metrics/:name" render={renderTitle} />

            {!(selectedRange !== "all" && startTime === undefined) &&
                <Switch>
                    <Route path="/metrics/:name" render={renderMetricsChart} />
                </Switch>
            }
        </>
    )
}


function renderTitle({ match }: RouteComponentProps<{ name: string }>) {
    const col = match.params.name as AggregateColumnType;
    return (<h5 className='text-center mb-5' > {getTitle(col)}</h5>);
}

function getTitle(col: AggregateColumnType) {
    switch (col) {
        case 'meeting_type': return "Meeting count by type"
        case 'room_by_meeting_count': return "Meeting count by room"
        case 'room_by_usage_duration': return "Total usage by room (hours)"
        case 'duration': return "Meeting durations"
        case 'start_time': return "Meetings by start time"
        case 'participant_count': return "Meetings by participant count"
        case 'booking_type': return "Booking type"
        case 'meeting_booking_type': return 'Meeting count by type'
        case 'booking_meeting_type': return 'Meeting count by type'
    }
    return col as string;
}


function renderMetrics(selectedRoomName: string | undefined, 
                        startDate: moment.Moment | undefined, 
                        endDate: moment.Moment | undefined, 
                        { match }: RouteComponentProps<{ name: string }>) {
    
    const col = match.params.name as AggregateColumnType;

    let opts: {
        aggregateFn: (data: AggregateData) => { labels: string[], data: number[] };
        hideLegend: boolean;
        type: ChartType
        chartOpts?: ChartOptions
    } =
        { aggregateFn: identityData, hideLegend: false, type: 'pie' }

    //console.log(`Render metrics: start: ${startDate}, end: ${endDate}, col=${col}`)

    switch (col) {
        case 'start_time':
            opts = {
                aggregateFn: startTime, hideLegend: true, type: 'bar', chartOpts: {
                    scales: { yAxes: [{ ticks: { beginAtZero: true } }] }
                },
            };
            break;
        case 'duration':
            opts = {
                aggregateFn: identityData, hideLegend: true, type: 'bar', chartOpts: {
                    scales: { yAxes: [{ ticks: { beginAtZero: true } }] }
                },
            };
            break;
        case 'participant_count':
            opts = {
                aggregateFn: participantCount, hideLegend: true, type: 'bar', chartOpts: {
                    scales: { yAxes: [{ ticks: { beginAtZero: true } }] }
                },
            };
            break;
        case 'room_by_usage_duration':
            opts = {
                aggregateFn: roomByUsageDuration, hideLegend: false, type: 'pie', chartOpts: {
                    tooltips: { callbacks: { label: durationTooltipsLabel } }
                },
            };
            break;
        case 'booking_type':
            opts = {aggregateFn: identityData, hideLegend: true, type: 'bar', chartOpts: {
                scales: { yAxes: [{ ticks: { beginAtZero: true } }] }
                },
            };
            break;
        case 'meeting_booking_type':
            opts = {
                aggregateFn: identityData, hideLegend: true, type: 'bar', chartOpts: {
                    scales: { yAxes: [{ ticks: { beginAtZero: true } }] }
                },
            };
            break;
        case 'booking_meeting_type':
            opts = {
                aggregateFn: identityData, hideLegend: true, type: 'bar', chartOpts: {
                    scales: { yAxes: [{ ticks: { beginAtZero: true } }] }
                },
            };
            break;
    }
    return (
        <MetricsChart className='chart' key={col} type={opts.type} hideLegend={opts.hideLegend} aggregateCol={col} aggregateFn={opts.aggregateFn} opts={opts.chartOpts}
            roomName={selectedRoomName} startRange={startDate && startDate.valueOf()} endRange={endDate && endDate.valueOf()} />
    );
}

function durationTooltipsLabel(item: ChartTooltipItem, data: ChartData) {
    const durations = data.datasets![0].data! as number[]
    return `${data.labels![item.index!]}: ${durations[item.index!]} hours`
}

function identityData(data: AggregateData) {
    return { labels: data.data.map((val, index) => val.key), data: data.data.map((val, index) => val.value) }
}

function roomByUsageDuration(data: AggregateData) {
    return { labels: data.data.map((val, index) => val.key), data: data.data.map((val, index) => Math.round(moment.duration(val.value).asHours() * 10) / 10) }
}

function startTime(aggregateData: AggregateData): { labels: string[], data: number[] } {
    const offset = Math.trunc(moment().utcOffset() / 60)

    const adjusted = aggregateData.data.map(val => {
        let i = parseInt(val.key, 10)

        i = i + offset;

        if (i > 23) {
            i = 0 + (i - 24)
        }
        if (i < 0) {
            i = 24 + i
        }

        return { hour: i, count: val.value }
    })

    const sorted = adjusted.sort((l, r) => l.hour - r.hour)

    const labels = sorted.map(({ hour: i }) => {
        if (i >= 12) {
            if (i > 12) {
                i = i - 12;
            }
            return `${i}PM`;
        }
        return `${i}AM`;
    })
    const data = sorted.map(val => val.count)

    return { labels, data }
}

function participantCount(data: AggregateData) {
    return {
        labels:
            data.data.map((val, index) => {
                const i = parseInt(val.key, 10);
                if (i === 1) {
                    return '1 participant';
                }
                return `${i} participants`

            }), data: data.data.map((val, index) => val.value)
    }
}

export default MetricsPage;