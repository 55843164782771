import { AddIcon, ArrowUpIcon, Button, Dialog, FilesUploadIcon, Flex, Loader, Text, TrashCanIcon, Checkbox } from "@fluentui/react-northstar";
import * as FileSaver from 'file-saver';
import moment from 'moment';
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { DiJsBadge } from 'react-icons/di';
import { FaArchive, FaCheck } from "react-icons/fa";
import { MdErrorOutline } from "react-icons/md";
import { useDispatch } from "react-redux";
import { BarLoader } from 'react-spinners';
import ReactTable, { CellInfo } from "react-table";
import { BackgroundOperation, FeedUpdateComponentsResult, PackageFeed, PackageVersion } from "../../model";
import { HalResource } from "../../services/Hal";
import { patchPackageFeed } from "../../store/actions";
import { InstallLatestState } from "../../store/reducers/packages";
import { getLatestPackageInfos, PackageFeedInfo } from "../../store/selectors";
import { actions } from "../../store/types";
import { useSelector } from "../../store/utils";
import BackgroundOperationProgressDialog from "../controls/BackgroundOperationProgressDialog";
import ConfirmationDialog from "../controls/ConfirmationDialog";
import FileUploadForm from "../controls/FileUploadForm";
import { IntermediateToolbarItem, ToolbarWithTooltips } from "../controls/ToolbarWithTooltips";
import AddFeedForm, { CreateFeed } from "./AddFeedForm";
import "./PackageFeedsPage.css";
import VirusScansTable from "./VirusScansTable";
import { compare } from "fast-json-patch";

function PackageFeedsPage() {

        const [addFeedDialogOpen, setAddFeedDialogOpen] = useState(false)
        const [uploadingForFeed, setUploadingForFeed] = useState<PackageFeed>()
        const [feedToDelete, setFeedToDelete] = useState<PackageFeed>()
        const [feedToUpdate, setFeedToUpdate] = useState<PackageFeed>()
        const [installingLatestProgressDialogOpen, setInstallingLatestProgressDialogOpen] = useState(false)
        const [installDismissed, setInstallDismissed] = useState(false)

        const installingLatest = useSelector(s => s.packages.installingLatest)
        const packages = useSelector(getLatestPackageInfos)
        const uploadingNewVersionForFeedName = useSelector(s => s.packages.uploadingNewVersionForFeedName)
        const isCreatingFeed = useSelector(s => s.packages.isCreatingFeed)
        const isLoadingAll = useSelector(s => s.packages.isLoadingAll)
        const virusScans = useSelector(s => s.packages.virus_scans)


        const dispatch = useDispatch()

        useEffect(() => {
            if (virusScans === null) {
                dispatch(actions.fetchVirusScans.request())
            }
        }, [dispatch, virusScans])

        const handleDeleteFeed = useCallback(() => {
            dispatch(actions.deletePackageFeed.request(feedToDelete!))
            setFeedToDelete(undefined)
        }, [dispatch, feedToDelete])

        useEffect(() => {
            setInstallDismissed(installingLatest !== undefined && installingLatest.operation.state === 'Dismissed')
        }, [installingLatest])

        const handleUpdateFeed = useCallback(() => {
            setInstallingLatestProgressDialogOpen(true)
            dispatch(actions.updateComponentsInPackageFeed.request(feedToUpdate!))
            setFeedToUpdate(undefined)
        }, [dispatch, feedToUpdate])

        useEffect(() => {
            dispatch(actions.fetchPackageFeeds.request())
        }, [dispatch])

        useEffect(() => {
            if (packages !== undefined) {
                const downloadCompleted = packages.filter(pkg => pkg.image !== undefined && pkg.image.isLoading && pkg.image.data !== undefined)
                downloadCompleted.forEach(completed => {
                    FileSaver.saveAs(completed.image!.data!, completed.image!.name)
                    dispatch(actions.downloadPackageVersionComplete(completed.latest!))
                })
            }
        }, [packages, dispatch])

        const handleDownload = (version: HalResource<PackageVersion>) => {
            return (event: React.MouseEvent<HTMLButtonElement>) => {
                event.preventDefault()
                dispatch(actions.downloadPackageVersion.request(version))
            };
        }

        const renderLatest = (cellInfo: CellInfo) => {
            const pkgInfo: PackageFeedInfo = cellInfo.original
            if (pkgInfo.latest !== undefined) {
                return (
                    <>
                        <button className='link-button' onClick={handleDownload(pkgInfo.latest)}>{pkgInfo.latest.version}</button>
                        {(pkgInfo.image && pkgInfo.image.isLoading) && <BarLoader height={2} />}
                    </>
                )
            }
            return null;
        }

        const handleFeedDelete = useCallback((feed: PackageFeedInfo) => {
            setFeedToDelete(feed.pkg)
        }, [])

        const renderFeedName = (cellInfo: CellInfo) => {
            const feed: PackageFeed = cellInfo.value.pkg
            return (<div className='feed-name'>
                {feed.type === 'JavascriptPackage' && <DiJsBadge />}
                {feed.type !== 'JavascriptPackage' && <FaArchive />}
                <p className='feed-name-text'>{feed.feed_name}</p>
            </div>)
        }

        const renderAutomaticUpdate = (cellInfo: CellInfo) => {
            if (cellInfo.value) {
                const time = moment.utc(cellInfo.value, 'HH:mm:ss').local()
                time.local()
                return <p>{time.format('LT')}</p>
            }
            return <Text timestamp>None</Text>
        }

        const renderLastUpdate = (cellInfo: CellInfo) => {
            if (cellInfo.value) {
                const time = moment.utc(cellInfo.value).local()
                time.local()
                return <p>{time.format('lll')}</p>
            }
            return null
        }

        function onActiveChanged(pkg: PackageFeed) {

            
            console.log('onActiveChanged', pkg);

            // IMPORTANT: Only one package per type can be the default, so there may be two updates per change

            const updatedPackage = { ...pkg, default: !pkg.default }
            const diff = compare(pkg, updatedPackage)

            console.log('onActiveChanged', diff);
            console.log('onActiveChanged', updatedPackage);

            dispatch(patchPackageFeed.request({ feed_name: pkg.feed_name, operations: diff }))

        }

        const renderIsDefaultPackage = (cellInfo: CellInfo) => {

            console.log('renderIsDefaultPackage', cellInfo);  
            const pkg = cellInfo.original.pkg;           
            return <Checkbox checked={cellInfo.value} toggle onChange={() => onActiveChanged(pkg)} />                

        }

        const handleFeedUpdate = useCallback((feed: PackageFeed) => {
            if (installingLatest && !installDismissed) {
                // install in progress just open the dialog
                setInstallingLatestProgressDialogOpen(true)
                return
            }

            setFeedToUpdate(feed)
        }, [installDismissed, installingLatest])

        const handleDismissBackgroundOperationDialog = useCallback((done: boolean) => {
            setInstallingLatestProgressDialogOpen(false)
            if (done) {
                dispatch(actions.dismissComponentUpdate())
            }
        }, [dispatch])

        const renderActions = useCallback((cellInfo: CellInfo) => {
            const updateItem = getUpdateToolbarItem(cellInfo.original.pkg, installDismissed, installingLatest)
            return (<ToolbarWithTooltips
                items={[{
                    key: 'upload',
                    icon: <FilesUploadIcon  outline/>,
                    tooltip: 'Upload new MSI',
                    disabled: uploadingNewVersionForFeedName !== undefined,
                    onClick: () => setUploadingForFeed(cellInfo.original.pkg)
                },
                {
                    ...updateItem,
                    onClick: () => handleFeedUpdate(cellInfo.original.pkg)
                },
                {
                    key: 'delete',
                    icon: <TrashCanIcon outline/>,
                    tooltip: 'Delete this feed',
                    onClick: () => handleFeedDelete(cellInfo.original)
                }]}
            />)
        }, [handleFeedDelete, handleFeedUpdate, installDismissed, installingLatest, uploadingNewVersionForFeedName])

        const closeUploadDialog = () => {
            setUploadingForFeed(undefined)
            dispatch(actions.clearUploadNewPackageVersion())
        }

        const uploadPackage = (file: File) => {
            dispatch(actions.uploadNewPackageVersion.request({ packageName: uploadingForFeed!.feed_name, file }))
            setUploadingForFeed(undefined)
        }

        const handleAddNewFeed = (feed: CreateFeed) => {
            setAddFeedDialogOpen(false)

            dispatch(actions.createPackageFeed.request({
                feed_name: feed.feed_name!,
                type: feed.feed_type!,
                component_type: feed.component_type,
                description: feed.description!,
                automatic_update_time_utc: feed.automatic_update
            }))
        }

        const cols = [
            // { Header: "#", accessor: "seq", maxWidth: 30, Cell: (cell: CellInfo) => <p className='font-weight-bold'>{cell.viewIndex + 1}</p> },
            { Header: "Feed Name", id: "feed_name", minWidth: 80, Cell: renderFeedName, accessor: (row: PackageFeedInfo) => row },
            { Header: "Description", id: "description", minWidth: 150, maxWidth: 400, accessor: (row: PackageFeedInfo) => row.pkg.description },
            { Header: "Component Type", id: "component_type", minWidth: 100, maxWidth: 150, accessor: (row: PackageFeedInfo) => row.pkg.component_type },
            { Header: "Current Version", id: "current_version", maxWidth: 110, Cell: renderLatest },
            { Header: "Last Updated", id: "last_updated", maxWidth: 110, accessor: (row: PackageFeedInfo) => row.latest !== undefined ? moment(row.latest.date_created_utc).fromNow(false) : "never" },
            { Header: "Automatic Update", maxWidth: 130, accessor: "pkg.automatic_update_time_utc", Cell: renderAutomaticUpdate },
            { Header: "Last Update Check", maxWidth: 130, accessor: "pkg.last_automatic_update_check_utc", Cell: renderLastUpdate },
            // { Header: "Default?", minWidth: 40, accessor: "pkg.default", Cell: renderIsDefaultPackage },
            { Header: "", id: "update_new", maxWidth: 110, Cell: renderActions }
        ]

        const addFeedButton = <Button primary content='Add Feed' loading={isCreatingFeed} icon={<AddIcon/>} iconPosition='before' disabled={isCreatingFeed} styles={{ marginBottom: '0.5rem' }} />
        const addFeedContent = isCreatingFeed
            ? <Flex hAlign='end'>{addFeedButton}</Flex>
            : (<Flex hAlign='end'>
                <Dialog
                    content={<AddFeedForm onSubmit={handleAddNewFeed} onCancel={() => setAddFeedDialogOpen(false)} />}
                    header="Add a new package feed for VideoFX"
                    open={addFeedDialogOpen}
                    onOpen={() => setAddFeedDialogOpen(true)}
                    trigger={addFeedButton}
                />
            </Flex>)

        return <Flex column>
            {virusScans && virusScans.length > 0 && <VirusScansTable virusScans={virusScans} />}

            <h5 className="packageFeedTitle">Package Feeds</h5>
            {addFeedContent}
            <ReactTable<PackageFeedInfo>
                showPagination={false}
                columns={cols}
                minRows={5}
                defaultPageSize={packages === undefined || packages.length === 0 ? 0 : packages.length}
                // defaultSorted={([{ id: "status", desc: false }, { id: "name", desc: false }])}
                data={packages}
                noDataText="No data available"
                loadingText={<Loader label='Loading package feeds...' />}
                loading={isLoadingAll}
            />

            {uploadingForFeed && <Dialog
                open={uploadingForFeed != null}
                header={`Upload new version for ${uploadingForFeed.feed_name}`}
                content={<FileUploadForm onCancel={closeUploadDialog} onSubmit={uploadPackage}
                    accept={uploadingForFeed.type === 'Msi' ? ".msi, application/x-msi" : ".zip, application/zip"} />}
            />}

            {installingLatest && <BackgroundOperationProgressDialog
                onDismiss={handleDismissBackgroundOperationDialog}
                operation={installingLatest.operation}
                renderStatus={renderOperationStatus}
                heading={`Installing updates for package feed ${installingLatest.feedName}`}
                isOpen={installingLatestProgressDialogOpen}
            />}

            <ConfirmationDialog onConfirm={handleUpdateFeed}
                onCancel={() => setFeedToUpdate(undefined)}
                message={`Are you sure you wish update all components in the feed ${(feedToUpdate ? feedToUpdate!.feed_name : "")}?`}
                heading='Update Feed'
                confirmButtonText='Update'
                isOpen={feedToUpdate !== undefined} />

            <ConfirmationDialog onConfirm={handleDeleteFeed}
                onCancel={() => setFeedToDelete(undefined)}
                message={`Are you sure you wish to delete the feed ${(feedToDelete ? feedToDelete!.feed_name : "")}?`}
                heading='Delete Feed'
                confirmButtonText='Delete'
                isOpen={feedToDelete !== undefined} />

        </Flex>
    }

function renderOperationStatus(operation: BackgroundOperation) {

    if (operation.state === 'Failed') {
        return <Flex column>
            <Text content={operation.status}/>
            <Text error content={operation.result?.ErrorDetail ?? "Unknown error"} />
        </Flex> 
    }

    const result: FeedUpdateComponentsResult = operation.result

    if (result) {
        return <Flex column>
            <Text>{operation.status}</Text>
            {result.deployed_rooms.length > 0 && result.deployed_rooms.map((room, idx) => <span key={`success_${idx}`}>
                    <Text content={`${room} `}/>
                    <Text success content='(Success)' />
                </span>)}
            {result.failed_rooms.length > 0 && result.failed_rooms.map((room, idx) => <span key={`fail_${idx}`}>
                    <Text content={`${room.name} `}/>
                    <Text className="errorText" error content='(Failed)' title={room.message}/>
                </span>)}
        </Flex>
    }
    return <Text>{operation.status}</Text>
}

function getUpdateToolbarItem(feed: PackageFeed, markedDismissed: boolean, installingLatest?: InstallLatestState): IntermediateToolbarItem {
    var item: IntermediateToolbarItem = {
        key: 'update',
        tooltip: 'Update all components in the feed',
    }

    if (installingLatest === undefined || markedDismissed) {
        item.icon = <ArrowUpIcon outline/>
        return item
    }

    if (feed.feed_name !== installingLatest.feedName) {
        item.icon = <ArrowUpIcon outline/>
        item.disabled = true
    } else if (installingLatest.operation.state === 'InFlight' ||
        installingLatest.operation.state === 'InProgress') {
        item.icon = <Loader size='smallest' />
    } else if (installingLatest.operation.state === 'Complete') {
        item.icon = <FaCheck color='green' />
    } else {
        item.icon = <MdErrorOutline color='red' />
    }
    return item
}


export default PackageFeedsPage;