import * as _ from 'lodash';
import { getType } from "typesafe-actions";
import { BackgroundOperation, BlobData, IdType, isPackageVersion, makePackageVersionKey, PackageFeed, PackageVersion, VirusScan } from "../../model";
import { HalResource } from "../../services/Hal";
import { Guid } from '../../utils/guid';
import { dismissComponentUpdate, updateComponentsInPackageFeed, updateComponentsInPackageFeedNotification } from '../actions';
import { Actions, actions } from "../types";

export interface InstallLatestState {
    feedName: string
    operation: BackgroundOperation
}
interface Packages {
    feeds?: {
        [name: string]: HalResource<PackageFeed>
    },
    latestPackageVersions?: {
        [name: string]: HalResource<PackageVersion>
    },
    packageBlobs?: {
        [key: string]: BlobData
    }
    isLoadingAll: boolean
    isCreatingFeed: boolean
    uploadingNewVersionForFeedName?: string
    installingLatest?: InstallLatestState,
    virus_scans: VirusScan[] | null
}

export default function packages(state: Packages = { isLoadingAll: true, isCreatingFeed: false, virus_scans: null }, action: Actions): Packages {

    switch (action.type) {
        case getType(actions.fetchPackageFeeds.success):
            const packages = action.payload.resources;
            const latestVersions: HalResource<PackageVersion>[] = packages.filter(pkg => pkg._embedded && pkg._embedded!.latest_version !== undefined).map(pkg => pkg._embedded!.latest_version)
            return {
                ...state,
                feeds: _.keyBy(packages, "feed_name"),
                latestPackageVersions: _.keyBy(latestVersions, "feed_name"),
                isLoadingAll: false
            };
        case getType(actions.createPackageFeed.request):
            return {
                ...state,
                isCreatingFeed: true
            }
        case getType(actions.createPackageFeed.success):
            return {
                ...state,
                feeds: { ...state.feeds, [action.payload.resource.feed_name]: action.payload.resource },
                isCreatingFeed: false
            }
        case getType(actions.createPackageFeed.failure):
            return {
                ...state,
                isCreatingFeed: false
            }
        case getType(actions.patchPackageFeed.request):
            return {
                ...state,
                isCreatingFeed: true
            }
        case getType(actions.patchPackageFeed.success):
            return {
                ...state,
                feeds: { ...state.feeds, [action.payload.resource.feed_name]: action.payload.resource },
                isCreatingFeed: false
            }
        case getType(actions.patchPackageFeed.failure):
            return {
                ...state,
                isCreatingFeed: false
            }
        case getType(actions.deletePackageFeed.success):
            const { [action.payload]: deletedFeed, ...updatedFeeds } = state.feeds!;
            return {
                ...state,
                feeds: updatedFeeds
            }
        case getType(actions.fetchPackageFeeds.request):
            return { ...state, isLoadingAll: true };
        case getType(actions.fetchPackageFeeds.failure):
            return {
                ...state,
                feeds: undefined,
                isLoadingAll: false
            }
            case getType(actions.patchPackageFeed.request): {
                return {
                    ...state,
                    installingLatest: {
                        feedName: action.payload.feed_name,
                        operation: makeOperation(Guid.createEmpty())
                    }
                }
            }
            case getType(actions.patchPackageFeed.success): {
                return {
                    ...state,
                    installingLatest: {
                        feedName: state.installingLatest!.feedName,
                        operation: makeOperation(Guid.createEmpty())
                    }
                }
            }
        case getType(actions.downloadPackageVersion.request):
            return {
                ...state,
                packageBlobs: { ...state.packageBlobs, [makePackageVersionKey(action.payload)]: { isLoading: true, failed: false } }
            };
        case getType(actions.downloadPackageVersion.success):
            const key = makePackageVersionKey(action.payload.version);
            return {
                ...state,
                packageBlobs: { ...state.packageBlobs, [key]: { ...state.packageBlobs![key], data: action.payload.data, name: action.payload.fileName, failed: false } }
            };
        case getType(actions.downloadPackageVersion.failure):

            return {
                ...state,
                packageBlobs: {
                    ...state.packageBlobs,
                    [makePackageVersionKey(action.payload.version)]: { isLoading: false, failed: true }
                }
            };
        case getType(actions.downloadPackageVersionComplete):
            const delKey = makePackageVersionKey(action.payload);

            const { [delKey]: deleted, ...newState } = state.packageBlobs!;

            return {
                ...state,
                packageBlobs: newState
            };
        case getType(actions.uploadNewPackageVersion.request):
            return {
                ...state,
                uploadingNewVersionForFeedName: action.payload.packageName
            };
        case getType(actions.uploadNewPackageVersion.success):
            if (isPackageVersion(action.payload)) {
                return {
                    ...state,
                    uploadingNewVersionForFeedName: undefined,
                    latestPackageVersions: {
                        ...state.latestPackageVersions,
                        [action.payload.feed_name]: action.payload
                    }
                }
            }
            return state
        case getType(actions.uploadNewPackageVersion.failure):
            return {
                ...state,
                uploadingNewVersionForFeedName: undefined
            };
        case getType(actions.fetchVirusScans.success): {
            return {
                ...state,
                virus_scans: action.payload
            }
        }
        case getType(actions.clearUploadNewPackageVersion): {
            return {
                ...state,
                uploadingNewVersionForFeedName: undefined
            };
        }
        case getType(updateComponentsInPackageFeed.request): {
            return {
                ...state,
                installingLatest: {
                    feedName: action.payload.feed_name,
                    operation: makeOperation(Guid.createEmpty())
                }
            }
        }
        case getType(updateComponentsInPackageFeed.success): {
            console.log(`updateComponentsInPackageFeed.success: payload=${action.payload}`, action.payload)
            return {
                ...state,
                installingLatest: {
                    feedName: state.installingLatest!.feedName,
                    operation: makeOperation(action.payload)
                }
            }
        }
        case getType(updateComponentsInPackageFeedNotification): {
            if (state.installingLatest === undefined) return state
            return {
                ...state,
                installingLatest: {
                    feedName: state.installingLatest.feedName,
                    operation: action.payload
                }
            }
        }
        case getType(dismissComponentUpdate): {
            return {
                ...state,
                installingLatest: {
                    feedName: state.installingLatest!.feedName,
                    operation: {
                        ...state.installingLatest!.operation,
                        state: 'Dismissed'
                    }
                }
            }
        }
        case getType(updateComponentsInPackageFeed.failure): {
            return {
                ...state,
                installingLatest: undefined
            }
        }
        default:
            return state;
    }
}

function makeOperation(id: IdType): BackgroundOperation {
    return {
        background_operation_id: id,
        state: _.isEmpty(id) ? 'InFlight' : 'InProgress',
        status: _.isEmpty(id) ? 'Sending request...' : 'Updating components in the package feed...',
        started_time_utc: '',
        is_indeterminate: true
    }
}


