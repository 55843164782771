import {
    AggregateColumnType, AggregateData, AllowedOrganisation, BlobData, BookableResource, BookingCount, BookItAttribute,
    BookItLocation, CalendarDelegation, ConnectedDevice, EscalationReport, SecurityReport, SecurityUserReport, 
    HeatMap, IdType, makePackageVersionKey, MinMedianMax, VfxRoomStatusReport, BookitTabletStatusReport, DeviceTimeseriesReport,
    Organisation, PackageFeed, PackageVersion, PeriodSummary, Provider, RoomWindowsUpdate, Tag, UcSite, UserAccount
} from "../model";
import { HalResource } from "../services/Hal";
import { ApiResourceState } from "./reducers/ApiResourceState";
import { ApiResult } from "./reducers/ApiResult";
import { RootState } from "./types";

export function getCollectedLogsForRoom(state: RootState, roomName: string): BlobData | undefined {
    return state.adminLogs.collectedLogs !== undefined ? state.adminLogs.collectedLogs[roomName] : undefined
}

export function getLogsMetadataForRoom(state: RootState, roomName: string) {
    const metadata = state.adminLogs.logFileMetadata[roomName.toLowerCase()]
    return metadata ? metadata : {}
}

export function getRoom(state: RootState, roomName: string) {
    return state.rooms.rooms !== undefined ? state.rooms.rooms![roomName.toLowerCase()] : undefined;
}

export function getRooms(state: RootState) {
    return state.rooms.rooms !== undefined ? Object.values(state.rooms.rooms!) : undefined;
}

export function getRoomNames(state: RootState) {
    return state.rooms.rooms !== undefined ? Object.keys(state.rooms.rooms!) : undefined;
}

export function getRoomWindowsUpdates(state: RootState, roomName: string): Array<{ resource: RoomWindowsUpdate, last_error?: string }> | undefined {
    const updates = state.rooms!.windowsUpdates[roomName]
    return updates !== undefined ? Object.values(updates) : undefined;
}

export function getBookableResources(state: RootState): Array<BookableResource> {
    return getOrganisationResource(state, state => state.bookit.bookableResources)
}


export function getCalendarDelegations(state: RootState): Array<CalendarDelegation> {
    return getOrganisationResource(state, state => state.bookit.calendarDelegations)
}

export function getConnectedDevices(state: RootState): Array<ConnectedDevice> {
    return getOrganisationResource(state, state => state.bookit.connectedDevices)
}

export function getBookableResourceById(state: RootState, id: IdType): BookableResource|undefined {
    const bookableResources = state.bookit.bookableResources
    const activeOrganisation = state.app.activeOrganisation

    if (activeOrganisation) {
        return Object.values(bookableResources.resources).find(br => br.organisation_id === activeOrganisation.organisation_id && br.bookable_resource_id === id)
    }
    return undefined;
}

export function getLocations(state: RootState): Array<BookItLocation> {
    return getOrganisationResource(state, state => state.bookit.locations);
}

export function getProviders(state: RootState): Array<Provider> {
    return getOrganisationResource(state, state => state.bookit.providers);
}

function getOrganisationResource<TResource extends {organisation_id: IdType}>(state: RootState, selector: (state: RootState) => ApiResourceState<TResource>) {
    const resourceState = selector(state)
    const activeOrganisation = state.app.activeOrganisation
    if (resourceState !== undefined && activeOrganisation) {
        return Object.values(resourceState.resources).filter(r => r.organisation_id === activeOrganisation.organisation_id)
    }
    return [];
}

export function getSite(state: RootState, siteId: string): UcSite {
    const activeOrganisation = state.app.activeOrganisation
    if (!activeOrganisation) throw Error("Must have an activeOrganisation")
    return state.uc.sites.resources[`${activeOrganisation.organisation_id}_${siteId}`]
}

export function getAttributes(state: RootState): Array<BookItAttribute> {
    const attributes = state.bookit.attributes.resources;
    if (attributes !== undefined) {
        return Object.values(attributes)
    }
    return [];
}

export function getAggregateData(state: RootState, aggregateCol: AggregateColumnType) {
    const data: HalResource<AggregateData> | undefined = state.metrics.metrics !== undefined ? state.metrics.metrics![aggregateCol] : undefined;
    return data
}

export function getHeatmap(state: RootState): HeatMap | undefined {
    return state.bookit.metrics.heatmap.heatMap
}

export function getHeatmapIsLoading(state: RootState): boolean {
    return state.bookit.metrics.heatmap.isLoading
}

export function getSummary(state: RootState): Array<PeriodSummary> | undefined {
    return state.bookit.metrics.summary.summary
}

export function getSummaryIsLoading(state: RootState): boolean {
    return state.bookit.metrics.summary.isLoading
}
export function getBookingCount(state: RootState): Array<BookingCount> | undefined {
    return state.bookit.metrics.bookingcount.bookingCount
}

export function getMinMedianMax(state: RootState): Array<MinMedianMax> {
    const stats = state.bookit.metrics.minmedianmax.minMedianMax;
    if (stats !== undefined) {
        return stats
    }
    return [];
}

export function getBookingStats(state: RootState) {
    return state.bookit.metrics.bookingstats.bookingStats;
}

export function getBookingStatsIsLoading(state: RootState) {
    return state.bookit.metrics.bookingstats.isLoading
}

export function getOrganiserBookingStats(state: RootState, organiser: string) {
    return state.bookit.metrics.organiserbookingstats[organiser.toLowerCase()]?.data
}

export function getOrganiserBookingStatsIsLoading(state: RootState, organiser: string) {
    return state.bookit.metrics.organiserbookingstats[organiser.toLowerCase()]?.isLoading ?? false
}

export function getOrganiserBookingStatsFilter(state: RootState, organiser: string) {
    return state.bookit.metrics.organiserbookingstats[organiser.toLowerCase()] ?? {}
}

export function getDevices(state: RootState) {
    const devices = state.bookit.devices.resources
    const activeOrganisation = state.app.activeOrganisation
    if (devices !== undefined && activeOrganisation !== false) {
        return Object.values(devices).filter(d => d.organisation_id === activeOrganisation.organisation_id)
    }
    return [];
}

export function getPackageFeeds(state: RootState) {
    if (state.packages.feeds) {
        return Object.keys(state.packages.feeds!)
            .map(key => state.packages.feeds![key])
    }
    return undefined;
}


export function getUserAccounts(state: RootState): Array<UserAccount> {
    const users = state.userAccounts.users.resources;
    if (users !== undefined) {
        return Object.values(users)
    }
    return [];
}

export function getOrganisation(state: RootState, organisationId: IdType): Organisation|undefined {
    const orgs = state.organisations.organisations.resources;
    if (orgs !== undefined) {
        return orgs[organisationId.toString()]
    }
    return undefined
}

export function getOrganisations(state: RootState): Array<Organisation> {
    const orgs = state.organisations.organisations.resources;
    if (orgs !== undefined) {
        return Object.values(orgs)
    }
    return [];
}

export function getLoggedInUser(state: RootState) {
    return ApiResult.getValueOrThrow(state.app.userInfo).user
}

export function getLoggedInUserRoles(state: RootState): string[] {
    return ApiResult.isSuccess(state.app.userInfo) ? state.app.userInfo.value.user.roles : []
}

export function getActiveOrganisation(state: RootState): AllowedOrganisation {
    if (state.app.activeOrganisation === false) {
        throw new Error("Active organisation should not be null")
    }
    return state.app.activeOrganisation
}

export interface PackageFeedInfo {
    pkg: HalResource<PackageFeed>,
    latest?: HalResource<PackageVersion>,
    image?: BlobData
}

export function getLatestPackageInfos(state: RootState): PackageFeedInfo[] | undefined {
    if (state.packages.feeds === undefined) {
        return undefined;
    }
    const data = Object.values(state.packages.feeds!);
    return data.map(pkg => {
        const latest = state.packages.latestPackageVersions !== undefined ? state.packages.latestPackageVersions[pkg.feed_name] : undefined;
        return {
            pkg, latest,
            image: latest && state.packages.packageBlobs ? state.packages.packageBlobs[makePackageVersionKey(latest)] : undefined
        };
    });
}

export function getReportingRoomGroups(state: RootState): Array<Tag> {
    const tags = state.reporting.reportingRoomGroups.data;
    if (tags !== undefined) {
        return Object.values(tags).map(a => a)
    }
    return [];
}

export function getEscalationsReport(state: RootState): Array<EscalationReport> {
    return state.reporting.escalationsReport.data || [];
}

export function getEscalationsReportIsLoading(state: RootState): boolean {
    return state.reporting.escalationsReport.isLoading
}

export function getRoomoteDevices(state: RootState) {
    const roomoteDevices = state.videofx.roomoteDevices.resources
    const activeOrganisation = state.app.activeOrganisation
    if (roomoteDevices !== undefined && activeOrganisation !== false) {
        return Object.values(roomoteDevices).filter(d => d.organisation_id === activeOrganisation.organisation_id)
    }
    return [];
}

export function getSecurityReport(state: RootState): Array<SecurityReport> {
    return state.reporting.securityReport.data || [];
}

export function getSecurityUserReport(state: RootState): Array<SecurityUserReport> {
    return state.reporting.securityUserReport.data || [];
}

export function getSecurityReportIsLoading(state: RootState): boolean {
    return state.reporting.securityReport.isLoading
}

export function getSecurityUserReportIsLoading(state: RootState): boolean {
    return state.reporting.securityUserReport.isLoading
}

export function getVfxRoomStatusReport(state: RootState): Array<VfxRoomStatusReport> {
    return state.reporting.vfxRoomStatusReport.data || [];
}

export function getVfxRoomStatusReportIsLoading(state: RootState): boolean {
    return state.reporting.vfxRoomStatusReport.isLoading
}

export function getBookitTabletStatusReport(state: RootState): Array<BookitTabletStatusReport> {
    return state.reporting.bookitTabletStatusReport.data || [];
}

export function getBookitTabletStatusReportIsLoading(state: RootState): boolean {
    return state.reporting.bookitTabletStatusReport.isLoading
}

 export function getDeviceTimeseriesReport(state: RootState): Array<DeviceTimeseriesReport> {
     return state.reporting.deviceTimeseriesReport.data || [];
 }

export function getDeviceTimeseriesReportIsLoading(state: RootState): boolean {
    return state.reporting.deviceTimeseriesReport.isLoading
}
