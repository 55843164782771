import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from '../../../store/utils'
import { getActiveOrganisation } from "../../../store/selectors";
import { fetchBookitTabletStatusReport } from "../../../store/actions";
import { getBookitTabletStatusReport, getBookitTabletStatusReportIsLoading } from "../../../store/selectors";
import { fetchVfxRoomStatusReport } from "../../../store/actions";
import { getVfxRoomStatusReport, getVfxRoomStatusReportIsLoading } from "../../../store/selectors";

import { Button, Dropdown, Flex, Menu, MenuItemProps, Popup } from "@fluentui/react-northstar";
import { ChartLegendOptions, ChartOptions, ChartType } from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2';

import css from './SupportDashboard.module.scss';
import { NotificationSeveritySelection, ToastHistory } from "../../../model";

const colours: string[] = [
    '#e23d3d',
    '#36A2EB',
    '#488440',
    '#FFCE56',
    '#e27c3c',
    '#8e3c96',
    '#c0d185',
    '#2c307c'
  ];

function DashboardSummary() {

    const dispatch = useDispatch()
    const activeOrganisation = useSelector(getActiveOrganisation)

    // Notifications
    const toastsHistory = useSelector(s => s.toastsHistory);

    const toastColours = ['#FF3333','#00B33C','#3366ff'] //getColours(videoFxRoomData.length);
    
    const toastChartData = {
        labels: ['Errors','Warnings','Information'],
        datasets: [{
            data: getToastDataForChart(),
            backgroundColor: toastColours,
            hoverBackgroundColor: toastColours
        }]
    };

    function getToastDataForChart() {
        return [
            toastsHistory.toasts.filter(t => t.type == 'Error').length,
            toastsHistory.toasts.filter(t => t.type == 'Warning').length,
            toastsHistory.toasts.filter(t => t.type == 'Information').length];
    }

    // Bookit tablets
    useEffect(() => {
        if (activeOrganisation) {
            dispatch(fetchBookitTabletStatusReport.request({ organisation_id: activeOrganisation.organisation_id }))
        }
    }, [dispatch, activeOrganisation])

    const bookitTabletData = useSelector(getBookitTabletStatusReport);

    const bookitColours = ['#00B33C','#FF3333','#3366ff'] //getColours(videoFxRoomData.length);
    
    const bookitChartData = {
        labels: ['Running','Stopped','Never run'],
        datasets: [{
            data: getBookitDataForChart(),
            backgroundColor: bookitColours,
            hoverBackgroundColor: bookitColours
        }]
    };

    function getBookitDataForChart() {
        return [
            bookitTabletData.filter(d => d.status == 'Running').length,
            bookitTabletData.filter(d => d.status == 'Stopped').length,
            bookitTabletData.filter(d => d.status == 'NeverRun').length];
    }

    // VideoFx Rooms
    useEffect(() => {
        if (activeOrganisation) {
            dispatch(fetchVfxRoomStatusReport.request({ organisation_id: activeOrganisation.organisation_id }))        
        }
    }, [dispatch, activeOrganisation])

    const videoFxRoomData = useSelector(getVfxRoomStatusReport);

    const vfxColours = ['#00B33C','#FF3333','#3366ff'] //getColours(videoFxRoomData.length);
    
    const vfxChartData = {
        labels: ['Running','Stopped','Never run'],
        datasets: [{
            data: getVfxRoomDataForChart(),
            backgroundColor: vfxColours,
            hoverBackgroundColor: vfxColours
        }]
    };

    const legend: ChartLegendOptions = {
        position: 'left',
        display: true,
    }

    function getVfxRoomDataForChart() {
        return [
            videoFxRoomData.filter(d => d.status == 'Running').length,
            videoFxRoomData.filter(d => d.status == 'Stopped').length,
            videoFxRoomData.filter(d => d.status == 'NeverRun').length];
    }

    const opts: ChartOptions = {maintainAspectRatio: false};

    return <div className={css.summaryChartsContainer}>

        <div className={css.pieGraph}>
            <h2>VideoFx Rooms ({videoFxRoomData.length})</h2>
            <div style={{position:'relative', height:'400px', width:'400px'}}>
                <Pie data={vfxChartData} legend={legend} options={opts} />
            </div>  
        </div>

        <div className={css.pieGraph}>
            <h2>Bookit devices ({bookitTabletData.length})</h2>
            <div style={{position:'relative', height:'400px', width:'400px'}}>
                <Pie data={bookitChartData} legend={legend} options={opts} />
            </div>  
        </div>

        <div className={css.pieGraph}>
            <h2>Notifications ({toastsHistory.toasts.length})</h2>
            <div style={{position:'relative', height:'400px', width:'400px'}}>
                {toastsHistory.toasts.length > 0 && <Pie data={toastChartData} legend={legend} options={opts} />}
            </div>  
        </div>


    </div>
}

function getColours(count: number) {
    const col: string[] = [];
    for (let i: number = 0; i < count; i++) {
      col.push(colours[i % colours.length]);
    }
    return col;
  }

export default DashboardSummary;