import { useEffect, useState } from "react";
import { Button, FilesUploadIcon, Dialog, CloseIcon, PlayIcon } from "@fluentui/react-northstar";
import ReactTable, { Column } from "react-table";
import { useDispatch } from "react-redux";
import * as _ from 'lodash';
import CSVReader from 'react-csv-reader'
import { actions } from "../../../../store/types";
import { createBookItDevice, fetchBookableResources, fetchBookItDevices, patchBookItDevice } from "../../../../store/resourceActions";
import {CreateBookItDevice, DefaultScreenType, IdType, BulkLoadDeviceItem} from "../../../../model";
import './BulkUpdate.scss';
import { getActiveOrganisation, getBookableResources, getDevices } from "../../../../store/selectors";
import { useSelector } from '../../../../store/utils';
import { MdAlarmOn, MdWarning, MdCheckCircle } from "react-icons/md";
import { compare } from "fast-json-patch";

function BulkLoadDevices() {

    
    const dispatch = useDispatch();

    const activeOrganisation = useSelector(getActiveOrganisation);

    // --------------- Bookable resources
    const { isLoaded: isRoomsLoaded, isLoading: isRoomsLoading } = useSelector(s => s.bookit.bookableResources)
    useEffect(() => {
        if (!isRoomsLoaded && !isRoomsLoading && activeOrganisation) {
            dispatch(fetchBookableResources.request({ organisation_id: activeOrganisation.organisation_id }))
        }
    }, [activeOrganisation, dispatch, isRoomsLoaded, isRoomsLoading])

    const bookableResources = useSelector(getBookableResources);    

    // --------------- Devices
    const { isLoaded: isDevicesLoaded, isLoading: isDevicesLoading } = useSelector(s => s.bookit.devices)
    
    useEffect(() => {
        if (!isDevicesLoaded && !isDevicesLoading && activeOrganisation) {
            dispatch(fetchBookItDevices.request({ organisation_id: activeOrganisation.organisation_id }))
        }
    }, [activeOrganisation, dispatch, isDevicesLoaded, isDevicesLoading])

    const devices = useSelector(getDevices);       

    const [isLoading, setIsLoading] = useState(false)
    const [isSelectingFile, setIsSelectingFile] = useState(false);
    const [tableData, setTableData] = useState<BulkLoadDeviceItem[]>([]);
    const [canProcess, setCanProcess] = useState(false);
    const [isValidationError, setIsValidationError] = useState(false);
    const [validationErrorMessage, setValidationErrorMessage] = useState<string>('');
    const [fileLoadErrorMessage, setFileLoadErrorMessage] = useState<string>();

    useEffect(() => {
        if (isDevicesLoaded && isRoomsLoaded && activeOrganisation && tableData.length > 0) {
            doValidation(tableData);
        }
    }, [isDevicesLoaded, isRoomsLoaded, activeOrganisation]);

    // ----------------------------------------------------------
    // Causes the container dialogue to close (and this with it)
    // ----------------------------------------------------------
    const onClose = () => {
        setTableData([]);
        setCanProcess(false);
    }

    function doValidation(data: BulkLoadDeviceItem[]) {
        if (validateData(data)) {
            setCanProcess(true);
        } else {
            setCanProcess(false);
        }
    }

    // ----------------------------------------------------------
    // Called by the csv loader if it was successful
    // ----------------------------------------------------------
    function onLoadSuccess(data: BulkLoadDeviceItem[]) {
        
        setIsSelectingFile(false);  
        
        // We still see empty objects even though the parser has been instructed to ignore
        _.remove(data, (item) => _.isEmpty(item.action) && _.isEmpty(item.context) && _.isEmpty(item.organisation));  

        doValidation(data);    
        setTableData(data);
    }

    // ----------------------------------------------------------
    // Called by the csv loader if there was an error
    // ----------------------------------------------------------
    function onLoadError(error: Error) {
        console.log('onLoadError', error);
        setFileLoadErrorMessage(error.message);
    }

    function normaliseField(field: string) : string {
        return _.trim(_.toLower(field));
    }

    // ----------------------------------------------------------
    // Make sure that the file data can be run and provide
    // feedback of errors in the corresponding table row
    // ----------------------------------------------------------
    function validateData(data: BulkLoadDeviceItem[]) : Boolean {

        console.log('validateData start data:', data);
        console.log('validateData start br:', bookableResources);

        setIsValidationError(false);
        setValidationErrorMessage('');

        if (data.length === 0) {
            setIsValidationError(true);
            setFileLoadErrorMessage('File contains no data.');
            return false;
        }

        const validatedRows: BulkLoadDeviceItem[] = [];
        let isValid = true;
        
        for (let row=0; row < data.length; row++) {
            
            const rowData = data[row];
            let error:string = '';
            rowData.process_status = 'valid';
            rowData.process_text = 'Ok to process';

            // Context must be correct - if it isn't then go no further
            if (normaliseField(rowData.context) !== 'bookitdevice') {
                
                rowData.process_status = 'error';
                rowData.process_text = `The context '${rowData.context}' is not recognised. Must be 'BookitDevice'`;   
                isValid = false;  

            } else if (normaliseField(rowData.organisation) !== activeOrganisation.name.toLowerCase()) {
                setIsValidationError(true);
                error = `Organisation ${rowData.organisation} does not match active organisation (${activeOrganisation.name})`;
                rowData.process_status = 'error';
                rowData.process_text = `${error}`;   
                isValid = false;         
            
            } else { // If the organisation is invalid, then the remaining tests are moot

                // Referenced rooms must exist for this organisation
                let br = bookableResources.find(res => normaliseField(res.email_address) === normaliseField(rowData.primary_room));
                
                if (!br) {
                    setIsValidationError(true);
                    error = `Primary room ${rowData.primary_room} could not be found. `;
                    rowData.process_status = 'error';
                    rowData.process_text = `${error}`;
                    isValid = false;
                }

                for(let j=0; j < rowData.other_rooms.length; j++) {
                    
                    if (_.isEmpty(rowData.other_rooms[j])) {
                        continue;
                    }

                    br = bookableResources.find(res => normaliseField(res.email_address) === normaliseField(rowData.other_rooms[j]));
                    
                    if (!br) {                                               
                        setIsValidationError(true);
                        error = `${error}Other room ${rowData.other_rooms[j]} could not be found. `;
                        rowData.process_status = 'error';
                        rowData.process_text = `${error}`;  
                        isValid = false;                  
                    }
                }

                if (normaliseField(rowData.action) === 'delete') {
                    rowData.process_status = 'error';
                    rowData.process_text = 'The actions DELETE and UPDATE are not available in this version';   
                    isValid = false;   
                } else if (normaliseField(rowData.action) !== 'add' && normaliseField(rowData.action) !== 'update') {
                    rowData.process_status = 'error';
                        rowData.process_text = `Unrecognised action (${rowData.action}). Only ADD and UPDATE accepted in this release.`;   
                        isValid = false;  
                }                

            }

            validatedRows.push(rowData);

        }

        console.log('validateData', validatedRows);

        return isValid;

    }

    // ----------------------------------------------------------
    // An awaitable delay
    // ----------------------------------------------------------
    function delay(ms:number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    // ----------------------------------------------------------
    // Perform the crud operations. because there could potentially
    // be 100's of these, we'll place a short interval between each
    // call to avoid a DOS attack
    // ----------------------------------------------------------
    async function processData() {

        console.log('Processing data');
        
        setCanProcess(false);
        const data = [...tableData];
        
        // Just the adds
        await processAdds(data.filter(item => _.toLower(item.action) === 'add'));

        // Updates
        await processUpdates(data.filter(item => _.toLower(item.action) === 'update'));
        
        // Deletes
        

        setTableData(data);
    }

    async function processAdds(data: BulkLoadDeviceItem[]) {

        for (let i=0; i < data.length; i++) {
            
            const device = mapBulkModelItemToBookitDeviceModel(data[i])
            dispatch(createBookItDevice.request(device))

            data[i].process_status = 'sucess';
            data[i].process_text = 'Success';                        
            
            await delay(1000);
        }

    }

    async function processUpdates(data: BulkLoadDeviceItem[]) {
        
        for (let i=0; i < data.length; i++) {
            
            const item = data[i];
            const originalDevice = devices.find(dev => _.toLower(_.toString(dev.device_id)) === _.toLower(_.toString(item.device_id)));                
            const updatedItem = mapBulkModelItemToBookitDeviceModel(item);

            if (!originalDevice) {
                data[i].process_status = 'error';
                data[i].process_text = 'Unable to find existing device';  
            }

            const updatedDevice = {
                ...originalDevice,
                bookable_resource_ids: updatedItem.bookable_resource_ids,
                primary_room_id: updatedItem.primary_room_id,        
                organisation_id: activeOrganisation.organisation_id
            }
            
            // take out original roomIds so we always treat them as an add operation
            const original = { name: originalDevice!.name, default_screen: originalDevice!.default_screen, 
                                pin: originalDevice!.pin, organisation_id: originalDevice!.organisation_id,
                                device_id: originalDevice?.device_id
                             }

            const diff = compare(original, updatedDevice);
            
            console.log("processUpdates: original", original)
            console.log("processUpdates: updatedDevice", updatedDevice)
            console.log("processUpdates: diff", diff)
             
            const compositeId = {
                organisation_id: activeOrganisation.organisation_id, 
                device_id: originalDevice!.device_id
            };

            const args = {
                id: compositeId,
                operations: diff
            };

            // After all this work, there might be nothing to do
            if (diff.length > 0) {
                dispatch(patchBookItDevice.request(args));
            }

            data[i].process_status = 'sucess';
            data[i].process_text = 'Success';                        
            
            await delay(1000);
        }



    }

    // ----------------------------------------------------------
    // Convert the local bulk load model to a device model that
    // can be passed to the crud actions.
    // ----------------------------------------------------------
    function mapBulkModelItemToBookitDeviceModel(bulkItem: BulkLoadDeviceItem) : CreateBookItDevice {

        // Note that the validation method has already established that all referenced resources exist
        // and that the referenced organisation is the current active org.

        const otherRoomIds:IdType[] = [];

        for(let j=0; j < bulkItem.other_rooms.length; j++) {
            const br = bookableResources.find(res => res.email_address.toLowerCase() === bulkItem.other_rooms[j]);            
            otherRoomIds.push(br!.bookable_resource_id);
        }

        const primaryRoom = bookableResources.find(res => res.email_address.toLowerCase() === bulkItem.primary_room);

        if (!primaryRoom) {
            return {
                name: bulkItem.name,
                default_screen: bulkItem.default_screen,
                pin: bulkItem.pin,
                primary_room_id: otherRoomIds[0],
                organisation_id: activeOrganisation.organisation_id,
                bookable_resource_ids: otherRoomIds
            };
        }

        const primaryRoomId = primaryRoom.bookable_resource_id;
        otherRoomIds.push(primaryRoomId);

        return {
            name: bulkItem.name,
            default_screen: bulkItem.default_screen,
            pin: bulkItem.pin,
            primary_room_id: primaryRoomId,
            organisation_id: activeOrganisation.organisation_id,
            bookable_resource_ids: otherRoomIds
        };
    }

    // ----------------------------------------------------------
    // Called by the CSV parser to convert file string data to 
    // the correct type (most types are handled automatically)
    // ----------------------------------------------------------
    function mapValueType(value:string, header:string) {        

        switch(header){
            case 'other_rooms':
                const rooms = _.split(value, ',');
                for (let i=0; i < rooms.length; i++) {
                    rooms[i] = _.toLower(_.trim(rooms[i]));
                }
                return rooms;     
            case 'organisation':
            case 'context':
            case 'action':
            case 'default_screen':
                return _.toLower(_.trim(value));  
            case 'name':
            case 'pin':
            case 'primary_room':
                return _.trim(value);    
            default:
                return value;
        }

        return value;
    }

    // ----------------------------------------------------------
    // Control that provides file selection and upload as Csv
    // ----------------------------------------------------------
    function pickCsvFile() {

        const parsingOptions = {
            header: true,
            skipEmptyLines: true,
            quoteChar: '"',
            transformHeader: (header:string, index:number) => _.snakeCase(header),
            transform: (value:string, header:string) => mapValueType(value, header)
        };

        return <CSVReader 
            onFileLoaded={(data, fileInfo, originalFile) => onLoadSuccess(data)}
            onError={(error:Error) => onLoadError(error)} 
            parserOptions={parsingOptions} /> 
    }
    
    // ----------------------------------------------------------
    // Cell render function
    // ----------------------------------------------------------
    function renderStatus(cellText:string) {
        return <div className="cellTextWrap">{cellText}</div>
    }

    function renderProcessIcon(cellText:string) {
        if (cellText === 'error') {
            return <MdWarning size="2em" color='red' />
        } else if (cellText === 'valid') {
            return <MdAlarmOn size="2em" color='green' />
        } else if (cellText === 'sucess') {
            return <MdCheckCircle size="2em" color="green" />
        } else {
            return <div>{cellText}</div>
        }
    }

    function renderOtherRooms(cellText:string[]) {
        if (!cellText || cellText.length === 0) {
            return '';
        }

        return cellText.join();
    }

    // ----------------------------------------------------------
    // ----------------------------------------------------------
    const columns: Column[] = [
        {
        headerStyle: {
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: '',
            headerStyle: {
                textAlign: 'left',
                borderRight: '1px solid #fff',
            },
            minWidth: 40,
            accessor: 'process_status',
            Cell: (cell) => renderProcessIcon(cell.value)
        }]
    }
    , {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Status',
            className: 'leftAlign',
            minWidth: 400,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'process_text',
            Cell: (cell) => renderStatus(cell.value),
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Organisation',
            className: 'leftAlign',
            minWidth: 100,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'organisation',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Context',
            className: 'leftAlign',
            minWidth: 100,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'context',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Action',
            className: 'leftAlign',
            minWidth: 60,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'action',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Name',
            className: 'leftAlign',
            minWidth: 150,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'name',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'PIN',
            className: 'leftAlign',
            minWidth: 80,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'pin',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Screen',
            className: 'leftAlign',
            minWidth: 80,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'default_screen',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    },{
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Primary room',
            className: 'leftAlign',
            minWidth: 200,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'primary_room',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Other rooms',
            className: 'leftAlign',
            minWidth: 300,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'other_rooms',
            Cell: (cell) => renderOtherRooms(cell.value),
        }]
    }]

    return <div className="panel">
        
        <div className="buttonRow">
            <Button primary content='Load CSV file' icon={<FilesUploadIcon />} 
                iconPosition='before' disabled={isLoading} onClick={() => setIsSelectingFile(true)} 
                styles={{ marginBottom: '0.5rem' }} /> 

            {!_.isEmpty(fileLoadErrorMessage) && <div className="errorMessage">{fileLoadErrorMessage}</div>}    

        </div>

        {isSelectingFile && <Dialog
                open={isSelectingFile}
                header='Upload Device Loader file'
                content={pickCsvFile()} 
                cancelButton="Cancel"  
                onCancel={() => setIsSelectingFile(false)}  
            />}

        {isValidationError && <div>{validationErrorMessage}</div>}

        <div className="gridContainer">
            <ReactTable
                className="-striped -highlight"
                showPagination={true}
                key={tableData.length === 0 ? "nodata" : "havedata"}
                defaultPageSize={tableData.length === 0 ? 5 : tableData.length}
                columns={columns}
                defaultSorted={([{ id: "organisation", desc: true }])}
                data={tableData}
                noDataText={<span>Load a CSV file</span>}
            />
        </div>

        <div className="buttonRow" style={{marginTop: '0.5rem'}}>  
            {canProcess && <Button primary content='Process' icon={<PlayIcon />} 
                iconPosition='before' disabled={isLoading} onClick={() => processData()} 
                styles={{ marginBottom: '0.5rem', marginLeft: '0.5rem' }} />}

            {/* <Button primary content='Cancel' icon={<CloseIcon />} 
                iconPosition='before' disabled={isLoading} onClick={() => onClose()} 
                styles={{ marginBottom: '0.5rem', marginLeft: '0.5rem' }} /> */}
        </div>

    </div>
}

export default BulkLoadDevices;