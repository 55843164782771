import { AddIcon, Button, Dialog, EditIcon, Flex, Loader, MoreIcon, RetryIcon, SyncIcon, Toolbar, ToolbarMenu, Tooltip, TrashCanIcon, DownloadIcon } from "@fluentui/react-northstar";
import { compare } from "fast-json-patch";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AiFillAndroid, AiFillApple } from 'react-icons/ai';
import { MdExitToApp } from "react-icons/md";
import { useDispatch } from "react-redux";
import ReactTable, { CellInfo } from "react-table";
import { BookableResource, BookItDevice, IdType } from "../../model";
import { createBookItDevice, deleteBookItDevice, fetchBookableResources, fetchBookItDevices, patchBookItDevice } from "../../store/resourceActions";
import { getActiveOrganisation, getBookableResources, getConnectedDevices, getDevices } from "../../store/selectors";
import { useSelector } from '../../store/utils';
import { actions } from '../../store/types';
import { Guid } from "../../utils/guid";
import ConfirmationDialog from "../controls/ConfirmationDialog";
import AddDevice, { CreateDevice } from "./AddDevice";
import BulkLoadDevices from "../admin/Support/BulkUpdates/BulkLoadDevices";
import './DevicesPage.scss';
import services from "../../services";

import { CSVLink, CSVDownload } from 'react-csv';


const DevicesPage: React.FC<{}> = (props) => {
    
    const isCreating = useSelector(s => s.bookit.devices.isCreating)
    const isLoading = useSelector(s => s.bookit.devices.isLoading)

    const activeOrganisation = useSelector(getActiveOrganisation)

    const dispatch = useDispatch()
    useEffect(() => {
        if (activeOrganisation) {
            dispatch(fetchBookItDevices.request({ organisation_id: activeOrganisation.organisation_id }))
        }
    }, [activeOrganisation, dispatch])

    const devices = useSelector(getDevices)

    const { isLoaded: isRoomsLoaded, isLoading: isRoomsLoading } = useSelector(s => s.bookit.bookableResources)
    useEffect(() => {
        if (!isRoomsLoaded && !isRoomsLoading && activeOrganisation) {
            dispatch(fetchBookableResources.request({ organisation_id: activeOrganisation.organisation_id }))
        }
    }, [activeOrganisation, dispatch, isRoomsLoaded, isRoomsLoading])
    const bookableResources = useSelector(getBookableResources)

    const { isLoaded: isConnectionsLoaded, isLoading: isConnectionsLoading } = useSelector(s => s.bookit.bookableResources)

    useEffect(() => {
        if (!isConnectionsLoaded && !isConnectionsLoading && activeOrganisation) {
            dispatch(actions.fetchConnectedDevices.request(activeOrganisation.organisation_id))
        }
    }, [activeOrganisation, dispatch, isConnectionsLoaded, isConnectionsLoading])
    const connections = useSelector(getConnectedDevices)

    const [addFormOpen, setAddFormOpen] = useState(false)
    const [deviceManagementMenuOpen, setDeviceManagementMenuOpen] = useState<IdType>()
    const [bulkFormOpen, setBulkFormOpen] = useState(false)

    const [deviceToEdit, setDeviceToEdit] = useState<BookItDevice>()
    const handleDeviceEdit = (device: BookItDevice) => {
        setDeviceToEdit(device)
        setAddFormOpen(true)
    }

    const handleDeviceManagementCommand = (device: {device_id: IdType}, command: string|undefined) => {
        if (command) {
            let cmd = undefined
            if (command === "Reload") {
                cmd = "GoHome"
            }
            if (command === "Reset") {
                cmd = "ShowPreferences"
            }
            if (command === "Exit") {
                cmd = "Shutdown"
            }
            if (cmd !== undefined) {
                dispatch(actions.connectedDeviceControl.request({organisation_id: activeOrganisation.organisation_id, device_id: device.device_id, command: cmd}))
            }
        }
    }

    const handleAddUpdateDevice = useCallback((device: CreateDevice) => {
        setAddFormOpen(false)
        if (activeOrganisation === undefined) {
            console.error("No active organisation!")
            return
        }
        const { rooms, ...newDevice } = device
        const ids = rooms && rooms.map(r => r.bookable_resource_id)
        const updatedDevice = {
            ...newDevice,
            bookable_resource_ids: ids,
            primary_room_id: ids ? ids[0] : Guid.createEmpty(),
            organisation_id: activeOrganisation.organisation_id
        }
        if (deviceToEdit) {
            // take out original roomIds so we always treat them as an add operation
            const original = { name: deviceToEdit.name, default_screen: deviceToEdit.default_screen, pin: deviceToEdit.pin, organisation_id: deviceToEdit.organisation_id }
            const diff = compare(original, updatedDevice);
            console.log("original", original)
            console.log("updatedDevice", updatedDevice)
            dispatch(patchBookItDevice.request({ id: deviceToEdit, operations: diff }))

        } else {
            dispatch(createBookItDevice.request(updatedDevice))
        }
        setDeviceToEdit(undefined)
    }, [activeOrganisation, deviceToEdit, dispatch])

    const existingDevices = useMemo(() => {
        return deviceToEdit ? devices.filter(l => l.name !== deviceToEdit.name).map(l => l.name) : devices.map(l => l.name)
    }, [devices, deviceToEdit])

    const [deviceToDelete, setDeviceToDelete] = useState<BookItDevice>()
    const handleConfirmDelete = (device: BookItDevice) => {
        setDeviceToDelete(device)
    }

    const handleDeviceDelete = () => {
        dispatch(deleteBookItDevice.request(deviceToDelete!))
        setDeviceToDelete(undefined)
    }

    const deviceData = useMemo(
        () => devices.map(device => ({
            ...device,
            primary_room: bookableResources && bookableResources.find(_ => _.bookable_resource_id === device.primary_room_id),
            connections: connections && connections.find(_ => _.device_id === device.device_id)
        }))
        , [devices, bookableResources, connections])

    // =======================================================
    // CSV Data download
    // =======================================================

    const [csvData, setCsvData] = useState<string[][]>([]);
    const [csvFilename, setCsvFilename] = useState<string>();
    
    function createCsvData() {
 
       console.log('doCsvExport');
        
        const csv: any = [];
        console.log('createCsvData: devices', devices);

        csv.push(createHeaderRow());

        devices.forEach(dev => {
            const item = createCsvRow(dev);
            csv.push(item);
            console.log('createCsvData', csv);
        });

        setCsvData(csv);
    }

    function createHeaderRow() : string[] {
        return ["Organisation","Context","Action","Name","PIN","DeviceId","Default Screen","Primary Room","Other Rooms"];
    }

    function createCsvRow(device: BookItDevice) : string[] {

        const csvRow:string[] = [];
        
        const primaryRoom = bookableResources.find(_ => _.bookable_resource_id === device.primary_room_id);
        const otherRooms = []

        if (device.bookable_resources) {
            for(var key in device.bookable_resources) {
                otherRooms.push(bookableResources.find(res => res.bookable_resource_id.toString() === key)?.email_address);
            }
        }
       
        console.log('createCsvData: other rooms: ', otherRooms);

        csvRow.push(activeOrganisation.name);
        csvRow.push('BookitDevice');
        csvRow.push('Update');
        csvRow.push(device.name);
        csvRow.push(device.pin ? device.pin : '<empty>');
        csvRow.push(device.device_id.toString());
        csvRow.push(device.default_screen);
        csvRow.push(primaryRoom ? primaryRoom.email_address : '<empty>');
        csvRow.push(otherRooms ? otherRooms.join(',') : '<empty>');
        
        return csvRow;
    }

    function createCsvFileName() {
        setCsvFilename(`${activeOrganisation.name}_Devices.csv`);
    }

    const downloadCsvButton = <CSVLink 
                data={csvData} 
                filename = {csvFilename}
                target="_blank"
                ><Button primary content='Download Csv' 
                        icon={<DownloadIcon />} 
                        iconPosition='before' 
                        disabled={isCreating} 
                        styles={{ marginBottom: '0.5rem', marginRight: '0.5rem' }} />
        </CSVLink>
    
    useEffect(() => {
        if (devices){
            createCsvData();
            createCsvFileName();
        }
    }, [devices]);

    // ========================================================

    const renderActions = (cellInfo: CellInfo) => {
        const device: typeof deviceData[0] = cellInfo.original

        return (<Toolbar className='right-align'
            items={[{
                key: 'edit',
                icon: <EditIcon outline />,
                tooltip: 'Edit Device',
                onClick: () => handleDeviceEdit(device)
            }, {
                key: 'delete',
                icon: <TrashCanIcon outline/>,
                tooltip: 'Delete this Device',
                onClick: () => handleConfirmDelete(device)
            }, {
                key: 'menu',
                icon: <MoreIcon outline/>,
                tooltip: 'Device Management Menu',
                popup: {
                    content: { 
                        className: 'DevicesPageMenu',
                        content:   <ToolbarMenu items={[
                            {
                                key: 'reload',
                                content: 'Reload',
                                icon: <SyncIcon outline />
                            },
                            {
                                key: 'reset',
                                content: 'Reset',
                                icon: <RetryIcon outline />
                            },
                            {
                                key: 'exit',
                                content: 'Exit',
                                icon: <MdExitToApp />
                            },
                        ]}
                        onItemClick={(e,d) => {
                            handleDeviceManagementCommand(device, d?.content as string)
                        }}
                    />
                    },
                    position: "below",
                },
                disabled: !(device.connections?.connections?.some(conn => conn.client_type === "iOS") ?? false),
                active: deviceManagementMenuOpen === device.device_id,
                menuOpen: deviceManagementMenuOpen === device.device_id,
                onMenuOpenChange: () => setDeviceManagementMenuOpen(x => x === device.device_id ? undefined : device.device_id),
            }]}
        />)
    }

    const renderPrimaryRoom = (cellInfo: CellInfo) => {
        const device: typeof deviceData[0] = cellInfo.original
        if (device.primary_room?.email_address) {
            return <span>{`${device.primary_room.name} (${device.primary_room.email_address})`}</span>
        }
        return <span>{device.primary_room?.name ?? ""}</span>
    }

    const renderConnectionInfo = (cellInfo: CellInfo) => {
        const device: typeof deviceData[0] = cellInfo.original
        if (device.connections && device.connections.connections.length > 0) {
            const conn = device.connections.connections[0]
            const icon = conn.client_type === "Android" ? <AiFillAndroid size="2em" /> : <AiFillApple size="2em" />
            return <div className='connectionInfo'><Tooltip content={`Client Version ${conn.client_version ?? "Unknown"}`}>{icon}</Tooltip></div>
        }
        return null
    }

    

    // const downloadCsvButton = <Button primary content='Download Csv' 
    //                             onClick={() => { doCsvExport() }}  
    //                             icon={<DownloadIcon />} 
    //                             iconPosition='before' 
    //                             disabled={isCreating} 
    //                             styles={{ marginBottom: '0.5rem', marginRight: '0.5rem' }} />
    
    // const bulkLoadContent = isCreating
    //     ? <Flex hAlign='end'>{bulkLoadButton}</Flex>
    //     : (<Flex hAlign='end'>
    //         <Dialog
    //             content={<BulkLoadDevices onCancel={() => setBulkFormOpen(false)} />}
    //             header="Bulk device update"
    //             open={bulkFormOpen}
    //             onOpen={() => setBulkFormOpen(true)}
    //             trigger={bulkLoadButton}                
    //         />
    //     </Flex>)

    const addDeviceButton = <Button primary content='Add Device' icon={<AddIcon />} iconPosition='before' disabled={isCreating} styles={{ marginBottom: '0.5rem' }} />
    const addDeviceContent = isCreating
        ? <Flex hAlign='end'>{addDeviceButton}</Flex>
        : (<Flex hAlign='end'>
            <Dialog
                content={<AddDevice
                    initial={getDeviceToEdit(bookableResources, deviceToEdit)}
                    allRooms={bookableResources}
                    existingDevices={existingDevices}
                    onSubmit={handleAddUpdateDevice}
                    onCancel={() => {
                        setDeviceToEdit(undefined)
                        setAddFormOpen(false)
                    }} />}
                header="Add/Edit device"
                open={addFormOpen}
                onOpen={() => setAddFormOpen(true)}
                trigger={addDeviceButton}
            />
        </Flex>)



    return <Flex column className='DevicesPage'>
        <h5>Devices</h5>
        <div className="buttonRow">
            {downloadCsvButton}            
            {addDeviceContent}
        </div>
        <ReactTable
            className="-highlight"
            showPagination={false}
            key={deviceData.length === 0 ? "nodata" : "havedata"}
            defaultPageSize={999}
            minRows={deviceData.length === 0 ? 15 : deviceData.length}
            columns={[
                { Header: "Name", accessor: "name" },
                { Header: "Primary Room", Cell: renderPrimaryRoom },
                { Header: "Connection", Cell: renderConnectionInfo },
                { Header: "", id: "edit_delete", Cell: renderActions }
            ]}
            defaultSorted={([{ id: "name", desc: false }])}
            data={deviceData}
            loadingText={<Loader label='Loading...' />}
            loading={isLoading}
        />

        <ConfirmationDialog onConfirm={handleDeviceDelete}
            onCancel={() => setAddFormOpen(false)}
            message={`Are you sure you wish to delete the device ${(deviceToDelete ? deviceToDelete!.name : "")}?`}
            heading='Delete Device'
            confirmButtonText='Delete'
            isOpen={deviceToDelete !== undefined} />

    </Flex>
}

function getDeviceToEdit(all: BookableResource[], existingDevice?: BookItDevice): CreateDevice {
    if (existingDevice) {
        console.log("existingDevice", existingDevice)
        var device = {
            name: existingDevice.name,
            default_screen: existingDevice.default_screen,
            pin: existingDevice.pin,
            rooms: existingDevice.bookable_resources && all.filter(r => existingDevice.bookable_resources[r.bookable_resource_id.toString()] !== undefined),
            primary_room_id: existingDevice.primary_room_id
        }
        return device
    }
    return { name: '', default_screen: 'Timeline', primary_room_id: Guid.createEmpty() }
}

export default DevicesPage