import { useState, useEffect } from "react";
import { Button, FilesUploadIcon, Dialog, CloseIcon, PlayIcon, itemLayoutClassName } from "@fluentui/react-northstar";
import ReactTable, { Column } from "react-table";
import { useDispatch } from "react-redux";
import * as _ from 'lodash';
import CSVReader from 'react-csv-reader'
import { createBookableResource, fetchBookableResources, fetchBookItAttributes, 
            fetchLocations, fetchProviders } from "../../../../store/resourceActions";
import { defaultOverrides, Provider, RoomSubType, AllRoomSubTypes, BookItLocation, 
            BulkLoadBookitResourceItem, CreateBookableResource, CreateBookItRoom, VfxRoomBulkLoadItem } from "../../../../model";

import './BulkUpdate.scss';
import { getActiveOrganisation, getBookableResources, getProviders, getLocations } from "../../../../store/selectors";
import { useSelector } from '../../../../store/utils';
import { MdAlarmOn, MdWarning, MdCheckCircle } from "react-icons/md";
import { actions } from "../../../../store/types";

function BulkLoadBookitResources() {
   
    const dispatch = useDispatch();

    const activeOrganisation = useSelector(getActiveOrganisation);
    
    // --------------- Bookable resources
    const { isLoaded: isRoomsLoaded, isLoading: isRoomsLoading } = useSelector(s => s.bookit.bookableResources)
    useEffect(() => {
        if (!isRoomsLoaded && !isRoomsLoading && activeOrganisation) {
            dispatch(fetchBookableResources.request({ organisation_id: activeOrganisation.organisation_id }))
        }
    }, [activeOrganisation, dispatch, isRoomsLoaded, isRoomsLoading])
    const bookableResources = useSelector(getBookableResources);

    // --------------- Locations
    const { isLoaded: isLocationsLoaded, isLoading: isLocationsLoading } = useSelector(s => s.bookit.locations)
    useEffect(() => {
        if (!isLocationsLoaded && !isLocationsLoading && activeOrganisation) {
            dispatch(fetchLocations.request({ organisation_id: activeOrganisation.organisation_id }))
        }
    }, [activeOrganisation, dispatch, isLocationsLoaded, isLocationsLoading])

    const locations:BookItLocation[] = useSelector(getLocations);

    console.log('locations', locations);

    // --------------- Providers
    const { isLoaded: isProvidersLoaded, isLoading: isProvidersLoading } = useSelector(s => s.bookit.providers)
    useEffect(() => {
        if (!isProvidersLoaded && !isProvidersLoading && activeOrganisation) {
            dispatch(fetchProviders.request({ organisation_id: activeOrganisation.organisation_id }))
        }
    }, [activeOrganisation, dispatch, isProvidersLoaded, isProvidersLoading])

    const providers = useSelector(getProviders);

    const [isLoading, setIsLoading] = useState(false)
    const [isSelectingFile, setIsSelectingFile] = useState(false);
    const [tableData, setTableData] = useState<BulkLoadBookitResourceItem[]>([]);
    const [canProcess, setCanProcess] = useState(false);
    const [isValidationError, setIsValidationError] = useState(false);
    const [validationErrorMessage, setValidationErrorMessage] = useState<string>('');
    const [fileLoadErrorMessage, setFileLoadErrorMessage] = useState<string>();

    useEffect(() => {
        if (isProvidersLoaded && isLocationsLoaded && isRoomsLoaded && activeOrganisation && tableData.length > 0) {
            doValidation(tableData);
        }
    }, [isProvidersLoaded, isRoomsLoaded, isLocationsLoaded, activeOrganisation]);

    // ----------------------------------------------------------
    // Causes the container dialogue to close (and this with it)
    // ----------------------------------------------------------
    const onClose = () => {
        setTableData([]);
        setCanProcess(false);
    }

    function doValidation(data: BulkLoadBookitResourceItem[]) {
        if (validateData(data)) {
            setCanProcess(true);
        } else {
            setCanProcess(false);
        }
    }

    // ----------------------------------------------------------
    // Called by the csv loader if it was successful
    // ----------------------------------------------------------
    function onLoadSuccess(data: BulkLoadBookitResourceItem[]) {     

        setIsSelectingFile(false);

        // We still see empty objects even though the parser has been instructed to ignore
        _.remove(data, (item) => _.isEmpty(item.action) && _.isEmpty(item.context) && _.isEmpty(item.organisation));  

        doValidation(data);        
        setTableData(data);
    }

    // ----------------------------------------------------------
    // Called by the csv loader if there was an error
    // ----------------------------------------------------------
    function onLoadError(error: Error) {
        setFileLoadErrorMessage(error.message);
        console.log('onLoadError', error);
    }

    // ----------------------------------------------------------
    // Make sure that the file data can be run and provide
    // feedback of errors in the corresponding table row
    // ----------------------------------------------------------
    function validateData(data: BulkLoadBookitResourceItem[]) : Boolean {

        console.log('validateData start data:', data);
        console.log('validateData start br:', bookableResources);

        setIsValidationError(false);
        setValidationErrorMessage('');

        if (data.length === 0) {
            setIsValidationError(true);
            setFileLoadErrorMessage('File contains no data.');
            return false;
        }

        const validatedRows: BulkLoadBookitResourceItem[] = [];
        let isValid = true;
        
        for (let row=0; row < data.length; row++) {
                       
            const rowData = data[row];
            let error:string = '';
            rowData.process_status = 'valid';
            rowData.process_text = 'Ok to process';

            // Context must be correct - if it isn't then go no further
            if (_.toLower(rowData.context) !== 'bookitresource') {
                
                rowData.process_status = 'error';
                rowData.process_text = `The context '${rowData.context}' is not recognised. Must be 'BookitResource'`;   
                isValid = false;  

            } else if (_.toLower(rowData.organisation) !== _.toLower(activeOrganisation.name)) {
                
                setIsValidationError(true);
                error = `Organisation ${rowData.organisation} does not match active organisation (${activeOrganisation.name})`;
                rowData.process_status = 'error';
                rowData.process_text = `${error}`;   
                isValid = false;         
            
            } else { 

                // Check the action
                if (_.toLower(rowData.action) === 'delete') {
                    rowData.process_status = 'error';
                    rowData.process_text = 'The actions DELETE and UPDATE are not available in this version';   
                    isValid = false;   
                } else if (_.toLower(rowData.action) !== 'add' && _.toLower(rowData.action) !== 'update') {
                    rowData.process_status = 'error';
                    rowData.process_text = `Unrecognised action (${rowData.action}). Only ADD and UPDATE accepted in this release.`;   
                    isValid = false;  
                }            

                if (rowData.action === 'add') {
                    // Resource type must be valid
                    if (!AllRoomSubTypes.find(r => _.toLower(r) === _.toLower(rowData.resource_type))) {
                        rowData.process_status = 'error';
                        rowData.process_text = `The resource type '${rowData.resource_type}' is not recognised. Must be exact in ${AllRoomSubTypes}`;   
                        isValid = false;  
                    }


                    // Location must exist
                    if (!locations.find(loc => _.toLower(loc.name) === _.toLower(rowData.location))) {
                        rowData.process_status = 'error';
                        rowData.process_text = `The Location '${rowData.location}' was not recognised`;   
                        isValid = false;  
                    }

                    if (!rowData.resource_name || rowData.resource_name.length === 0) {
                        rowData.process_status = 'error';
                        rowData.process_text = 'An resource name must be provided';   
                        isValid = false;  
                    }

                }

                if (!rowData.resource_email || rowData.resource_email.length === 0) {
                    rowData.process_status = 'error';
                    rowData.process_text = 'An email address must be provided';   
                    isValid = false;  
                } else {
                    if (rowData.action === 'add') {
                        const br = bookableResources.find(br => _.toLower(br.email_address) === _.toLower(rowData.resource_email));
                        if (br) {
                            rowData.process_status = 'error';
                            rowData.process_text = 'A resource aleady exists for this email address';   
                            isValid = false;  
                        }
                    } else {
                        const br = bookableResources.find(br => _.toLower(br.email_address) === _.toLower(rowData.resource_email));
                        if (!br) {
                            rowData.process_status = 'error';
                            rowData.process_text = 'No resource could be found for this email address';   
                            isValid = false;  
                        }
                    }
                }

                if (_.toLower(rowData.action) === 'add' || (_.toLower(rowData.action) == 'update' && !_.isEmpty(rowData.provider))) {
                    
                    if (findAllowedProviders(providers, rowData.resource_email).length === 0) {
                        rowData.process_status = 'error';
                        rowData.process_text = `The email address does not match a provider for this organisation`;   
                        isValid = false;  
                    } else {

                        // Provider must exist and be valid for this organisation
                        const provider = providers.find(prov => _.toLower(prov.description) === _.toLower(rowData.provider));

                        if (!provider) {
                            rowData.process_status = 'error';
                            rowData.process_text = `The Provider '${rowData.provider}' was not recognised`;   
                            isValid = false;  
                        } else if (!isValidProvider(provider, rowData.resource_email)) {
                            rowData.process_status = 'error';
                            rowData.process_text = `The Provider '${rowData.provider}' is not valid for the current organisation`;   
                            isValid = false;
                        }
                    }
                }
            
            }

            validatedRows.push(rowData);

        }

        console.log('validateData', validatedRows);

        return isValid;

    }

    function isValidProvider(provider: Provider, emailAddress:string) {
        if (emailAddress === undefined || provider === undefined) return false
        const validProviders = findAllowedProviders(providers, emailAddress)
        return validProviders.some(p => p.provider_id === provider.provider_id)
    }

    function findAllowedProviders(providers: Provider[], emailAddress: string) {

        console.log('findAllowedProviders', {providers, emailAddress});

        const domain = emailAddress.split('@')?.pop()?.toLowerCase()
        if (domain === undefined) return []
        const result = providers
            .filter(provider => provider.domain_maps.length === 0 ||
                                provider.domain_maps.some(d => d.toLowerCase() === domain) ||
                                (provider.provider_type === 'Google' && domain === 'resource.calendar.google.com'))
            .sort((l, r) =>  r.domain_maps.length - l.domain_maps.length)
    
        return result
    }


    // ----------------------------------------------------------
    // An awaitable delay
    // ----------------------------------------------------------
    function delay(ms:number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    // ----------------------------------------------------------
    // Perform the crud operations. because there could potentially
    // be 100's of these, we'll place a short interval between each
    // call to avoid a DOS attack
    // ----------------------------------------------------------
    async function processData() {

        console.log('Processing data');
        
        const data = [...tableData];

        setCanProcess(false);        
        processAdds(data.filter(d => _.toLower(d.action) === 'add'));
        processUpdates(data.filter(d => _.toLower(d.action) === 'update'));
     
        // Deletes
        // Not implemented in this version
       
        setTableData(data);
    }

    function processUpdates(data: BulkLoadBookitResourceItem[]) {
        
        if (data.length > 0){

            const updateData: CreateBookItRoom[] = [];

            data.forEach(d => updateData.push(mapBulkModelItemToBookitResourceModel(d)));

            const args = {
                 organisation_id: activeOrganisation.organisation_id,
                 bulkUpdateItems: updateData,
                 bookableResources: bookableResources
             }
 
             dispatch(actions.doUpdateBookitResourcesFromCsv.request(args))

             data.forEach(d => {
                d.process_status = 'success';
                d.process_text = 'Success'; 
            });
         }
    }

    function processAdds(data: BulkLoadBookitResourceItem[]) {
        
        const addData: CreateBookableResource[] = [];

        for (let i=0; i < data.length; i++) {
                                   
            const newRoom = mapBulkModelItemToBookitResourceModel(data[i])

            // These have already been proved to exist in the validation method
            const location = locations.find(loc => _.toLower(loc.name) === _.toLower(data[i].location));
            const provider = providers.find(prov => _.toLower(prov.description) === _.toLower(data[i].provider));            

            const payload = {...newRoom,
                is_active: true,
                is_closed: false,
                //attribute_ids: attribute_ids,
                location_id: location!.location_id,
                //floor_plan_data: floorPlanData,
                organisation_id: activeOrganisation!.organisation_id,
                provider_id: provider!.provider_id,
                settings: {
                    overrides: defaultOverrides
                },
                floor: newRoom.floor || null,
            };

            addData.push(payload);
    
            data[i].process_status = 'success';
            data[i].process_text = 'Success';                        
            
        }

        console.log('Processing: adding: ', addData);

        if (addData.length > 0) {
            const args = {
                organisation_id: activeOrganisation.organisation_id,
                bulkUpdateItems: addData                
            }

            dispatch(actions.doAddBookitResourcesFromCsv.request(args));
        }

    }

    // ----------------------------------------------------------
    // Convert the local bulk load model to a device model that
    // can be passed to the crud actions.
    // ----------------------------------------------------------
    function mapBulkModelItemToBookitResourceModel(item: BulkLoadBookitResourceItem) : CreateBookItRoom {

        // Note that the validation method has already established that all referenced resources exist
        // and that the referenced organisation is the current active org.

        console.log('Map IN', item);

        const location = locations.find(loc => _.toLower(loc.name) === _.toLower(item.location));
        const provider = providers.find(prov => _.toLower(prov.description) === _.toLower(item.provider));        

        const datum = {  
            email_address: item.resource_email,
            name: item.resource_name,
            capacity: item.capacity,
            sub_type: item.resource_type,
            floor_plan_url: item.floor_plan_file_name,
            floor: item.floor,        
            location: location,
            location_id: location?.location_id,
            provider: provider,
        }

        console.log('Map OUT', datum);

        return datum;
        
    }

    // ----------------------------------------------------------
    // Called by the CSV parser to convert file string data to 
    // the correct type (most types are handled automatically)
    // ----------------------------------------------------------
    function mapValueType(value:string, header:string) {        

        switch(header) {   
            case 'is_enabled':
            case 'enable_nfc_auth':
                return toBool(value);
            case 'organisation':
            case 'context':
            case 'action':
            case 'resource_email':
            case 'location':
            case 'provider':
            case 'resource_name':
                return _.trim(value);              
            default:
                return value;
        }

        return value;
    }

    function toBool(value:string) {
        return (value && value.length > 0 && value.toLowerCase().trim() === 'true');
    }

    // ----------------------------------------------------------
    // Control that provides file selection and upload as Csv
    // ----------------------------------------------------------
    function pickCsvFile() {

        const parsingOptions = {
            header: true,
            skipEmptyLines: true,
            quoteChar: '"',
            transformHeader: (header:string, index:number) => _.snakeCase(header),
            transform: (value:string, header:string) => mapValueType(value, header)
        };

        return <CSVReader 
            onFileLoaded={(data, fileInfo, originalFile) => onLoadSuccess(data)}
            onError={(error:Error) => onLoadError(error)}
            parserOptions={parsingOptions} /> 
    }
    
    // ----------------------------------------------------------
    // Cell render function
    // ----------------------------------------------------------
    function renderStatus(cellText:string) {
        return <div className="cellTextWrap">{cellText}</div>
    }

    function renderProcessIcon(cellText:string) {
        if (cellText === 'error') {
            return <MdWarning size="2em" color='red' />
        } else if (cellText === 'valid') {
            return <MdAlarmOn size="2em" color='green' />
        } else if (cellText === 'success') {
            return <MdCheckCircle size="2em" color="green" />
        } else {
            return <div>{cellText}</div>
        }
    }

    // ----------------------------------------------------------
    // ----------------------------------------------------------
    const columns: Column[] = [
        {
        headerStyle: {
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: '',
            headerStyle: {
                textAlign: 'left',
                borderRight: '1px solid #fff',
            },
            minWidth: 40,
            accessor: 'process_status',
            Cell: (cell) => renderProcessIcon(cell.value)
        }]
    }
    , {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Status',
            className: 'leftAlign',
            minWidth: 200,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'process_text',
            Cell: (cell) => renderStatus(cell.value),
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Organisation',
            className: 'leftAlign',
            minWidth: 50,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'organisation',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Context',
            className: 'leftAlign',
            minWidth: 80,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'context',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Action',
            className: 'leftAlign',
            minWidth: 40,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'action',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Type',
            className: 'leftAlign',
            minWidth: 60,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'resource_type',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Email',
            className: 'leftAlign',
            minWidth: 100,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'resource_email',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }
    , {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Name',
            className: 'leftAlign',
            minWidth: 100,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'resource_name',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Enabled',
            className: 'leftAlign',
            minWidth: 40,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'is_enabled',
            Cell: (cell) => cell.value ? 'True' : 'False',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Capacity',
            className: 'leftAlign',
            minWidth: 40,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'capacity',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Location',
            className: 'leftAlign',
            minWidth: 60,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'location',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Floor',
            className: 'leftAlign',
            minWidth: 40,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'floor',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Provider',
            className: 'leftAlign',
            minWidth: 60,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'provider',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }, {
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        columns: [{
            Header: 'Floor plan url',
            className: 'leftAlign',
            minWidth: 60,
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
            },
            accessor: 'floor_plan_file_name',
            Cell: (cell) => cell.value ? cell.value : '',
        }]
    }

]

    return <div className="panel">
        
        <div className="buttonRow">
            <Button primary content='Load CSV file' icon={<FilesUploadIcon />} 
                iconPosition='before' disabled={isLoading} onClick={() => setIsSelectingFile(true)} 
                styles={{ marginBottom: '0.5rem' }} />     
            
            {!_.isEmpty(fileLoadErrorMessage) && <div className="errorMessage">{fileLoadErrorMessage}</div>} 
        </div>             

        {isSelectingFile && <Dialog
                open={isSelectingFile}
                header='Upload Bookit resource loader file'
                content={pickCsvFile()}   
                cancelButton="Cancel"  
                onCancel={() => setIsSelectingFile(false)}       
            />}

        {isValidationError && <div>{validationErrorMessage}</div>}

        <div className="gridContainer">
            <ReactTable
                className="-striped -highlight"
                showPagination={true}
                key={tableData.length === 0 ? "nodata" : "havedata"}
                defaultPageSize={tableData.length === 0 ? 5 : tableData.length}
                columns={columns}
                defaultSorted={([{ id: "organisation", desc: true }])}
                data={tableData}
                noDataText={<span>Load a CSV file</span>}
            />
        </div>

        <div className="buttonRow" style={{marginTop: '0.5rem'}}>  
            {canProcess && <Button primary content='Process' icon={<PlayIcon />} 
                iconPosition='before' disabled={isLoading} onClick={() => processData()} 
                styles={{ marginBottom: '0.5rem', marginLeft: '0.5rem' }} />}

            <Button primary content='Cancel' icon={<CloseIcon />} 
                iconPosition='before' disabled={isLoading} onClick={() => onClose()} 
                styles={{ marginBottom: '0.5rem', marginLeft: '0.5rem' }} />
        </div>

    </div>
}

export default BulkLoadBookitResources;