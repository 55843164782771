import { Flex, Form, FormField, FormLabel, Grid, Loader, Segment } from '@fluentui/react-northstar';
import { compare } from 'fast-json-patch';
import { Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { BookableResource, BookItAttribute, BookItLocation, defaultOverrides, FloorPlanData, IdType, 
            Provider, RoomSubType, CreateBookItRoom } from '../../model';
import { createBookableResource, fetchBookableResource, fetchBookItAttributes, fetchLocations, patchBookableResource } from '../../store/resourceActions';
import { getActiveOrganisation, getAttributes, getBookableResourceById, getLocations } from '../../store/selectors';
import { useSelector } from '../../store/utils';
import { Guid } from '../../utils/guid';
import FormButtons from '../controls/FormButtons';
import { FormikNorthstarCheckbox } from '../controls/FormikNorthstarCheckbox';
import { FormikNorthstarDropdown } from '../controls/FormikNorthstarDropdown';
import { FormikNorthstarInput } from '../controls/FormikNorthstarInput';
import './AddBookItRoom.scss';
import AttributeThumb from './AttributeThumb';

interface Props {
    bookable_resource_id?: IdType
    onSubmit: () => void
    onCancel: () => void
    existingBookableResources: BookableResource[]
    providers: Provider[]
}

const defaults = { name: '', attribute_ids: [], email_address: '', sub_type: "MeetingRoom" as "MeetingRoom", capacity: 4, location_id: Guid.createEmpty() }
const AddBookItRoom: React.FC<Props> = (props) => {
    const { bookable_resource_id, onSubmit, onCancel, providers } = props
    const roomSubTypes = ["MeetingRoom", "HotDesk", "ParkingSpace", "Locker"];

    const { isLoaded: isLocationsLoaded, isLoading: isLocationsLoading } = useSelector(s => s.bookit.locations)
    const { isLoaded: isAttributesLoaded, isLoading: isAttributesLoading } = useSelector(s => s.bookit.attributes)
    const activeOrganisation = useSelector(getActiveOrganisation)

    const dispatch = useDispatch();


    useEffect(() => {
        if (activeOrganisation && !isLocationsLoaded && !isLocationsLoading) {
            dispatch(fetchLocations.request({ organisation_id: activeOrganisation.organisation_id }))
        }
        if (!isAttributesLoaded && !isAttributesLoading) {
            dispatch(fetchBookItAttributes.request({}))
        }
    })
    const bookItAttributes = useSelector(getAttributes)
    const locations = useSelector(getLocations)
    const locationItems = useMemo(() => locations.map(l => l.location_id), [locations])

    useEffect(() => {
        if (bookable_resource_id && activeOrganisation) {
            dispatch(fetchBookableResource.request({ organisation_id: activeOrganisation.organisation_id, bookable_resource_id }))
        }
    }, [dispatch, bookable_resource_id, activeOrganisation])

    const initial = useSelector<Pick<BookableResource, keyof typeof defaults>
        & Partial<Pick<BookableResource, Exclude<keyof BookableResource, keyof typeof defaults>>>>(s => bookable_resource_id === undefined
            ? defaults : getBookableResourceById(s, bookable_resource_id)!)

    const formSchema = useMemo(() => Yup.object().shape({
        name: Yup.string()
            .required('Room name cannot be empty'),
            location_id: Yup.string().required('Must provide a location'),
        email_address: Yup.string()
            .email("Must provide a valid email address")
            .required('Email address cannot be empty')
            .test('has_provider', 'The email address does not match a provider for this organisation',
                email => email ? findAllowedProviders(providers, email).length > 0 : true),
        provider: Yup.mixed<Provider>()
            .test('has_email_domain', 'This provider cannot be used for the given email address domain',
                function (provider) {
                    if (this.parent.email_address === undefined || provider === undefined) return true
                    const validProviders = findAllowedProviders(providers, this.parent.email_address)
                    return validProviders.some(p => p.provider_id === provider.provider_id)
                }
            )
    }), [providers])

    const handleCancel = () => {
        onCancel()
    }

    const handleSubmit = async (bookItRoom: CreateBookItRoom) => {
        if (activeOrganisation === undefined) {
            console.error("No active organisation! Can't create bookable resource")
            return
        }
        const { attribute_ids, location_id, floor_plan_file, provider, ...newRoom } = bookItRoom;
        const floorPlanData = await getFloorPlanData(floor_plan_file);
        const updatedRoom = {
            ...newRoom, bookable_resource_id: newRoom.bookable_resource_id!,
            attribute_ids: attribute_ids,
            provider_id: provider?.provider_id,
            location_id: location_id,
            floor_plan_data: floorPlanData,
            organisation_id: activeOrganisation.organisation_id,
            floor: newRoom.floor || null,
        }

        if (bookable_resource_id !== undefined) {
            const { attribute_ids: _,  ...original } = initial as any
            const diff = compare(original, updatedRoom);
            console.log(original, updatedRoom)

            dispatch(patchBookableResource.request({ id: updatedRoom, operations: diff }))

        } else {
            if (provider === undefined) {
                console.error("Must have a provider defined")
                return
            }
            console.log("Creating bookit room from ", bookItRoom)
            dispatch(createBookableResource.request({
                ...newRoom,
                is_active: true,
                is_closed: false,
                attribute_ids: attribute_ids,
                location_id: location_id,
                floor_plan_data: floorPlanData,
                organisation_id: activeOrganisation?.organisation_id ?? 0,
                provider_id: provider.provider_id,
                settings: {
                    overrides: defaultOverrides
                },
                floor: newRoom.floor || null,
            }))
        }
        onSubmit()
    }
    const selectedAttributes = useMemo(() => initial && initial.attribute_ids ? initial.attribute_ids : [], [initial])
    const selectedProvider = useMemo(() => initial && initial.provider_id  ? providers.find(p => p.provider_id === initial.provider_id) : undefined, [initial, providers])

    const handleEmailChange = useCallback((email: string, formik: FormikProps<CreateBookItRoom>) => {
        var validProviders = findAllowedProviders(providers, email)
        if (validProviders.length < 1) return // yup will generate error for us
        if (formik.values.provider === undefined || !validProviders.some(p => p.provider_id === formik.values.provider!.provider_id)) {
            formik.setFieldValue('provider', validProviders[0])
        }
    }, [providers])

    if ((bookable_resource_id !== undefined && initial.attribute_ids === undefined) || (!isAttributesLoaded && !isLocationsLoaded)) return <Loader label='Loading...' />

    return <Flex column gap="gap.medium" fill className='AddBookItRoom'>
        <Formik
            initialValues={{ ...initial, attribute_ids: selectedAttributes, provider: selectedProvider, location_id: initial.location_id ?? Guid.createEmpty(), floor: initial.floor ?? '' }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}>
            {formik => <Form styles={{ width: '1000px' }} onSubmit={e => formik.handleSubmit(e as React.FormEvent<HTMLFormElement>)}>
                <Flex fill column>
                    <Grid columns={2}>
                        <Segment className="formColumn">
                            <FormikNorthstarDropdown label="Resource Type" items={roomSubTypes} name="sub_type" placeholder='Select a room type' getHeader={item => item} />
                            <FormikNorthstarInput fluid label="Resource Name" name="name" />
                            <Flex className='location'>
                                <FormikNorthstarDropdown label="Location" items={locationItems} name="location_id" placeholder='Select a location' getHeader={item => locations.find(loc => loc.location_id === item)!.name} />
                                <FormikNorthstarInput label="Floor" name="floor" />
                            </Flex>
                            <FormikNorthstarInput fluid label="Email" name="email_address" onChange={val => handleEmailChange(val, formik)} />
                            <FormikNorthstarDropdown fluid label="Provider" items={providers} name="provider" placeholder='Select a provider'
                                getHeader={item => item.description}
                            />
                            <FormikNorthstarCheckbox toggle label='Enable NFC Authentication' name='enable_nfc_authentication'/>
                        </Segment>
                        <Segment className="formColumn">
                            {formik.values.sub_type === 'MeetingRoom' &&
                                <>
                                    <FormikNorthstarInput fluid label="Capacity" name="capacity" type='number' />
                                    <FormField>
                                        <FormLabel htmlFor="attribute_ids" id="attribute_ids_label">Attributes</FormLabel>
                                        <Attributes bookItAttributes={bookItAttributes} formik={formik} />
                                    </FormField>
                                </>}

                            {initial.floor_plan_url && <div>
                                Floor plan: <img className="floorPlan" src={initial.floor_plan_url} alt="floor-plan" />
                            </div>
                            }

                            <FormField>
                                <FormLabel htmlFor="floor_plan_file" id="floor_plan_file_label">Upload Floor plan / Map / Photo</FormLabel>

                                <input
                                    id="floor_plan_file"
                                    type="file"
                                    name="floor_plan_file"
                                    onChange={e => {
                                        const file = e.target.files![0];
                                        const name = e.target.name
                                        formik.setFieldValue(name, file, false);
                                        formik.setFieldTouched(name, true, false);
                                    }}
                                />
                            </FormField>
                            {formik.values.floor_plan_file && <img src={URL.createObjectURL(formik.values.floor_plan_file)} id="imgshow" alt='preview' />}
                        </Segment>
                    </Grid>
                    {/* <FormikDebug/> */}
                    <FormButtons className="buttons" onCancel={handleCancel} />
                </Flex>
            </Form>
            }
        </Formik>
    </Flex >
}

function findAllowedProviders(providers: Provider[], emailAddress: string) {
    const domain = emailAddress.split('@')?.pop()?.toLowerCase()
    if (domain === undefined) return []
    const result = providers
        .filter(provider => provider.domain_maps.length === 0 ||
                            provider.domain_maps.some(d => d.toLowerCase() === domain) ||
                            (provider.provider_type === 'Google' && domain === 'resource.calendar.google.com'))
        .sort((l, r) =>  r.domain_maps.length - l.domain_maps.length)

    return result
}

function Attributes({ formik, bookItAttributes }: { formik: FormikProps<CreateBookItRoom>, bookItAttributes: BookItAttribute[] }) {
    const attributes: IdType[] = formik.values.attribute_ids ?? []
    return <div className="attributes" id="attribute_ids">
        {bookItAttributes.map(a =>
            <AttributeThumb
                key={a.attribute_id.toString()}
                selected={attributes.includes(a.attribute_id)}
                attribute={a}
                onClick={a => {
                    const attrs = attributes.includes(a.attribute_id)
                        ? attributes.filter((at) => at !== a.attribute_id)
                        : [...attributes, a.attribute_id]
                    formik.setFieldValue("attribute_ids", attrs, false);
                    formik.setFieldTouched("attribute_ids", true, false);
                }} />
        )
        }
    </div>
}

function getFloorPlanData(file?: File): Promise<FloorPlanData | undefined> {
    if (!file) return Promise.resolve(undefined)

    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file!)
        reader.onload = () => {
            if (reader.result === null) {
                return reject(new Error("Empty file"))
            }
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
            if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }
            return resolve({ base64_data: encoded, content_type: file.type })
        }
        reader.onerror = error => reject(error);
    });
}

export default AddBookItRoom