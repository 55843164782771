import { Operation } from 'fast-json-patch';
import { Account } from 'msal';
import { Toast } from 'reactstrap';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { AggregateColumnType, AggregateData, AllowedOrganisation, ArchivedEvent, BackgroundOperation, BackgroundOperationResult, 
                BookingCount, BookingStats, Component, ComponentType, BookitTabletStatusReport, DeviceTimeseriesReport,
                ConnectedDevice, CorsAllowedOrigin, SecurityReport, SecurityUserReport, VfxRoomStatusReport, EscalationReport, EventEscalation, 
                EventEscalationSelection, Exclusions, HealthReport, HeatMap, IdType, LogFileMetadata, LogFilesType, MinMedianMax, 
                NfcTagScannedEvent, OrganisationSummary, OrganiserBookingStats, PackageFeed, PackageFeedType, PackageVersion, 
                PeriodSummary, PortalSettings, Process, ProviderTestResult, Role, Room, RoomAttribute, RoomAttributes, RoomChangedEvent, 
                RoomConfiguration, RoomNames, RoomsFilterType, RoomWindowsUpdate, ServerInfo, SettingsCategory, Tag, TimeZoneData, 
                UserInfo, VirusScan, WindowsUpdate, WindowsUpdateInstallRequestResponse, ToastHistory, VfxRoomBulkLoadItem, 
                BulkLoadBookitResourceItem, CreateBookableResource, BookableResource, CreateBookItRoom, BulkLoadDeviceItem } from '../model';
import { HalArrayResult, HalResource, HalResult } from '../services/Hal';
import { ApiError } from '../utils/error';

export const createAuthedAccount = createAsyncAction('CREATE_AUTHED_ACCOUNT_REQUEST', 'AUTHED_ACCOUNT_SUCCESS', 'CREATE_AUTHED_ACCOUNT_FAILURE')<undefined, Account, ApiError>()
export const signOutAuthedAccount = createAction('SIGN_OUT_AUTHED_ACCOUNT')();
export const fetchServerInfo = createAsyncAction('FETCH_SERVER_INFO_REQUEST', 'FETCH_SERVER_INFO_SUCCESS', 'FETCH_SERVER_INFO_FAILURE')<undefined, ServerInfo, ApiError>();
export const fetchUserInfo = createAsyncAction('FETCH_USER_INFO_REQUEST', 'FETCH_USER_INFO_SUCCESS', 'FETCH_USER_INFO_FAILURE')<undefined, UserInfo, ApiError>();
export const clearUserServerInfo = createAction('CLEAR_USER_SERVER_INFO')();

export const errorHandler = createAction('ERROR_HANDLER', (err: Error) => err)();
export const customErrorHandler = createAction('CUSTOM_ERROR_HANDLER', (ctx: { err: Error, errorMessage: (err: ApiError) => string | null }) => ctx)();

export const loadTimezoneData = createAction('LOAD_TIMEZONE_DATA')();
export const setTimezoneData = createAction('SET_TIMEZONE_DATA', (timezoneData: TimeZoneData) => timezoneData)();

export const startServerNotificationHub = createAsyncAction('START_SERVER_NOTIFICATION_HUB', 'START_SERVER_NOTIFICATION_HUB_SUCCESS', 'START_SERVER_NOTIFICATION_HUB_FAILURE')<undefined, undefined, Error>();
export const listenToRoomChanges = createAction('LISTEN_TO_ROOM_CHANGES')();
export const listenToEventEscalations = createAction('LISTEN_TO_EVENT_ESCALATIONS')();
export const listenToNfcTags = createAction('LISTEN_TO_NFC_TAGS')();
export const onRoomChange = createAction('ON_ROOM_CHANGE', (notif: RoomChangedEvent) => notif)();
export const startPingHub = createAsyncAction('START_PING_HUB', 'START_PING_HUB_SUCCESS', 'START_PING_HUB_FAILURE')<undefined, undefined, Error>();

export const setActiveOrganisation = createAction('SET_ACTIVE_ORGANISATION', (organisation: AllowedOrganisation) => organisation)();
export const reloadOrganisation = createAsyncAction('RELOAD_ORGANISATION_REQUEST', 'RELOAD_ORGANISATION_SUCCESS', 'RELOAD_ORGANISATION_FAILURE')
                <IdType, IdType, {organisationId: IdType, error: ApiError}>();
export const setPortalSettings = createAction('SET_PORTAL_SETTINGS', (settings: PortalSettings) => settings)();

// toasts
export const onNfcTagScanned = createAction('ON_NFC_TAG_SCANNED', (event: NfcTagScannedEvent) => event)();
export const onEventEscalation = createAction('ON_EVENT_ESCALATION', (event: ArchivedEvent) => event)();
export const onRoomWindowsUpdatesChanged = createAction('ON_ROOM_WINDOWS_UPDATES_CHANGED', (roomName: String) => roomName)()
export const onEventEscalationCleared = createAction('ON_EVENT_ESCALATION_CLEARED', (event: ArchivedEvent) => event)();
export const onReportInformation = createAction('ON_REPORT_INFORMATION', (ctx: {title: string, message: string}) => ctx)();
export const onReportError = createAction('ON_REPORT_ERROR', (errorMessage: string) => errorMessage)();
export const onReportApiError = createAction('ON_REPORT_API_ERROR', (apiError: ApiError) => apiError)();
export const onReportCustomApiError = createAction('ON_REPORT_CUSTOM_API_ERROR', (ctx: { api: ApiError, errorMessage: (err: ApiError) => string | null }) => ctx)();
export const toastShown = createAction('TOAST_SHOWN', (id: number[]) => id)();

export const removeToastHistory = createAction('REMOVE_TOAST_HISTORY', (id: number[]) => id)();
export const addToastHistory = createAction('ADD_TOAST_HISTORY', (toastHistory: ToastHistory) => toastHistory)();

export const hubsStarted = createAction('HUBS_STARTED')();

export const updateRoomProperties = createAsyncAction('UPDATE_ROOM_PROPERTIES_REQUEST', 'UPDATE_ROOM_PROPERTIES_SUCCESS', 'UPDATE_ROOM_PROPERTIES_FAILURE')
        <{ room: HalResult<Room>, updatedRoom: Room }, Room, Error>();
export const updateComponentProperties = createAsyncAction('UPDATE_COMPONENT_PROPERTIES_REQUEST', 'UPDATE_COMPONENT_PROPERTIES_SUCCESS', 'UPDATE_COMPONENT_PROPERTIES_FAILURE')
        <{ room: HalResult<Room>, component: HalResource<Component>, updatedComponent: Component }, HalResult<Component>, Error>();

export const fetchRoom = createAsyncAction('FETCH_ROOM_REQUEST', 'FETCH_ROOM_SUCCESS', 'FETCH_ROOM_FAILURE')<{organisationId: IdType, roomId: string}, HalResult<Room>, Error>();
export const createRoom = createAsyncAction('CREATE_ROOM_REQUEST', 'CREATE_ROOM_SUCCESS', 'CREATE_ROOM_FAILURE')<{ room: Room, attribs?: Array<{ name: string, value: RoomAttribute }> }, HalResult<Room>, Error>();
export const deleteRoom = createAsyncAction('DELETE_ROOM_REQUEST', 'DELETE_ROOM_SUCCESS', 'DELETE_ROOM_FAILURE')<Room, string, Error>();
//export const provisionRoom = createAsyncAction('PROVISION_ROOM_REQUEST', 'PROVISION_ROOM_SUCCESS', 'PROVISION_ROOM_FAILURE')<Room, Deployment, Error>();
export const updateComponentToLatest = createAsyncAction('UPDATE_COMPONENT_TO_LATEST_REQUEST', 'UPDATE_COMPONENT_TO_LATEST_SUCCESS', 'UPDATE_COMPONENT_TO_LATEST_FAILURE')
        <{ room: HalResult<Room>, componentType: ComponentType }, undefined, Error>();
export const updateJavascriptPackageToLatest = createAsyncAction('UPDATE_JAVASCRIPT_PACAGE_TO_LATEST_REQUEST', 'UPDATE_JAVASCRIPT_PACAGE_TO_LATEST_SUCCESS', 'UPDATE_JAVASCRIPT_PACAGE_TO_LATEST_FAILURE')<HalResult<Room>, undefined, Error>();
export const fetchRoomAttributes = createAsyncAction('FETCH_ROOM_ATTRIBUTES_REQUEST', 'FETCH_ROOM_ATTRIBUTES_SUCCESS', 'FETCH_ROOM_ATTRIBUTES_FAILURE')<HalResult<Room>, { roomName: string, attribs: HalResult<RoomAttributes> }, Error>();
export const fetchLatestRoomBackup = createAsyncAction('FETCH_LATEST_ROOM_BACKUP_REQUEST', 'FETCH_LATEST_ROOM_BACKUP_SUCCESS', 'FETCH_LATEST_ROOM_BACKUP_FAILURE')<HalResult<Room>, { roomName: string, configuration: HalResult<RoomConfiguration> }, Error>();
export const refreshRoomPin = createAsyncAction('REFRESH_ROOM_PIN_REQUEST', 'REFRESH_ROOM_PIN_SUCCESS', 'REFRESH_ROOM_PIN_FAILURE')<HalResult<Room>, HalResult<Room>, Error>();
export const setRoomsFilter = createAction('SET_ROOMS_FILTER', (filterName: RoomsFilterType) => filterName)();
export const fetchRooms = createAsyncAction('FETCH_ROOMS_REQUEST', 'FETCH_ROOMS_SUCCESS', 'FETCH_ROOMS_FAILURE')<IdType, HalArrayResult<Room>, Error>();
export const fetchRoomNames = createAsyncAction('FETCH_ROOM_NAMES_REQUEST', 'FETCH_ROOM_NAMES_SUCCESS', 'FETCH_ROOM_NAMES_FAILURE')<IdType, RoomNames, Error>();

export const backupRoom = createAsyncAction('BACKUP_ROOM_REQUEST', 'BACKUP_ROOM_SUCCESS', 'BACKUP_ROOM_FAILURE')<HalResult<Room>, { roomName: string, configuration: HalResult<RoomConfiguration> }, Error>();

// Endpoint that always fails (for testing purposes)
export const fetchFail = createAsyncAction('FETCH_FAIL_REQUEST', 'FETCH_FAIL_SUCCESS', 'FETCH_FAIL_FAILURE')<undefined, {}, Error>();

export const fetchOrganisationSummaries = createAsyncAction('FETCH_ORGANISATION_SUMMARIES_REQUEST', 'FETCH_ORGANISATION_SUMMARIES_SUCCESS', 'FETCH_ORGANISATION_SUMMARIES_FAILURE')
        <undefined, Array<OrganisationSummary>, Error>();
export const testProvider = createAsyncAction('TEST_PROVIDER_REQUEST', 'TEST_PROVIDER_SUCCESS', 'TEST_PROVIDER_FAILURE')<{organisationId: IdType, providerId: IdType, emailAddress: string}, ProviderTestResult, Error>();
export const clearTestProviderResults = createAction('CLEAR_TEST_PROVIDER_RESULTS')();
export const fetchConnectedDevices = createAsyncAction('FETCH_CONNECTED_DEVICES_REQUEST', 'FETCH_CONNECTED_DEVICES_SUCCESS', 'FETCH_CONNECTED_DEVICES_FAILURE')
<IdType, Array<ConnectedDevice>, ApiError>();

export const connectedDeviceControl = createAsyncAction('CONNECTED_DEVICE_CONTROL_REQUEST', 'CONNECTED_DEVICE_CONTROL_SUCCESS', 'CONNECTED_DEVICE_CONTROL_FAILURE')
<{organisation_id: IdType, device_id: IdType, command: string}, void, ApiError>();

export const doUpdateVfxRoomsFromCsv = createAsyncAction('DO_UPDATE_VFX_ROOMS_FROM_CSV_REQUEST', 'DO_UPDATE_VFX_ROOMS_FROM_CSV_SUCCESS', 'DO_UPDATE_VFX_ROOMS_FROM_CSV_FAILURE')
<{organisation_id: IdType, bulkUpdateItems: VfxRoomBulkLoadItem[]}, void, ApiError>();

export const doUpdateBookitResourcesFromCsv = createAsyncAction('DO_UPDATE_BOOKIT_RESOURCES_FROM_CSV_REQUEST', 'DO_UPDATE_BOOKIT_RESOURCES_FROM_CSV_SUCCESS', 'DO_UPDATE_BOOKIT_RESOURCES_FROM_CSV_FAILURE')
<{organisation_id: IdType, bulkUpdateItems: CreateBookItRoom[], bookableResources: BookableResource[]}, void, ApiError>();

export const doAddBookitResourcesFromCsv = createAsyncAction('DO_ADD_BOOKIT_RESOURCES_FROM_CSV_REQUEST', 'DO_ADD_BOOKIT_RESOURCES_FROM_CSV_SUCCESS', 'DO_ADD_BOOKIT_RESOURCES_FROM_CSV_FAILURE')
<{organisation_id: IdType, bulkUpdateItems: CreateBookableResource[]}, void, ApiError>();

export const doUpdateBookitDevicesFromCsv = createAsyncAction('DO_UPDATE_BOOKIT_DEVICES_FROM_CSV_REQUEST', 'DO_UPDATE_BOOKIT_DEVICES_FROM_CSV_SUCCESS', 'DO_UPDATE_BOOKIT_DEVCES_FROM_CSV_FAILURE')
<{organisation_id: IdType, bulkUpdateItems: BulkLoadDeviceItem[], bookableResources: BookableResource[]}, void, ApiError>();

export const fetchRoles =  createAsyncAction('FETCH_ROLES_REQUEST', 'FETCH_ROLES_SUCCESS', 'FETCH_ROLES_FAILURE')<undefined, Array<Role>, ApiError>();

export const fetchEventEscalations = createAsyncAction('FETCH_EVENT_ESCALATIONS_REQUEST', 'FETCH_EVENT_ESCALATIONS_SUCCESS', 'FETCH_EVENT_ESCALATIONS_FAILURE')
        <{organisationId: IdType, selection: EventEscalationSelection}, HalArrayResult<EventEscalation>, Error>();
export const fetchEventEscalationsForRoom = createAsyncAction('FETCH_EVENT_ESCALATIONS_FOR_ROOM_REQUEST', 'FETCH_EVENT_ESCALATIONS_FOR_ROOM_SUCCESS', 'FETCH_EVENT_ESCALATIONS_FOR_ROOM_REQUEST_FAILURE')
        <{ selection: EventEscalationSelection, organisationId: IdType, roomName: string }, HalArrayResult<EventEscalation>, Error>();
export const closeEventEscalation = createAsyncAction('CLOSE_EVENT_ESCALATIONS_REQUEST', 'CLOSE_EVENT_ESCALATIONS_SUCCESS', 'CLOSE_EVENT_ESCALATIONS_REQUEST_FAILURE')
        <{ eventEscalationId: IdType, organisationId: IdType, reason: string }, HalResult<EventEscalation>, { eventEscalationId: IdType, error: Error }>();

export const fetchCorsAllowedOrigins = createAsyncAction('FETCH_CORS_ALLOWED_ORIGINS_REQUEST', 'FETCH_CORS_ALLOWED_ORIGINS_SUCCESS', 'FETCH_CORS_ALLOWED_ORIGINS_REQUEST_FAILURE')
        <undefined, HalArrayResult<CorsAllowedOrigin>, Error>();
export const createCorsAllowedOrigin = createAsyncAction('CREATE_CORS_ALLOWED_ORIGIN_REQUEST', 'CREATE_CORS_ALLOWED_ORIGIN_SUCCESS', 'CREATE_CORS_ALLOWED_ORIGIN_REQUEST_FAILURE')
        <CorsAllowedOrigin, HalResult<CorsAllowedOrigin>, Error>();
export const deleteCorsAllowedOrigin = createAsyncAction('DELETE_CORS_ALLOWED_ORIGIN_REQUEST', 'DELETE_CORS_ALLOWED_ORIGIN_SUCCESS', 'DELETE_CORS_ALLOWED_ORIGIN_FAILURE')
        <CorsAllowedOrigin, IdType, Error>();
export const patchCorsAllowedOrigin = createAsyncAction('PATCH_CORS_ALLOWED_ORIGIN_REQUEST', 'PATCH_CORS_ALLOWED_ORIGIN_SUCCESS', 'PATCH_CORS_ALLOWED_ORIGIN_FAILURE')
        <{ id: IdType, operations: Operation[] }, HalResult<CorsAllowedOrigin>, Error>();


export const fetchAggregateMetrics = createAsyncAction('FETCH_AGGREGATE_METRICS_REQUEST', 'FETCH_AGGREGATE_METRICS_SUCCESS',
        'FETCH_AGGREGATE_METRICS_FAILURE')<{ organisationId: IdType, aggregate: AggregateColumnType, start?: number, end?: number, roomName?: string }, HalResult<AggregateData>, Error>();

export const fetchAggregateMetricsCsv = createAsyncAction('FETCH_AGGREGATE_METRICS_REQUEST_CSV', 'FETCH_AGGREGATE_METRICS_SUCCESS_CSV',
        'FETCH_AGGREGATE_METRICS_FAILURE_CSV')<{ organisationId: IdType, aggregate: AggregateColumnType, start?: number, end?: number, roomName?: string }, HalResult<AggregateData>, Error>();


export const fetchPackageFeeds = createAsyncAction('FETCH_PACKAGE_FEEDS_REQUEST', 'FETCH_PACKAGE_FEEDS_SUCCESS',
        'FETCH_PACKAGE_FEEDS_FAILURE')<undefined, HalArrayResult<PackageFeed>, Error>();
export const fetchVirusScans = createAsyncAction('FETCH_VIRUS_SCANS_REQUEST', 'FETCH_VIRUS_SCANS_SUCCESS',
        'FETCH_VIRUS_SCANS_FAILURE')<undefined, Array<VirusScan>, ApiError>();

export const backgroundOperationChange = createAction('BACKGROUND_OPERATION_CHANGE', (op: BackgroundOperation) => op)();

export const createPackageFeed = createAsyncAction('CREATE_PACKAGE_FEED_REQUEST', 'CREATE_PACKAGE_FEED_SUCCESS', 'CREATE_PACKAGE_FEED_FAILURE')
        <{ feed_name: string, type: PackageFeedType, component_type: ComponentType, description: string, automatic_update_time_utc?: string }, HalResult<PackageFeed>, Error>();

export const deletePackageFeed = createAsyncAction('DELETE_PACKAGAE_FEED_REQUEST', 'DELETE_PACKAGAE_FEED_SUCCESS', 'DELETE_PACKAGAE_FEED_FAILURE')
        <PackageFeed, string, Error>();

export const patchPackageFeed = createAsyncAction('PATCH_PACKAGE_FEED_REQUEST', 'PATCH_PACKAGE_FEED_SUCCESS', 'PATCH_PACKAGE_FEED_FAILURE')
        <{ feed_name: string, operations: Operation[] }, HalResult<PackageFeed>, Error>();

export const updateComponentsInPackageFeed =
        createAsyncAction('UPDATE_COMPONENTS_IN_PACKAGAE_FEED_REQUEST', 'UPDATE_COMPONENTS_IN_PACKAGAE_FEED_SUCCESS', 'UPDATE_COMPONENTS_IN_PACKAGAE_FEED_FAILURE')
                <PackageFeed, IdType, Error>();
export const updateComponentsInPackageFeedNotification = createAction('UPDATE_COMPONENTS_IN_PACKAGAE_FEED_NOTIFICATION', (op: BackgroundOperation) => op)();
export const dismissComponentUpdate = createAction('DISMISS_COMPONENT_UPDATE')();
export const downloadPackageVersion = createAsyncAction('DOWNLOAD_PACKAGE_VERSION_REQUEST', 'DOWNLOAD_PACKAGE_VERSION_SUCCESS',
        'DOWNLOAD_PACKAGE_VERSION_FAILURE')<HalResource<PackageVersion>, { version: PackageVersion, fileName?: string, data: Blob }, { version: PackageVersion, error: Error }>();
export const downloadPackageVersionComplete = createAction('DOWNLOAD_PACKAGE_VERSION_COMPLETE', (version: PackageVersion) => version)();
export const uploadNewPackageVersion = createAsyncAction('UPLOAD_NEW_PACKAGE_VERSION_REQUEST', 'UPLOAD_NEW_PACKAGE_VERSION_SUCCESS', 'UPLOAD_NEW_PACKAGE_VERSION_FAILURE')<{ packageName: string, file: File }, HalResource<PackageVersion>|BackgroundOperationResult, ApiError>();
export const clearUploadNewPackageVersion = createAction('CLEAR_UPLOAD_NEW_PACKAGE_VERSION')();

export const uploadLicenseFile = createAsyncAction('UPLOAD_LICENSE_FILE_REQUEST', 'UPLOAD_LICENSE_FILE_SUCCESS', 'UPLOAD_LICENSE_FILE_FAILURE')
        <{ room: HalResult<Room>, file: File }, undefined, Error>();

export const fetchHealth = createAsyncAction('FETCH_HEALTH_REQUEST', 'FETCH_HEALTH_SUCCESS', 'FETCH_HEALTH_FAILURE')<undefined, HealthReport, Error>();

export const fetchSettingsCategory = createAsyncAction('FETCH_SETTINGS_CATEGORY_REQUEST', 'FETCH_SETTINGS_CATEGORY_SUCCESS', 'FETCH_SETTINGS_CATEGORY_FAILURE')<{ application: string, category: string }, HalResult<SettingsCategory>, Error>();

export const fetchWindowsUpdates = createAsyncAction('FETCH_WINDOWS_UPDATES_REQUEST', 'FETCH_WINDOWS_UPDATES_SUCCESS', 'FETCH_WINDOWS_UPDATES_FAILURE')
                <undefined, HalArrayResult<WindowsUpdate>, ApiError>()

export const adminFetchWindowsUpdatesForRoom = createAsyncAction('ADMIN_FETCH_WINDOWS_UPDATES_FOR_ROOM_REQUEST', 'ADMIN_FETCH_WINDOWS_UPDATES_FOR_ROOM_SUCCESS', 'ADMIN_FETCH_WINDOWS_UPDATES_FOR_ROOM_FAILURE')
                <HalResource<Room>, { roomName: string, updates: HalArrayResult<RoomWindowsUpdate> }, Error>()
export const adminCheckForWindowsUpdates = createAsyncAction('ADMIN_CHECK_FOR_WINDOWS_UPDATES_REQUEST', 'ADMIN_CHECK_FOR_WINDOWS_UPDATES_SUCCESS', 'ADMIN_CHECK_FOR_WINDOWS_UPDATES_FAILURE')
        <HalResource<Room>, undefined, Error>()
export const adminRequestWindowsUpdatesInstall = createAsyncAction('ADMIN_REQUEST_WINDOWS_UPDATES_INSTALL_REQUEST', 'ADMIN_REQUEST_WINDOWS_UPDATES_INSTALL_SUCCESS', 'ADMIN_REQUEST_WINDOWS_UPDATES_INSTALL_FAILURE')
        <{room: HalResource<Room>, ids: number[]},{ roomName: string, result: WindowsUpdateInstallRequestResponse } , Error>()

export const adminFetchLog = createAsyncAction('ADMIN_FETCH_LOG_REQUEST', 'ADMIN_FETCH_LOG_SUCCESS', 'ADMIN_FETCH_LOG_FAILURE')<{ room: HalResource<Room>, log: LogFilesType }, { logName: LogFilesType, fileName?: string, data: Blob }, { logName: LogFilesType, error: Error }>();
export const adminFetchLogMetadata = createAsyncAction('ADMIN_FETCH_LOG_METADATA_REQUEST', 'ADMIN_FETCH_LOG_METADATA_SUCCESS', 'ADMIN_FETCH_LOG_METADATA_FAILURE')<{ room: HalResource<Room>, log: LogFilesType }, { roomName: string, log: LogFilesType, result: HalResult<LogFileMetadata> }, { roomName: string, log: LogFilesType, error: ApiError }>();
export const adminFetchLogMetadataErrorHandler = createAction('ADMIN_FETCH_LOG_METADATA_ERROR_HANDLER'
        , (err: { err: Error, log: LogFilesType, roomName: string}) => err)();

export const adminDownloadLogComplete = createAction('ADMIN_DOWNLOAD_LOG_COMPLETE', (log: LogFilesType) => log)();
export const adminCollectLogs = createAsyncAction('ADMIN_COLLECT_LOGS_REQUEST', 'ADMIN_COLLECT_LOGS_SUCCESS',
        'ADMIN_COLLECT_LOGS_FAILURE')<HalResource<Room>, { roomName: string, fileName?: string, data: Blob }, { roomName: string, error: Error }>();
export const adminCollectedLogsDownloaded = createAction('ADMIN_COLLECTED_LOGS_DOWNLOADED', (roomName: string) => roomName)();
export const adminEmailLogs = createAsyncAction('ADMIN_EMAIL_LOGS_REQUEST', 'ADMIN_EMAIL_LOGS_SUCCESS',
        'ADMIN_EMAIL_LOGS_FAILURE')<{ room: HalResource<Room>, to: string[] }, string, { roomName: string, error: Error }>();

export const adminStopRoom = createAsyncAction('ADMIN_STOP_ROOM_REQUEST', 'ADMIN_STOP_ROOM_SUCCESS', 'ADMIN_STOP_ROOM_FAILURE')<HalResource<Room>, HalResult<Process>, Error>();
export const adminStartRoom = createAsyncAction('ADMIN_START_ROOM_REQUEST', 'ADMIN_START_ROOM_SUCCESS', 'ADMIN_START_ROOM_FAILURE')<HalResource<Room>, HalResult<Process>, Error>();
export const adminRestartRoom = createAsyncAction('ADMIN_RESTART_ROOM_REQUEST', 'ADMIN_RESTART_ROOM_SUCCESS', 'ADMIN_RESTART_ROOM_FAILURE')<HalResource<Room>, HalResult<Process>, Error>();

export const adminReboot = createAsyncAction('ADMIN_REBOOT_REQUEST', 'ADMIN_REBOOT_SUCCESS', 'ADMIN_REBOOT_FAILURE')<{ room: HalResource<Room>, force?: boolean }, void, Error>();

export const adminFetchScreenShot = createAsyncAction('ADMIN_FETCH_SCREEN_SHOT_REQUEST', 'ADMIN_FETCH_SCREEN_SHOT_SUCCESS', 'ADMIN_FETCH_SCREEN_SHOT_FAILURE')
        <{ room: HalResource<Room>, index: number }, { roomName: string, index: number, data: Blob }, { roomName: string, index: number, error: Error }>();

export const fetchHeatMap = createAsyncAction('FETCH_HEATMAP_REQUEST', 'FETCH_HEATMAP_SUCCESS', 'FETCH_HEATMAP_FAILURE')
        <{ organisationId: IdType, startRange: Date, endRange: Date, location: string, resourceType: string, bookableResource: string, excludeWeekends: boolean, workingHoursOnly: boolean, isActualTime: boolean }, HalResult<HeatMap>, Error>();

export const fetchSummary = createAsyncAction("FETCH_SUMMARY_REQUEST", "FETCH_SUMMARY_SUCCESS", "FETCH_SUMMARY_FAILURE")
        <{ organisationId: IdType, startRange: Date, endRange: Date, location: string, resourceType: string, bookableResource: string, excludeWeekends: boolean, workingHoursOnly: boolean, isActualTime: boolean }, HalArrayResult<PeriodSummary>, Error>();

export const fetchBookingCount = createAsyncAction('FETCH_BOOOKING_COUNT_REQUEST', 'FETCH_BOOKING_COUNT_SUCCESS', 'FETCH_BOOKING_COUNT_FAILURE')
        <{ organisationId: IdType, startRange: Date, endRange: Date, location: string }, HalArrayResult<BookingCount>, Error>();

export const fetchMinMedianMax = createAsyncAction('FETCH_MIN_MEDIAN_MAX_REQUEST', 'FETCH_MIN_MEDIAN_MAX_SUCCESS', 'FETCH_MIN_MEDIAN_MAX_FAILURE')
        <{ organisationId: IdType, startRange: Date, endRange: Date, resourceType: string, location: string }, HalArrayResult<MinMedianMax>, Error>();

export const fetchBookingStats = createAsyncAction('FETCH_BOOOKING_STATS_REQUEST', 'FETCH_BOOKING_STATS_SUCCESS', 'FETCH_BOOKING_STATS_FAILURE')
        <{ organisationId: IdType, startRange: Date, endRange: Date, location: string, resourceType: string, bookableResource: string, cancellationBefore: number, excludeWeekends: boolean, workingHoursOnly: boolean }, HalArrayResult<BookingStats>, Error>();

export const fetchOrganiserBookingStats = createAsyncAction('FETCH_ORGANISER_BOOOKING_STATS_REQUEST', 'FETCH_ORGANISER_BOOKING_STATS_SUCCESS', 'FETCH_ORGANISER_BOOKING_STATS_FAILURE')
        <{ organisationId: IdType, startRange: Date, endRange: Date, location: string, resourceType: string, bookableResource: string, organiser: string, excludeWeekends: boolean, workingHoursOnly: boolean }, { lastFetch: Date, startRange: Date, endRange: Date, location: string, resourceType: string, bookableResource: string, organiser: string, excludeWeekends: boolean, workingHoursOnly: boolean, organisationId: IdType, data: HalArrayResult<OrganiserBookingStats> }, { organiser: string, error: Error }>();

export const fetchReportingRoomGroups = createAsyncAction('FETCH_REPORTING_ROOM_GROUPS_REQUEST', 'FETCH_REPORTING_ROOM_GROUPS_SUCCESS', 'FETCH_REPORTING_ROOM_GROUPS_FAILURE')
        <IdType, HalArrayResult<Tag>, Error>();

export const fetchEscalationsReport = createAsyncAction('FETCH_ESCALATIONS_REPORT_REQUEST', 'FETCH_ESCALATIONS_REPORT_SUCCESS', 'FETCH_ESCALATIONS_REPORT_FAILURE')
        <{ organisationId: IdType, startRange: Date, endRange: Date, tag: string }, HalArrayResult<EscalationReport>, Error>();


export const patchUcSiteExclusions = createAsyncAction('PATCH_UC_SITE_EXCLUSIONS_REQUEST', 'PATCH_UC_SITE_EXCLUSIONS_SUCCESS', 'PATCH_UC_SITE_EXCLUSIONS_FAILURE')
        <{organisation_id: IdType, site_id: string, diff: Operation[]}, {organisation_id: IdType, site_id: string, exclusions: Exclusions}, ApiError>();

export const fetchSecurityReport = createAsyncAction('FETCH_SECURITY_REPORT_REQUEST', 'FETCH_SECURITY_REPORT_SUCCESS', 'FETCH_SECURITY_REPORT_FAILURE')
        <{ startRange: Date, endRange: Date }, HalArrayResult<SecurityReport>, Error>();

export const fetchSecurityReportCsv = createAsyncAction('FETCH_SECURITY_REPORT_CSV_REQUEST', 'FETCH_SECURITY_REPORT_CSV_SUCCESS', 'FETCH_SECURITY_REPORT_CSV_FAILURE')
        <{ startRange: Date, endRange: Date }, HalArrayResult<SecurityReport>, Error>();

export const fetchSecurityUserReport = createAsyncAction('FETCH_SECURITY_USER_REPORT_REQUEST', 'FETCH_SECURITY_USER_REPORT_SUCCESS', 'FETCH_SECURITY_USER_REPORT_FAILURE')
        <{ startRange: Date, endRange: Date, tag: string }, HalArrayResult<SecurityUserReport>, Error>();

export const fetchVfxRoomStatusReport = createAsyncAction('FETCH_VFX_ROOM_STATUS_REPORT_REQUEST', 'FETCH_VFX_ROOM_STATUS_REPORT_SUCCESS', 'FETCH_VFX_ROOM_STATUS_REPORT_FAILURE')
        <{ organisation_id: IdType }, HalArrayResult<VfxRoomStatusReport>, Error>();

export const fetchBookitTabletStatusReport = createAsyncAction('FETCH_BOOKIT_TABLET_STATUS_REPORT_REQUEST', 'FETCH_BOOKIT_TABLET_STATUS_REPORT_SUCCESS', 'FETCH_BOOKIT_TABLET_STATUS_REPORT_FAILURE')
        <{ organisation_id: IdType }, HalArrayResult<BookitTabletStatusReport>, Error>();

export const fetchDeviceTimeseriesReport = createAsyncAction('FETCH_DEVICE_TIMESERIES_REPORT_REQUEST', 'FETCH_DEVICE_TIMESERIES_REPORT_SUCCESS', 'FETCH_DEVICE_TIMESERIES_REPORT_FAILURE')
        <{ organisation_id: IdType, startRange: Date, endRange: Date }, HalArrayResult<DeviceTimeseriesReport>, Error>();
