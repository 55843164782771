import { Menu } from "@fluentui/react-northstar";
import * as React from "react";
import { useCallback } from "react";
import { StoppedRunningSelection } from "../../../model";

interface Props {
    onSelectionChange: (selection: StoppedRunningSelection) => void
}
const StoppedRunningSelector: React.FC<Props> = ({onSelectionChange}) => {

    const handleMenuClicked = useCallback((event: React.SyntheticEvent<HTMLElement>, data?: any) => {
        const newSelection = data!.name as StoppedRunningSelection
        onSelectionChange(newSelection)
    }, [onSelectionChange])

    const items = [
        {
            key: 'stopped',
            name: 'stopped',
            content: 'Stopped',
            onClick: handleMenuClicked
        },
        {
            key: 'running',
            name: 'running',
            content: 'Running',
            onClick: handleMenuClicked
        },
        {
            key: 'all',
            name: 'all',
            content: 'All',
            onClick: handleMenuClicked
        },
    ]

    return <Menu defaultActiveIndex={2} items={items} primary />
}

export default StoppedRunningSelector;