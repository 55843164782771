import { combineEpics } from 'redux-observable';
import { resourceEpics } from '../resourceActions';
import { adminCheckForWindowsUpdatesEpic, adminCollectLogsEpic, adminEmailLogsEpic, adminFetchLogEpic, adminFetchLogMetadataEpic, adminFetchLogMetadataErrorHandlerEpic, adminFetchScreenShotEpic, adminFetchWindowsUpdatesForRoomEpic, adminRebootEpic, adminRequestWindowsUpdatesInstallEpic, adminRestartRoomEpic, adminStartRoomEpic, adminStopRoomEpic } from './admin';
import { customErrorHandlerEpic, errorHandlerEpic, fetchFailEpic, loadTimezoneDataEpic, updateUserInfoOnDeleteEpic, updateUserInfoOnUpdateEpic } from './app';
import { fetchBookingCountEpic, fetchBookingStatsEpic, fetchHeatMapEpic, fetchMinMedianMaxEpic, fetchOrganisationSummariesEpic, fetchOrganiserBookingStatsEpic, fetchSummaryEpic, testProviderEpic } from './bookit';
import { createCorsAllowedOriginEpic, deleteCorsAllowedOriginEpic, fetchCorsAllowedOriginsEpic, patchCorsAllowedOriginEpic } from './cors';
import { closeEventEscalationsEpic, fetchEventEscalationsEpic, fetchEventEscalationsForRoomEpic } from './eventEscalations';
import { fetchHealthEpic } from './health';
import { uploadLicenseFileEpic } from './licenseFiles';
import { fetchAggregateMetricsEpic } from './metrics';
import { listenToBackgroundOperationChangeEpic, listenToEventEscalationsEpic, listenToNfcTagsEpic, listenToRoomChangesEpic, listenToRoomWindowsUpdatesChangedEpic, startServerNotificationHubEpic } from './notificationHub';
import { fetchEscalationsReportEpic, fetchReportingRoomGroupsEpic, fetchSecurityReportEpic, fetchSecurityUserReportEpic,  fetchVfxRoomStatusReportEpic, fetchBookitTabletStatusReportEpic, fetchDeviceTimeseriesReportEpic } from './reporting';
import { backupRoomEpic, createRoomEpic, deleteRoomEpic, fetchLatestRoomBackupEpic, fetchRoomAttributesEpic, fetchRoomEpic, fetchRoomNamesEpic, fetchRoomsEpic, refreshRoomPinEpic, updateComponentPropertiesEpic, updateJavascriptPackageToLatestEpic, updateRoomPropertiesEpic, updateRoomToLatestEpic } from './rooms';
import { fetchSettingsCategoryEpic } from './settings';
import { createPackagesFeedEpic, deletePackageFeedEpic, downloadPackageVersionEpic, fetchSoftwarePackagesEpic, updateComponentsInPackageFeedEpic } from './softwarePackages';
import { fetchRolesEpic } from './userAccounts';
import { adminFetchWindowsUpdatesEpic } from './windowsUpdates';

export default combineEpics(

    resourceEpics

    , updateUserInfoOnDeleteEpic
    , updateUserInfoOnUpdateEpic
    , fetchFailEpic
    , errorHandlerEpic
    , customErrorHandlerEpic
    , startServerNotificationHubEpic
    , listenToNfcTagsEpic
    , listenToBackgroundOperationChangeEpic
    , listenToRoomChangesEpic
    , listenToEventEscalationsEpic
    , listenToRoomWindowsUpdatesChangedEpic
    , updateRoomPropertiesEpic
    , updateComponentPropertiesEpic
    , fetchRoomEpic
    , createRoomEpic
    , deleteRoomEpic
    , fetchAggregateMetricsEpic
    , refreshRoomPinEpic
    , updateRoomToLatestEpic
    , updateJavascriptPackageToLatestEpic
    , fetchRoomAttributesEpic
    , fetchLatestRoomBackupEpic
    , backupRoomEpic
    , fetchRoomsEpic
    , fetchRoomNamesEpic
    , fetchEventEscalationsEpic
    , fetchEventEscalationsForRoomEpic
    , closeEventEscalationsEpic
    , fetchCorsAllowedOriginsEpic
    , createCorsAllowedOriginEpic
    , deleteCorsAllowedOriginEpic
    , patchCorsAllowedOriginEpic
    , fetchSettingsCategoryEpic
    , fetchHealthEpic
    , fetchSoftwarePackagesEpic
    , createPackagesFeedEpic
    , deletePackageFeedEpic
    , updateComponentsInPackageFeedEpic
    , downloadPackageVersionEpic
    , uploadLicenseFileEpic
    , adminStopRoomEpic
    , adminStartRoomEpic
    , adminRestartRoomEpic
    , adminRebootEpic
    , adminFetchLogEpic
    , adminFetchLogMetadataEpic
    , adminFetchLogMetadataErrorHandlerEpic
    , adminCollectLogsEpic
    , adminEmailLogsEpic
    , adminFetchScreenShotEpic

    , fetchRolesEpic
    , testProviderEpic
    , fetchOrganisationSummariesEpic

    , adminFetchWindowsUpdatesEpic

    , adminFetchWindowsUpdatesForRoomEpic
    , adminRequestWindowsUpdatesInstallEpic
    , adminCheckForWindowsUpdatesEpic

    , fetchHeatMapEpic
    , fetchSummaryEpic
    , fetchBookingCountEpic
    , fetchMinMedianMaxEpic
    , fetchBookingStatsEpic
    , fetchOrganiserBookingStatsEpic

    , fetchReportingRoomGroupsEpic
    , fetchEscalationsReportEpic
    , fetchSecurityReportEpic
    , fetchSecurityUserReportEpic
    , fetchVfxRoomStatusReportEpic
    , fetchBookitTabletStatusReportEpic
    , fetchDeviceTimeseriesReportEpic
    , loadTimezoneDataEpic

);