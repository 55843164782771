import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { Services } from "../../services";
import { createPackageFeed, deletePackageFeed, downloadPackageVersion, errorHandler, fetchPackageFeeds, 
    updateComponentsInPackageFeed, patchPackageFeed } from "../actions";
import { RootAction, RootState } from "../types";

export const fetchSoftwarePackagesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchPackageFeeds.request)),
        mergeMap(action =>
            serverApi.fetchAllPackageFeeds()
            .pipe(
                mergeMap(hal => of(fetchPackageFeeds.success(hal))),
                catchError(err => of(fetchPackageFeeds.failure(err), errorHandler(err)))
            )));
}

export const createPackagesFeedEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(createPackageFeed.request)),
        mergeMap(action => {
            console.log('createPackagesFeedSuccessEpic:', action.payload)
            return serverApi.createPackageFeed(action.payload.feed_name, action.payload.type, action.payload.component_type, action.payload.description, action.payload.automatic_update_time_utc).pipe(
                mergeMap(hal => of(createPackageFeed.success(hal))),
                catchError(err => of(createPackageFeed.failure(err), errorHandler(err)))
            )}));
}

export const deletePackageFeedEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(deletePackageFeed.request)),
        switchMap(action => 
            from(serverApi.deletePackageFeed(action.payload.feed_name)).pipe(
                map(() => deletePackageFeed.success(action.payload.feed_name)),
                catchError(err => of(deletePackageFeed.failure(err), errorHandler(err)))
            )));
}

export const updateComponentsInPackageFeedEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(updateComponentsInPackageFeed.request)),
        switchMap(action => 
            from(serverApi.updateComponentsInPackageFeed(action.payload.feed_name)).pipe(
                map(resp => updateComponentsInPackageFeed.success(resp.background_operation_id)),
                catchError(err => of(updateComponentsInPackageFeed.failure(err), errorHandler(err)))
            )));
}

export const downloadPackageVersionEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
       return action$.pipe(
        filter(isActionOf(downloadPackageVersion.request)),
        mergeMap(action =>
            serverApi.downloadPackageVersion(action.payload).pipe(
                mergeMap(res => of(downloadPackageVersion.success({...res, version: action.payload}))),
                catchError(err => of(downloadPackageVersion.failure({error: err, version: action.payload}), errorHandler(err)))
            )));
}
