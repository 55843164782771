
import { all } from "async-saga";
import { ConnectedDevice, VfxRoomBulkLoadItem, Room, Component, BulkLoadBookitResourceItem } from "../../model";
import services from "../../services";
import { ServerApi } from "../../services/ServerApi";

import { getApiError } from "../../utils/error";
import { actions } from "../types";
import { takeEvery } from "./async-saga";
import { HalResult, HalResource } from "../../services/Hal";
import * as _ from 'lodash';
import { catchError, flatMap, map, mergeMap, tap } from "rxjs/operators";
import { from, of } from "rxjs";
import { getBookableResources } from "../../store/selectors";
import { useSelector } from '../../store/utils';
import { compare } from "fast-json-patch";
import {patchBookableResource, createBookableResource} from "../resourceActions";
import { Guid } from "../../utils/guid";



const reloadOrganisationSaga = takeEvery(actions.reloadOrganisation.request, async function (ctx, organisationId) {
    

    const api = services.apiClient
    if (api === null) return
    
    try {
        await api.postEmpty(`/organisations/${organisationId}/reload`)
        ctx.dispatch(actions.reloadOrganisation.success(organisationId))
        
    } catch (e) {
        console.error("reloadOrganisationSaga: ", e)
        ctx.dispatch(actions.reloadOrganisation.failure({organisationId, error: await getApiError(e)}))
    }
})

const fetchConnectedDevicesSaga = takeEvery(actions.fetchConnectedDevices.request, async function (ctx, organisationId) {
    const api = services.apiClient
    if (api === null) return
    
    try {
        const devices = await api.get<ConnectedDevice[]>(`/organisations/${organisationId}/devicemgmt`)
        ctx.dispatch(actions.fetchConnectedDevices.success(devices))
        
    } catch (e) {
        console.error("reloadOrganisationSaga: ", e)
        ctx.dispatch(actions.fetchConnectedDevices.failure(await getApiError(e)))
    }
})

const connectedDeviceCommandSaga = takeEvery(actions.connectedDeviceControl.request, async function (ctx, {organisation_id, device_id, command}) {
    const api = services.apiClient
    if (api === null) return
    
    try {
        await api.postEmpty(`/organisations/${organisation_id}/devicemgmt/${device_id}?command=${command}`)
        ctx.dispatch(actions.connectedDeviceControl.success())
        
    } catch (e) {
        console.error("reloadOrganisationSaga: ", e)
        ctx.dispatch(actions.connectedDeviceControl.failure(await getApiError(e)))
    }
})

const addBookitResourcesFromCsvSaga = takeEvery(actions.doAddBookitResourcesFromCsv.request, async function (ctx, {organisation_id, bulkUpdateItems }) {
    
    console.log('addBookitResourcesFromCsvSaga: Start', {organisation_id, bulkUpdateItems});
    
    for (let i=0;i<bulkUpdateItems.length;i++) {
        console.log('addBookitResourcesFromCsvSaga: room loop', bulkUpdateItems[i].email_address);
        ctx.dispatch(createBookableResource.request(bulkUpdateItems[i]));        
    }

})


const updateBookitResourcesFromCsvSaga = takeEvery(actions.doUpdateBookitResourcesFromCsv.request, 
                                                    async function (ctx, {organisation_id, bulkUpdateItems, bookableResources }) {
    
    //console.log('updateBookitResourcesFromCsvSaga: Start', {organisation_id, bulkUpdateItems, bookableResources});

    for (let i=0; i < bulkUpdateItems.length;i++) {

        const item = bulkUpdateItems[i];
        //console.log('updateBookitResourcesFromCsvSaga: room loop', item);
                
        const originalRoom = bookableResources.find(br => _.toLower(br.email_address) === _.toLower(item.email_address))!;

        const { attribute_ids, location_id, floor_plan_file, provider, ...newRoom } = item;

        const updatedRoom = {
            ...originalRoom,
            name: applyStringValueForPatch(item.name, originalRoom.name),
            capacity: applyNumberValueForPatch(item.capacity, originalRoom.capacity),
            sub_type: applyStringValueForPatch(item.sub_type, originalRoom.sub_type),
            location_id: applyGuidValueForPatch(item.location_id, originalRoom.location_id),
            floor: applyStringValueForPatch(item.floor, originalRoom.floor)
        };

        const diff = compare(originalRoom, updatedRoom);
        //console.log('updateBookitResourcesFromCsvSaga: diff', diff);

        if (diff.length > 0) {
            ctx.dispatch(patchBookableResource.request({ id: updatedRoom, operations: diff }))
        }
        
    }

    // return original value (no patch operation) if either no change or
    // new value is not supplied. return new value if it is changed.
    // return empty new value if = <empty>
    function applyStringValueForPatch(newValue:string | null, originalValue:string | null) : string | null {
        
        //console.log('applyStringValueForPatch', {newValue, originalValue});

        if (newValue === originalValue) {        
            return originalValue;
        }

        if (_.isEmpty(newValue)) {
            return originalValue;
        }

        if (newValue === '<empty>') {
            return null;
        } 

        return newValue;

    }

    function applyGuidValueForPatch(newValue:Guid | undefined, originalValue:Guid | undefined) : Guid | undefined {
        
        //console.log('applyGuidValueForPatch', {newValue, originalValue});

        if (_.toString(newValue) === _.toString(originalValue)) {        
            return originalValue;
        }

        if (_.isEmpty(newValue)) {
            return originalValue;
        }

        return newValue;

    }


    function applyNumberValueForPatch(newValue:number, originalValue:number) : number {
        
        //console.log('applyNumberValueForPatch', {newValue, originalValue});

        if (_.toString(newValue) === _.toString(originalValue)) {        
            return originalValue;
        }

        if (_.isEmpty(newValue)) {
            return originalValue;
        }

        return newValue;

    }



})


const updateVfxRoomsFromCsvSaga = takeEvery(actions.doUpdateVfxRoomsFromCsv.request, async function (ctx, {organisation_id, bulkUpdateItems }) {
    
    const api = services.serverApi;

    console.log('updateVfxRoomsFromCsvSaga: Start 2', {organisation_id, bulkUpdateItems});
    
    for (let i=0; i < bulkUpdateItems.length; i++) {

        console.log('updateVfxRoomsFromCsvSaga: room loop', bulkUpdateItems[i].room_name);
        const observable = api.fetchRoom(organisation_id, bulkUpdateItems[i].room_name);

        observable.subscribe(room => {

            console.log('updateVfxRoomsFromCsvSaga: room loop', room);
            
            const item = bulkUpdateItems[i];
            
            if (item.template_room === '<empty>' && !_.isEmpty(room.resource.template_room_name)){
                console.log('updateVfxRoomsFromCsvSaga: template room is empty', item.template_room);
                updateRoomProperty(ctx, room, 'template_room_Name', undefined);
            } else if (!_.isEmpty(item.template_room) && item.template_room !== room.resource.template_room_name) {
                console.log('updateVfxRoomsFromCsvSaga: template room not empty', item.template_room);
                updateRoomProperty(ctx, room, 'template_room_Name', item.template_room);
            }
            
            let packagefeed = room.resource.room_component ? room.resource.room_component.package_feed_name : room.resource._embedded.room_component.package_feed_name;
            
            if (item.room_package_feed === '<empty>' && !_.isEmpty(packagefeed)) {
                // Patch operation fails if replacing with empty value
                //console.log('updateVfxRoomsFromCsvSaga: room_msi_package_feed_name is empty', item.room_package_feed);
                updateRoomComponent(ctx, room, 'room_msi_package_feed_name', undefined);
            } else if (!_.isEmpty(item.room_package_feed) && item.room_package_feed !== packagefeed) {
                console.log('updateVfxRoomsFromCsvSaga: room_package_feed not empty', item.room_package_feed);
                updateRoomComponent(ctx, room, 'room_msi_package_feed_name', item.room_package_feed);
            }
        
            packagefeed = room.resource.admin_component ? room.resource.admin_component.package_feed_name : room.resource._embedded.admin_component.package_feed_name;

            if (item.admin_package_feed === '<empty>' && !_.isEmpty(packagefeed)) {
                // Patch operation fails if replacing with empty value
                //console.log('updateVfxRoomsFromCsvSaga: admin package feed is empty', item.admin_package_feed);
                //updateRoomComponent(ctx, room, 'admin_msi_package_feed_name', undefined);
            } else if (item.admin_package_feed !== '<empty>' && !_.isEmpty(item.admin_package_feed) && item.admin_package_feed !== packagefeed) {
                console.log('updateVfxRoomsFromCsvSaga: admin_package_feed not empty', item.admin_package_feed);
                updateRoomComponent(ctx, room, 'admin_msi_package_feed_name', item.admin_package_feed);
            }
        
            if (item.display_name === '<empty>') {
                // Can't be empty, so do nothing
            } else if (!_.isEmpty(item.display_name) && item.display_name !== room.resource.display_name) {
                console.log('updateVfxRoomsFromCsvSaga: display_name not empty', item.display_name);
                updateRoomProperty(ctx, room, 'display_name', item.display_name);
            }
        });
    }

    // try{
    //     const res = from(bulkUpdateItems).pipe(
    //         mergeMap(item => api.fetchRoom(organisation_id, item.room_name)),            
    //         //tap(r => ctx.dispatch(actions.updateVfxRoomsFromCsvStatus(`Processing room ${r.resource.name}`)))
    //         map(room =>  {
                
    //             console.log('updateVfxRoomsFromCsvSaga: Top of Loop');

    //             const item = bulkUpdateItems.find(x => x.room_name === room.resource.name)!

    //             console.log('updateVfxRoomsFromCsvSaga: Loop: ', {room, item});

    //             if (!_.isEmpty(item.template_room)) {
    //                 updateRoomProperty(ctx, room, 'template_room_Name', item.template_room);
    //             }
            
    //             if (!_.isEmpty(item.room_package_feed)) {
    //                 updateRoomComponent(ctx, room, 'room_msi_package_feed_name', item.room_package_feed);
    //             }
            
    //             if (!_.isEmpty(item.admin_package_feed)) {
    //                 updateRoomComponent(ctx, room, 'admin_msi_package_feed_name', item.admin_package_feed);
    //             }
            
    //             if (!_.isEmpty(item.display_name)) {
    //                 updateRoomProperty(ctx, room, 'display_name', item.display_name);
    //             }
    //         })//,
    //         //tap(x => ctx.dispatch(actions.updateFcxRoomsFromCsvProgreess()))
    //     )

         
    // } catch (e) {
    //     console.error("updateVfxRoomsFromCsvSaga: ", e);
    // }


})

function updateRoomComponent(ctx:any, room: HalResult<Room>, propertyName: string, value?: string) {
        
    console.log('updateRoomComponent', {room, propertyName, value});

    // Not sure what sometimes 'promotes' the components to the resource from _embedded, but this allows for both
    let component: HalResource<Component> = (room.resource.room_component) ?  room.resource.room_component : room.resource._embedded.room_component!
    
    if (propertyName === "javascript_package_feed_name") {
        component = (room.resource.javascript_package_component) ? room.resource.javascript_package_component : room.resource._embedded.javascript_package_component!
    } else if (propertyName === "admin_msi_package_feed_name") {
        component = (room.resource.admin_component) ? room.resource.admin_component : room.resource._embedded.admin_component!
    }
    
    if (value === "<empty>") {
        value = undefined;
    }

    const args = { room: room, component: component, updatedComponent: { ...component, package_feed_name: value } };
    console.log('updateRoomComponent: args ', args);

    ctx.dispatch(actions.updateComponentProperties.request(args))
    
}

function updateRoomProperty(ctx:any, room: HalResult<Room>, propertyName:string, value?:string) {
    
    console.log('updateRoomProperty', {room, propertyName, value});


    if (propertyName === "template_room_name" && (value === "<empty>" || value === 'None')) {
        value = undefined;
    }
    
    const args = { room: room, updatedRoom: { ...room.resource, [propertyName]: value } };
    console.log('updateRoomProperty', args);

    ctx.dispatch(actions.updateRoomProperties.request(args))
}

export default all(
    reloadOrganisationSaga,
    fetchConnectedDevicesSaga,
    connectedDeviceCommandSaga,
    updateVfxRoomsFromCsvSaga,
    updateBookitResourcesFromCsvSaga,
    addBookitResourcesFromCsvSaga
)

