import { Button, Checkbox, Dialog, EditIcon, Flex, FlexItem, Header, ProviderConsumer, RetryIcon, Text } from "@fluentui/react-northstar";
import moment from "moment";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import RoomControlToolbar from "../../../containers/RoomControlToolbar";
import { Component, Room } from "../../../model";
import { HalResource, HalResult } from "../../../services/Hal";
import { getActiveOrganisation, getPackageFeeds, getRoomNames } from "../../../store/selectors";
import { actions } from "../../../store/types";
import { useSelector } from "../../../store/utils";
import FileUploadForm from "../../controls/FileUploadForm";
import './RoomCard.css';
import { RoomPropertyEditDropdown, RoomPropertyEditInput, RoomPropertyEditPopup } from "./RoomPropertyEditPopup";
import { RoomPropertyField } from "./RoomPropertyField";
import { RoomRunningStatus } from "./RoomRunningStatus";

type Props = {
    room: HalResult<Room>;
}

interface State {
    licenseUploadComplete?: boolean;
    licenseFileUpload?: File;
    editMode: boolean;
}
interface PopupState {
    template_room_name: boolean;
    team_viewer_id: boolean;
    display_name: boolean;
    room_msi_package_feed_name: boolean;
    admin_msi_package_feed_name: boolean;
    javascript_package_feed_name: boolean;
    license_file_upload: boolean;
    provisioning_pin: boolean;
}

const RoomCard: React.FC<Props> = ({ room }) => {

    const dispatch = useDispatch()

    const [popupState, setPopupState] = useState<PopupState>(
        {
            team_viewer_id: false,
            display_name: false,
            room_msi_package_feed_name: false,
            admin_msi_package_feed_name: false,
            javascript_package_feed_name: false,
            template_room_name: false,
            license_file_upload: false,
            provisioning_pin: false
        })
    const [, setLicenseFileUpload] = useState<File>()

    const isUploadingLicenseFile = useSelector(s => s.licenseFiles.isUploadingLicenseFile)
    const latestBackup = useSelector(s => s.rooms.lastBackups[room.resource.name.toLowerCase()])
    const roomNames = useSelector(s => getRoomNames(s))
    const packageFeeds = useSelector(s => getPackageFeeds(s))
    const portalSettings = useSelector(s => s.app.portalSettings)

    useEffect(() => {

        if (!isUploadingLicenseFile) {
            setLicenseFileUpload(undefined)
        }
    }, [isUploadingLicenseFile])


    const onChangeClicked = useCallback((id: string) => {
        setPopupState({
            ...popupState,
            [id]: !popupState[id]
        })
    }, [popupState]);

    const closePopup = useCallback((id: string) => {
        setPopupState({
            ...popupState,
            [id]: false
        })
    }, [popupState]);

    const onBackupNowClicked = useCallback(() => {
        dispatch(actions.backupRoom.request(room))
    }, [dispatch, room])

    const onRefreshRoomPin = useCallback(() => {
        dispatch(actions.refreshRoomPin.request(room))
    }, [dispatch, room])

    const saveChanges = useCallback((propertyName: string, value?: string) => {
        if (propertyName === "template_room_name" && value === "None") {
            value = undefined;
        }

        const args = { room: room, updatedRoom: { ...room.resource, [propertyName]: value } };

        dispatch(actions.updateRoomProperties.request(args))
        closePopup(propertyName)
    }, [closePopup, dispatch, room])

    const handleToggleMute = useCallback((room: HalResult<Room>) => {
        const updatedRoom = { ...room.resource, escalations_muted: !(room.resource.escalations_muted ?? false)}
        const args = { room, updatedRoom }
        dispatch(actions.updateRoomProperties.request(args))
    }, [dispatch])

    const saveComponentChanges = useCallback((propertyName: string, value?: string) => {
        let component: HalResource<Component> = room.resource.room_component!
        if (propertyName === "javascript_package_feed_name") {
            component = room.resource.javascript_package_component!
        } else if (propertyName === "admin_msi_package_feed_name") {
            component = room.resource.admin_component!
        }
        const args = { room: room, component: component, updatedComponent: { ...component, package_feed_name: value } };

        if (component.package_feed_name === args.updatedComponent.package_feed_name) {
            closePopup(propertyName);
            return
        }

        dispatch(actions.updateComponentProperties.request(args))
        closePopup(propertyName);
    }, [closePopup, dispatch, room])

    const uploadLicenseFile = useCallback((file: File) => {
        dispatch(actions.uploadLicenseFile.request({ room: room, file: file }))
        closePopup('license_file_upload')
    }, [closePopup, dispatch, room])


    const closeUploadLicensePopup = useCallback(() => {
        closePopup("license_file_upload");
    }, [closePopup])

    return (
        <ProviderConsumer render={theme => {
            return (<>
                <Flex column styles={{ border: `1px solid ${theme.siteVariables.colorScheme.default.border3}` }}>
                    <Flex className='room-card-header' vAlign='start'
                        styles={{ backgroundColor: theme.siteVariables.colorScheme.default.background, borderBottom: `1px solid ${theme.siteVariables.colorScheme.default.border3}` }}>
                        <Flex column>
                            <Header as="h6" content={room.resource!.display_name} description={{ content: `${room.resource!.name}`, as: 'span' }} />
                        </Flex>
                        <Button icon={<EditIcon/>} text onClick={() => onChangeClicked('display_name')} className='edit-room-name' />
                        <FlexItem push>
                            <RoomControlToolbar className='push-end' room={room.resource} halRoom={room} latestBackup={latestBackup} showExtraButtons={true} />
                        </FlexItem>
                    </Flex>
                    <Flex className='room-card-content'
                        styles={{ backgroundColor: theme.siteVariables.colorScheme.default.background }}>
                        <FlexItem grow>
                            <Flex column>
                                <RoomPropertyField label='Software Versions' content={<RoomVersions room={room.resource} />} />
                                <RoomPropertyField label='Video Clients' content={<ClientVersions room={room.resource} />} />
                                <RoomPropertyField label='SIP Address' content={room.resource.sip_address} />
                                {portalSettings && portalSettings.feature_settings.licence_file_upload && <RoomPropertyField label='License File' content={room.resource.has_license_file ? "Yes" : "No"}
                                    actionIcon={<EditIcon/>} actionOnClick={() => onChangeClicked('license_file_upload')} />}
                                <RoomPropertyField label='Provisioning Status' content={room.resource.provisioning_status} />
                                <RoomPropertyField label='IP Addresses' content={room.resource.ip_addresses !== undefined ? room.resource.ip_addresses.map((addr, idx) => (<span key={idx.toString()}>{addr}<br /></span>)) : undefined} />                            </Flex>
                        </FlexItem>
                        <FlexItem grow>
                            <Flex column>
                                <RoomPropertyField label='Running Status' content={<RoomRunningStatus room={room.resource!} />} />
                                <RoomPropertyField label='Escalations Muted' content={<Checkbox checked={room.resource.escalations_muted ?? false} toggle onChange={() => handleToggleMute(room)} />} />

                                <RoomPropertyField label='Template Room' content={room.resource.template_room_name ? <Link to={`/rooms/${room.resource.template_room_name}`}>{room.resource.template_room_name}</Link> : 'None'}
                                    actionIcon={<EditIcon/>} actionOnClick={() => onChangeClicked('template_room_name')} />
                                <RoomPropertyField label='Last Heartbeat' content={durationString(room.resource!.room_component!.last_heartbeat)} />
                                {lastRestartRow(room.resource)}
                                <RoomPropertyField label='Last Backup' content={latestBackup ? durationString(latestBackup!.resource.created_date_utc) : "Never"}
                                    actionIcon={<RetryIcon/>} actionOnClick={onBackupNowClicked} />
                            </Flex>
                        </FlexItem>
                        <FlexItem grow>
                            <Flex column>
                                <RoomPropertyField label='Pin' content={room.resource.pin} actionIcon={<RetryIcon/>}actionOnClick={onRefreshRoomPin} />
                                <RoomPropertyField label='Provisioning Pin' content={room.resource.provisioning_pin !== undefined && room.resource.provisioning_pin !== "" ? formatPin(room.resource.provisioning_pin) : "None"}
                                    actionIcon={<EditIcon/>} actionOnClick={() => onChangeClicked('provisioning_pin')} />
                                <RoomPropertyField label='Room MSI Package Feed' content={room.resource.room_component?.package_feed_name ?? "None"}
                                    actionIcon={<EditIcon/>} actionOnClick={() => onChangeClicked('room_msi_package_feed_name')} canEdit={room.resource.room_component !== undefined} />
                                <RoomPropertyField label='Admin MSI Package Feed' content={room.resource.admin_component?.package_feed_name ?? "None"}
                                    actionIcon={<EditIcon/>} actionOnClick={() => onChangeClicked('admin_msi_package_feed_name')} canEdit={room.resource.admin_component !== undefined} />
                                {/* <RoomPropertyField label='JS Package Feed' content={room.resource.javascript_package_component?.package_feed_name ?? "None"}
                                    actionIcon={<EditIcon/>} actionOnClick={() => onChangeClicked('javascript_package_feed_name')} canEdit={room.resource.javascript_package_component !== undefined} /> */}
                                <RoomPropertyField label='TeamViewer ID' content={room.resource.team_viewer_id ? room.resource.team_viewer_id : "N/A"}
                                    actionIcon={<EditIcon/>} actionOnClick={() => onChangeClicked('team_viewer_id')} />
                            </Flex>
                        </FlexItem>
                    </Flex>
                </Flex>

                <RoomPropertyEditPopup title='Edit Template Room' isOpen={popupState.template_room_name}
                    content={<RoomPropertyEditDropdown label='Room' dataItems={roomNames!} getHeader={i => i.toString()}
                        selectedKey={room.resource.template_room_name} placeholder='Select template room' allowNone={true}
                        propertyName='template_room_name' onSubmit={saveChanges} onCancel={closePopup} />} />

                <RoomPropertyEditPopup title='Edit Display Name' isOpen={popupState.display_name}
                    content={<RoomPropertyEditInput label='Display Name' defaultValue={room.resource.display_name}
                        propertyName='display_name' onSubmit={saveChanges} onCancel={closePopup} />} />

                <RoomPropertyEditPopup title='Edit TeamViewer ID' isOpen={popupState.team_viewer_id}
                    content={<RoomPropertyEditInput label='TeamViewer ID' defaultValue={room.resource.team_viewer_id}
                        propertyName='team_viewer_id' onSubmit={saveChanges} onCancel={closePopup} clearable />} />

                <RoomPropertyEditPopup title='Edit Provisioning Pin' isOpen={popupState.provisioning_pin}
                    content={<RoomPropertyEditInput label='Provisioning PIN' defaultValue={room.resource.provisioning_pin}
                        propertyName='provisioning_pin' onSubmit={saveChanges} onCancel={closePopup} validate={validatePin} clearable />} />

                <RoomPropertyEditPopup title='Edit Room MSI Package Feed'
                    isOpen={popupState.room_msi_package_feed_name}
                    content={<RoomPropertyEditDropdown
                        label='MSI Package Feed'
                        dataItems={packageFeeds ? packageFeeds.filter(f => f.type === 'Msi') : []}
                        getHeader={i => i.feed_name}
                        selectedKey={room.resource.room_component?.package_feed_name}
                        placeholder='Select MSI package feed' allowNone={false}
                        propertyName='room_msi_package_feed_name'
                        onSubmit={saveComponentChanges}
                        onCancel={closePopup} />} />

                <RoomPropertyEditPopup title='Edit Admin MSI Package Feed'
                    isOpen={popupState.admin_msi_package_feed_name}
                    content={<RoomPropertyEditDropdown
                        label='MSI Package Feed'
                        dataItems={packageFeeds ? packageFeeds!.filter(f => f.type === 'Msi') : []}
                        getHeader={i => i.feed_name}
                        selectedKey={room.resource.admin_component?.package_feed_name}
                        placeholder='Select MSI package feed' allowNone={false}
                        propertyName='admin_msi_package_feed_name'
                        onSubmit={saveComponentChanges}
                        onCancel={closePopup} />} />

                <RoomPropertyEditPopup title='Edit JS Package Feed'
                    isOpen={popupState.javascript_package_feed_name}
                    content={<RoomPropertyEditDropdown label='JS Package Feed'
                        dataItems={packageFeeds ? packageFeeds!.filter(f => f.type === 'JavascriptPackage') : []}
                        getHeader={i => i.feed_name}
                        selectedKey={room.resource.javascript_package_component?.package_feed_name}
                        placeholder='Select JS package feed' allowNone={false}
                        propertyName='javascript_package_feed_name'
                        onSubmit={saveComponentChanges}
                        onCancel={closePopup} />} />


                {popupState.license_file_upload && <Dialog
                    open={popupState.license_file_upload}
                    header={`Upload license file for ${room.resource.name}`}
                    content={<FileUploadForm onCancel={closeUploadLicensePopup} onSubmit={uploadLicenseFile}
                        accept={".lic"} />}
                />}

            </>)
        }}
        />
    );
}

function durationString(m?: moment.MomentInput) {
    return durationStringSuffix("ago", "Never", m)
}

function durationStringSuffix(suffix: string, defaultStr: string, m?: moment.MomentInput) {
    return m === undefined ? defaultStr : `${moment.duration(moment().diff(m)).humanize()} ${suffix}`
}

function lastRestartRow(room: Room) {

    if (room.room_component !== undefined) {
        switch (room.room_component.running_status) {
            case 'Running':
                return <RoomPropertyField label='Uptime' content={durationStringSuffix("", "None", room.room_component!.last_restart)} />
            case 'Stopped':
                return <RoomPropertyField label='Last Restart' content={durationString(room.room_component!.last_restart)} />
            default:
                return <RoomPropertyField label='Last Restart' content='Never' />
        }
    } else {
        return <RoomPropertyField label='Last Restart' content='Never' />
    }
}

function validatePin(propertyName: string, value?: string): string | undefined {
    if (value && value!.length === 6) {
        return undefined;
    }
    return "Provisioning PIN must be 6 characters"
}



function formatPin(pin: string) {
    return pin.substr(0, 3) + "-" + pin.substr(3, 3);
}

const RoomVersions: React.FC<{ room: HalResource<Room> }> = (props) => {
    const { room } = props
    let adminVer = room.admin_component!.version
    if (adminVer && adminVer.endsWith('.0')) {
        adminVer = adminVer.slice(0, -2)
    }

    return (<>
        <Flex column>
            {room.room_component!.version ? <Text content={`${room.room_component!.version} (Room)`} /> : null}
            {adminVer ? <Text content={`${adminVer} (Admin)`} /> : null}
            {room.javascript_package_component!.version && <Text content={`${room.javascript_package_component!.version} (Javascript)`} />}
        </Flex>
    </>);
};

const ClientVersions: React.FC<{ room: HalResource<Room> }> = (props) => {
    const { room } = props

    return (<>
        <Flex column>
            {room.skype_client_version && <Text content={`${room.skype_client_version} (Skype)`} />}
            {room.teams_client_version && <Text content={`${room.teams_client_version} (Teams)`} />}
        </Flex>
    </>);
};

export default RoomCard;
