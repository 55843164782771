import { Button, Dropdown, Flex, Menu, MenuItemProps, Popup } from "@fluentui/react-northstar";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import useToggle from "../../hooks/useToggle";
import { getActiveOrganisation, getReportingRoomGroups } from "../../store/selectors";
import { actions } from "../../store/types";
import { useSelector } from "../../store/utils";
import RangeSelector, { getRange } from "../controls/RangeSelector";
import SecurityReport from  "../reports/security_report";
import "./SecurityReportPage.scss";

export interface ReportFilter {
    startDate?: moment.Moment
    endDate?: moment.Moment
    tag: string
}

const SecurityReportPage: React.FC<{}> = () => {

    const dispatch = useDispatch()

    const [activeIndex, setActiveIndex] = useState(0)
    const [rangeOpen, toggleRangeOpen] = useToggle(false)
    const [selectedRange, setSelectedRange] = useState<string>("last7d")
    const [rangeDescription, setRangeDescription] = useState("Last 7 days")
    const [defaultRange] = useState(getRange("last7d"))
    const [startDate, setStartDate] = useState<moment.Moment | undefined>(moment(defaultRange.startDate))
    const [endDate, setEndDate] = useState<moment.Moment | undefined>(moment(defaultRange.endDate))

    const [tag, setTag] = useState("All")

    const activeOrganisation = useSelector(getActiveOrganisation)
    
    useEffect(() => { 
        dispatch(actions.fetchReportingRoomGroups.request(activeOrganisation.organisation_id)) 
    }, [activeOrganisation.organisation_id, dispatch])

    const reportingRoomGroups = useSelector(getReportingRoomGroups)    

    const tagNames = useMemo(() => {
        const tags = reportingRoomGroups.flatMap(t => t.type === 'ReportingRoomGroup' ? t.tag_name : []).sort()
        tags.unshift('All')
        return tags
    }, [reportingRoomGroups])

    const toggleRange = () => {
        toggleRangeOpen()
    }

    const onApplyRange = (sDate: moment.Moment, eDate: moment.Moment, range: string, description: string, toggle: boolean) => {
        setRangeDescription(description)
        setSelectedRange(range)
        if (description === "All") {
            setStartDate(undefined)
            setEndDate(undefined)
        } else {
            setStartDate(sDate)
            setEndDate(eDate)
        }
        if (toggle) {
            toggleRange();
        }
    }

    const rangeContent = (
        <Flex column>
            <Flex.Item align="end">
                <Button text iconOnly icon="close" onClick={() => toggleRange()} title="Close" />
            </Flex.Item>
            <RangeSelector startDate={startDate} endDate={endDate} onApply={onApplyRange} onCancel={toggleRange} selectedRange={selectedRange} />
        </Flex>
    )

    const handleMenuItemClick = useCallback((event: React.SyntheticEvent<HTMLElement>, data?: MenuItemProps) => {
        if (data) {
            setActiveIndex(data.index!)
        }
    }
    , [])
    const tabItems = [
        {
            "key": "security",
            "content": "Security"
        },
    ]

    const filter: ReportFilter = useMemo(() => ({
        startDate,
        endDate,
        tag
    }),
    [startDate, endDate, tag])

    return (
        <Flex className="ReportsPage">
            <h5>Organisation Security Report</h5>
            <div>
                <Popup
                    trapFocus
                    trigger={<Button primary onClick={() => toggleRange()}>{rangeDescription}</Button>}
                    content={rangeContent}
                    open={rangeOpen}
                    onOpenChange={() => toggleRangeOpen()}
                />

              
            </div>

            <Menu 
                activeIndex={activeIndex} 
                onItemClick={handleMenuItemClick}
                items={tabItems} 
                underlined primary
            />

            {activeIndex === 0 && <SecurityReport filter={filter} />}
        </Flex>
    )
}

export default SecurityReportPage;