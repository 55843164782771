interface ConfigData {
    enableServerPushDeployment: boolean
    serverApiRoot: string
}

const DEVELOPMENT_URL: string = "https://api.ipfx.dev"
export default class Config {
    public static get enableServerPushDeployment(): boolean {
        return Config.instance.enableServerPushDeployment ? Config.instance.enableServerPushDeployment : false
    }


    public static get serverApiRoot(): string {

        console.log("Config.serverApiRoot", Config.instance.serverApiRoot)
        console.log("process.env", process.env)

        return Config.instance.serverApiRoot ? Config.instance.serverApiRoot
            // : process.env.NODE_ENV && process.env.NODE_ENV === "development" ? "https://aklnics1.ipfx.com:5000" : Config.releaseApiUrl
          //   : process.env.NODE_ENV && process.env.NODE_ENV === "development" ? "https://aklmarkb.ipfx.com:5000" : Config.releaseApiUrl
             : process.env.NODE_ENV && process.env.NODE_ENV === "development" ? DEVELOPMENT_URL : Config.releaseApiUrl
            // : process.env.NODE_ENV && process.env.NODE_ENV === "development" ? Config.releaseApiUrl : Config.releaseApiUrl
    }

    private static get releaseApiUrl(): string {
        return  process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : "https://api.ipfx.app"
    }

    private static get instance(): ConfigData {
        return ((window as any).videoFxConfig) as ConfigData
    }
}

