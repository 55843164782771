import React from 'react';
import { useSettingsContext } from './BookItSettingsPage';
import { Setting, SettingInput, SettingsSection, SettingToggle } from './Settings';

function MeetingSettings() {

    const { values, base } = useSettingsContext()
    console.log("MeetingSettings: ", values)

    return <SettingsSection title="Meeting">
        <Setting title="Ad-Hoc Meetings" description="Allow Ad-Hoc meetings to be created from Book-It devices."
            name='ad_hoc_enabled'
            overrideName='ad_hoc_enabled'
            defaultValue={true} />
        <Setting title="Allow Start Minutes" description="Controls how long before the meeting start time the meeting can be started from the Book-It device." overrideName="max_early_start_minutes">
            <SettingInput label='Minutes before:' name="max_early_start_minutes"
                type='number' defaultValue={15} />
        </Setting>
        <Setting
            title="Auto-End Meetings not Started"
            description="The number of minutes after a meeting was scheduled to start that if it hasn't been started will automatically be ended (use -1 to disable)."
            overrideName='auto_end_meeting_minutes'>
            <SettingInput label='Minutes before truncate:' name="auto_end_meeting_minutes" type='number' defaultValue={5} />
        </Setting>
        <Setting
            title="Auto-End Started Meeting"
            description="Automatically end started meetings after a given duration (use -1 to disable)."
            overrideName="auto_end_started_meeting_override"
        >
            <SettingInput label='Minutes after end:' name="auto_end_started_meeting_duration_minutes" type='number' disabled={values.auto_end_started_meeting_at_midnight}
                defaultValue={-1} />
        </Setting>
        {base === undefined && <>
            <Setting
                title="Room Sizes Filter"
                description="Comma separated list of boundries for room size filter. Example 4, 9 will generate 3 filter buttons <4, 4-8 and >=9"
                overrideName="room_sizes_filter"
            >
                <SettingInput label='Filter Sizes:' name="room_sizes_filter" defaultValue="4, 9" />
            </Setting>
            <Setting
                title="Replace Meeting Subject"
                description="Enabling this feature will cause all meeting subject to be replaced with the  given text."
                name='replace_meeting_subject_enabled'
                overrideName='replace_meeting_subject_enabled'
                defaultValue={false}
            >
                <SettingInput label='Replacement Meeting Subject:' name="replacement_meeting_subject" defaultValue='' />
            </Setting>
            {/* 
                *** Deprecated, so removed ***
            <Setting
                title="Meeting Summary"
                description="This feature will add a summary meeting link to the body of calendar appointments if enabled."
                name='meeting_summary_enabled'
                overrideName='meeting_summary_enabled'>
                <SettingInput label='Base URL:' name="meeting_summary_base_url" type='url' defaultValue='' />
            </Setting> */}
        </>}
        <Setting title="Custom Background URL" description="If set this URL will be used as the background image on the tablets within the organisation."
            overrideName="device_background_url">
            <SettingInput label='URL:' name="device_background_url" type='url' defaultValue='' />
        </Setting>
        {base === undefined &&
            <Setting title="Allow attendee editing" description="Allow attendees to edit the organisers version of the meeting. A link will be included in the invite email that allows attendees to edit the meeting."
                name='allow_attendee_editing'
                overrideName='allow_attendee_editing'
                defaultValue={false} />}
        <Setting title="Invite external attendees enabled" description="External attendees invited to a room meeting are sent a Fast-Track login."
            name="invite_external_attendees_enabled"
            overrideName="invite_external_attendees_enabled" defaultValue={false} />

        <Setting
            title="Remove rooms from recurring meetings on truncation"
            description="Remove future instances of the room from recurring meetings if threshold is met"
            name='recurrence_auto_end_enabled'
            overrideName='recurrence_auto_end'
            defaultValue={true} >
            <SettingInput label='Max. missed instances:' name="recurrence_auto_end_missed_instances" type='number' defaultValue={3} />
        </Setting>
    </SettingsSection>
}

// export function toMeetingSettingsEditModel(settings: Partial<BookItSettings> | undefined, defaults: BookItSettings): MeetingSettingsEditModel {

//     const editModel = {
//         AdHocEnabled: settings?.ad_hoc_enabled ?? defaults.ad_hoc_enabled,
//         MaxEarlyStartMinutes: settings?.max_early_start_minutes ?? defaults.max_early_start_minutes,
//         AutoEndMeetingEnabled: true,
//         AutoEndMeetingMinutes: settings?.auto_end_meeting_minutes ?? defaults.auto_end_meeting_minutes,
//         MeetingSummaryEnabled: settings?.meeting_summary_enabled ?? defaults.meeting_summary_enabled,
//         MeetingSummaryBaseUrl: settings?.meeting_summary_base_url ?? defaults.meeting_summary_base_url,
//         ReplaceMeetingSubjectEnabled: settings?.replace_meeting_subject_enabled ?? defaults.replace_meeting_subject_enabled,
//         ReplacementMeetingSubject: settings?.replacement_meeting_subject ?? defaults.replacement_meeting_subject,
//         RoomSizesFilter: settings?.room_sizes_filter?.join(', ') ?? defaults.room_sizes_filter.join(', '),
//         AutoEndStartedMeetingAtMidnight: settings?.auto_end_started_meeting_duration_minutes === -1 ?? defaults.auto_end_started_meeting_duration_minutes,
//         AutoEndStartedMeetingDurationMinutes: settings?.auto_end_started_meeting_duration_minutes ?? 60 ?? defaults.auto_end_started_meeting_duration_minutes,
//         DeviceBackgroundUrl: settings?.device_background_url ?? defaults.device_background_url

//     }

//         return editModel
// }

// export function toMeetingSettingsEditModelInherited(settings: Partial<BookItSettings> | undefined): Partial<MeetingSettingsEditModel> {

//     const editModel = {
//         AdHocEnabled: settings?.ad_hoc_enabled,
//         MaxEarlyStartMinutes: settings?.max_early_start_minutes,
//         AutoEndMeetingEnabled: settings?.auto_end_meeting_minutes !== undefined ? true : undefined,
//         AutoEndMeetingMinutes: settings?.auto_end_meeting_minutes,
//         MeetingSummaryEnabled: settings?.meeting_summary_enabled,
//         MeetingSummaryBaseUrl: settings?.meeting_summary_base_url,
//         ReplaceMeetingSubjectEnabled: settings?.replace_meeting_subject_enabled,
//         ReplacementMeetingSubject: settings?.replacement_meeting_subject,
//         RoomSizesFilter: settings?.room_sizes_filter?.join(', '),
//         AutoEndStartedMeetingAtMidnight: settings?.auto_end_started_meeting_duration_minutes === -1,
//         AutoEndStartedMeetingDurationMinutes: settings?.auto_end_started_meeting_duration_minutes,
//         DeviceBackgroundUrl: settings?.device_background_url

//     }

//     return editModel
// }

export default MeetingSettings
