import { Epic } from "redux-observable";
import { of } from "rxjs";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { Services } from "../../services";
import {
    errorHandler,
    fetchEscalationsReport, fetchReportingRoomGroups, fetchSecurityReport, fetchSecurityUserReport,  
    fetchVfxRoomStatusReport, fetchBookitTabletStatusReport, fetchDeviceTimeseriesReport
} from "../actions";
import { RootAction, RootState } from "../types";

export const fetchReportingRoomGroupsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchReportingRoomGroups.request)),
        mergeMap(action =>
            serverApi.fetchReportingRoomGroups(action.payload).pipe(
                map(res => fetchReportingRoomGroups.success(res)),
                catchError(err => of(fetchReportingRoomGroups.failure(err), errorHandler(err)))
            )));
}

export const fetchEscalationsReportEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchEscalationsReport.request)),
        mergeMap(action =>
            serverApi.fetchEscalationsReport(action.payload.organisationId, action.payload.startRange, action.payload.endRange, action.payload.tag).pipe(
                map(res => fetchEscalationsReport.success(res)),
                catchError(err => of(fetchEscalationsReport.failure(err), errorHandler(err)))
            )));
}

export const fetchSecurityReportEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchSecurityReport.request)),
        mergeMap(action =>
            serverApi.fetchSecurityReport(action.payload.startRange, action.payload.endRange).pipe(
                map(res => fetchSecurityReport.success(res)),
                catchError(err => of(fetchSecurityReport.failure(err), errorHandler(err)))
            )));
}

export const fetchSecurityUserReportEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchSecurityUserReport.request)),
        mergeMap(action =>
            serverApi.fetchSecurityUserReport(action.payload.startRange, action.payload.endRange, action.payload.tag).pipe(
                map(res => fetchSecurityUserReport.success(res)),
                catchError(err => of(fetchSecurityUserReport.failure(err), errorHandler(err)))
            )));
}

export const fetchVfxRoomStatusReportEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchVfxRoomStatusReport.request)),
        mergeMap(action =>
            serverApi.fetchVfxRoomStatusReport(action.payload.organisation_id).pipe(
                map(res => fetchVfxRoomStatusReport.success(res)),
                catchError(err => of(fetchVfxRoomStatusReport.failure(err), errorHandler(err)))
            )));
}

export const fetchBookitTabletStatusReportEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchBookitTabletStatusReport.request)),
        mergeMap(action =>
            serverApi.fetchBookitTabletStatusReport(action.payload.organisation_id).pipe(
                map(res => fetchBookitTabletStatusReport.success(res)),
                catchError(err => of(fetchBookitTabletStatusReport.failure(err), errorHandler(err)))
            )));
}

export const fetchDeviceTimeseriesReportEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchDeviceTimeseriesReport.request)),
        mergeMap(action =>
            serverApi.fetchDeviceTimeseriesReport(action.payload.organisation_id, action.payload.startRange, action.payload.endRange).pipe(
                map(res => fetchDeviceTimeseriesReport.success(res)),
                catchError(err => of(fetchDeviceTimeseriesReport.failure(err), errorHandler(err)))
            )));
}


