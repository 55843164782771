import React, { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../store/utils";
import useToggle from "../../../hooks/useToggle";

import { getActiveOrganisation } from "../../../store/selectors";
import { Button, Popup, Flex } from "@fluentui/react-northstar";
import { MdCall, MdEvent, MdWarning } from "react-icons/md";
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

import ReactTable, { CellInfo, Column } from "react-table";
import { StoppedRunningSelection, DeviceTimeseriesReport, IdType } from "../../../model";
import StoppedRunningSelector from './StoppedRunningSelector';
import { fetchDeviceTimeseriesReport } from "../../../store/actions";
import DateSelector, { getDatesOfRange, getRangeToDescripton } from "../../bookIt/DateSelector";
//import services from "../../services";
import { getDeviceTimeseriesReport, getDeviceTimeseriesReportIsLoading } from "../../../store/selectors";
import './SupportDashboard.module.scss';

import { date } from "yup";
import moment from "moment";
import { dateRanges } from "../../bookIt/DateSelector";
import { endOfDay, startOfDay } from "date-fns";
import internal from "stream";

import TimelinesChart, { TimelinesChartInstance } from 'timelines-chart';
import { postChartRows } from "../../bookIt/GraphApiService";

const defaultRange = "last7d"

export function secondsToDhm(seconds: number) {
    const minutes = seconds / 60

    const d = Math.floor(minutes / (60*24));
    const h = Math.floor(minutes % (60*24) / 60);
    const m = Math.floor(minutes % 60);

    const dDisplay = d > 0 ? d + "d " : "";
    const hDisplay = h > 0 ? h + "hr "  : "";
    const mDisplay = m > 0 ? m + "m " : "";

    return dDisplay + hDisplay + mDisplay;
}

interface ReportFilter {
    startDate?: Date,
    endDate?: Date,   
    isWorkingHoursOnly: boolean,
    excludeWeekends: boolean
}

interface IPeriod {
    status_start_utc: Date,
    status_end_utc: Date
}

function DeviceTimeseriesReportPage() {

    const dispatch = useDispatch()
    const activeOrganisation = useSelector(getActiveOrganisation)
    
    const initialRange = getDatesOfRange(defaultRange)
    const [rangeOpen, toggleRangeOpen] = useToggle(false)
    const [rangeDescription, setRangeDescription] = useState(getRangeToDescripton(defaultRange))
    const [startDate, setStartDate] = useState(initialRange.startDate)
    const [endDate, setEndDate] = useState(initialRange.endDate)
    const [selectedRange, setSelectedRange] = useState(defaultRange)
    const [isWorkingHoursOnly, setIsWorkingHoursOnly] = useState(false);
    const [excludeWeekends, setExcludeWeekends] = useState(true);

    
    useEffect(() => {
        if (activeOrganisation && selectedRange) {
        dispatch(fetchDeviceTimeseriesReport.request({ organisation_id: activeOrganisation.organisation_id,  startRange: filter.startDate!,
            endRange: filter.endDate! }))
        }
    }, [dispatch, activeOrganisation, selectedRange])

    const reportData = useSelector(getDeviceTimeseriesReport);
    const isLoading = useSelector(getDeviceTimeseriesReportIsLoading);
    
    function getTimes() {
        return Array.from(new Array(24)).map((_, h) => moment.utc(0).add(h, 'h'));
    }

   
  
    const onApplyRange = (sDate: moment.Moment, eDate: moment.Moment, range: string, description: string, toggle: boolean, workingHoursOnly: boolean, excludeWeekends: boolean) => {
        setRangeDescription(description)
        setSelectedRange(range)
        setStartDate(startOfDay(sDate.toDate()))
        setEndDate(endOfDay(eDate.toDate()))
        setIsWorkingHoursOnly(workingHoursOnly)
        setExcludeWeekends(excludeWeekends)
        if (toggle) {
            toggleRangeOpen();
        }
    }

    const filter: ReportFilter = useMemo(() => ({
        startDate,
        endDate,
        selectedRange,
        isWorkingHoursOnly,
        excludeWeekends
        }),
        [endDate, excludeWeekends, isWorkingHoursOnly, selectedRange, startDate]
    )

    function renderUtcDate(date:Date | undefined) {
        if (date) {
            return moment(date).local().format('Do MMM YYYY, h:mm:ss a');
        } else {
            return '';
        }
    }
    
    const rangeContent = (
        <Flex column>
            <Flex.Item align="end">
                <Button text iconOnly icon="close" onClick={() => openSelector()} title="Close" />
            </Flex.Item>
            <DateSelector startDate={startDate} endDate={endDate} onApply={onApplyRange} onCancel={toggleRangeOpen}
                selectedRange={selectedRange} onIsWorkingHoursOnlyChanged={() => setIsWorkingHoursOnly(!isWorkingHoursOnly)}
                onExcludeWeekends={() => setExcludeWeekends(!excludeWeekends)} excludeWeekends={excludeWeekends} workingHoursOnly={isWorkingHoursOnly} />
        </Flex>
    )
    
    const openSelector = () => {
        setSelectedRange('yesterday')
        toggleRangeOpen()
        console.log(selectedRange)
    }

    function mapReportData(reportData: DeviceTimeseriesReport[]) {
        
        console.log('tschart mapReportData', reportData);
        
        const chartData: any[] = [];

        reportData.forEach(row => {
            chartData.push(buildGroup(row));            
        });

        console.log('tschart mapReportData', chartData);
        return chartData;
    }

    function buildGroup(row: DeviceTimeseriesReport) {

        console.log('tschart buildGroup row', row);

        const chartRow = {
            group: row.device_name,
            data: [buildPeriodData(row.periods)]
        }

        console.log('tschart buildGroup row', chartRow);

        return chartRow;
    }

    function buildPeriodData(periods: IPeriod[]) {
        
        const itemsData: any[] = [];

        periods.forEach(period => {
            const item = {
                timeRange: [period.status_start_utc, period.status_end_utc],
                val: 0
            }
            itemsData.push(item);
        });

        return {
            label: '',
            data: itemsData
        }
    }

    function getDummyData() {

        const today = moment().startOf('day');

        const data = [
            {
            group: 'Tablet 1',
            data: [
                {
                   label: '',
                    data: [
                        {
                            timeRange: [moment().startOf('day').add(9, 'hours').toDate(), moment().startOf('day').add(9.5, 'hours').toDate()],
                            val: 0
                        },
                        {
                            timeRange: [moment().startOf('day').add(10, 'hours').toDate(), moment().startOf('day').add(10.5, 'hours').toDate()],
                            val: 0
                        },
                        
                    ]
                }
            ]
        },
        {
            group: 'tablet 2',
            data: [
                {
                   label: '',
                    data: [
                        {
                            timeRange: [moment().startOf('day').add(7, 'hours').toDate(), moment().startOf('day').add(7.25, 'hours').toDate()],
                            val: 0
                        },
                        {
                            timeRange: [moment().startOf('day').add(10.1, 'hours').toDate(), moment().startOf('day').add(10.5, 'hours').toDate()],
                            val: 0
                        },
                        
                    ]
                }
            ]
        }
    
    
    ]

        return data;
    }

    function Chart({ data }: { data: any }) {
        
        const ref = useRef<null | HTMLDivElement>(null);
      
        useEffect(() => {
          let chart: TimelinesChartInstance | null = null;
          const { current: node } = ref;
          if (node) {
            chart = TimelinesChart()(node)
                        .width(1500)                      
                        .data(data);

          }
          return () => {
            if (node) {
              while (node.firstChild) {
                node.removeChild(node.firstChild);
              }
            }
          };
        }, [data]);
        return <div ref={ref}></div>;
      }

     
      const verticalSpace = {
        marginTop: '1rem',
        marginBottom: '1rem'
      }

    return <div className='reportSection'>
    <h2>Device downtime time series</h2>
    <div>
        <Popup
            trapFocus
            trigger={<Button className="date" primary onClick={() => toggleRangeOpen()}>{rangeDescription}</Button>}
            content={rangeContent}
            open={rangeOpen}
            onOpenChange={() => toggleRangeOpen()}
        />
    </div>
    <div className='timelineChart'>

        {reportData.length > 0 && <Chart data={mapReportData(reportData)} />}
        {reportData.length === 0 && <div style={verticalSpace}>All devices were fully operational during the selected period.</div>}
       
    </div>
</div>

}



export default DeviceTimeseriesReportPage;
